import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { trackScreen } from "../../configs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Input, Spin, Modal, Form, Steps, Button, notification, Checkbox, Radio } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FloatLabel from "../../components/floating-label";
import PageTitle from "../../components/page-title";
import AntSpinner from "../../components/antSpinner";
import { colors, states, billingFormFields, paymentFormFields, responses } from "../../constants";
import { cancelSubscription, addPaymentCardById, addBillingAddress, addPaymentCard, editBillingAddress, getBillingAddresses, getPaymentCards, addSubscription } from "../../actions";
import PlanOptionBlock from "../../components/plan-option-block";
import BillingForm from "../../components/billing-form";
import UncontrolledLottie from "../../components/lottie/uncontrolled-lottie";
import PaymentSuccessAnimation from "../../lotties/success.json";
import { formErrorMessages, freeItems, premiumItems } from "../../constants";
import { getCardBrandIcon, getFormattedCardDetails, getFormattedLocationAddress, getLongBillingAddress } from "../../util";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import "./index.scss";

let premiumFootnotes = ["* As zones are not fully available yet, each location is equivalent to one zone."];

let unsubscribeOptions = [<Radio id={"TOO_EXPENSIVE"} value={"Too Expensive"} />, <Radio id={"NOT_ENOUGH_VALUE"} value={"Not enough value"} />, <Radio id={"DIDNT_LIKE_PLATFORM"} value={"Didn't like the platform"} />, <Radio id={"NOT_ENOUGH_MUSIC"} value={"Not enough music selection"} />, <Radio id={"OTHER"} value={"Other"} />];

const { Step } = Steps;
const { TextArea } = Input;

class ChoosePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0, // what step is the user on in the plan-selection process
      stateRes: [], // Filtered States to show
      billingData: {}, // User's billing data
      paymentData: {}, // User's payment data
      setAsDefaultBilling: false, // Does user want to set this billing address as the default
      setAsDefaultCard: false, // Does user want to set this card as the default
      billingAccepted: false, // Was billing accepted
      animationFinished: false, // Did payment animation finish
      savingEdit: false, // Are we saving edited form data?
      loading: false, // Are we waiting on async data
      tempSelectedId: null, // Tempory variable to hold user's selection
      selectedBillingId: null, // ID of the user's selected billing address
      selectedPaymentId: null, // ID of the user's selected payment card
      modalVisible: false, // Is the modal visible
      editAddress: false, // Does the user want to edit an existing address
      loadingPage: true, // Are we loading the page
      unsubscribeReason: "", // The text reason the user has unsubscribed
    };
  }

  async componentDidMount() {
    trackScreen();

    let stateRes = [];
    let stateKeys = Object.keys(states);
    stateKeys.forEach((state) => stateRes.push(state));

    // Redirects user to /locations after successful subscription
    if (localStorage.getItem("subscription_redirect")) {
      localStorage.removeItem("subscription_redirect");
      this.props.history.push("/locations");
    }

    await Promise.all([this.props.actions.getBillingAddresses(), this.props.actions.getPaymentCards()]).then(() => {
      this.setState({ loadingPage: false, stateRes, selectedBillingId: this.props.user?.defaultBillingAddressId, selectedPaymentId: this.props.user?.defaultPaymentMethodId }, () => {
        this.setData();
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentStep !== prevState.currentStep && this.state.currentStep === 3) {
      let interval = setInterval(() => {
        let animationWrapper = document.getElementById("payment-animation-wrapper");

        if (animationWrapper) {
          console.log(animationWrapper);
          animationWrapper.children[0].children[0].children[1].children[5].setAttribute("fill", colors.primaryColor);
          clearInterval(interval);
        }
      }, 100);
    }
  }

  // Sets 'locationData' state variable based on incoming 'incomingId'
  async setData() {
    return new Promise((resolve) => {
      const { billing, payment } = this.props;
      const { selectedBillingId, selectedPaymentId } = this.state;

      let billingData = {};
      let paymentData = {};

      billingFormFields.forEach((field) => (billingData[field] = selectedBillingId ? billing[selectedBillingId][field] : ""));
      paymentFormFields.forEach((field) => (paymentData[field] = selectedPaymentId ? payment[selectedPaymentId][field] : ""));

      this.setState({ billingData, paymentData }, () => {
        resolve(true);
      });
    });
  }

  // On Autocomplete serach (filters data list)
  onAutoCompleteSearch = (type, searchText) => {
    let keys = Object.keys(states);
    let stateRes = keys.filter((s) => s.toLowerCase().includes(searchText.toLowerCase()));
    this.setState({ filteredData: searchText.length <= 0 ? keys : stateRes, stateRes, billingData: { ...this.state.billingData, [type]: searchText } });
  };

  /**
   * Handles Input Changes
   * Future Reference: We're using both setState & setFieldsValue because antd will compain not to use setState while also using fieldDecorator, so we're using setFieldsValue as a remedy so we can make use of fieldDecorator
   *  */
  handleChange = (e, type) => {
    let key = e.target.id;
    let value = e.target.value;
    let stateKey = type === "billing" ? "billingData" : type === "payment" ? "paymentData" : "";

    this.props.form.setFieldsValue({ [key]: value });
    this.setState({ [stateKey]: { ...this.state[stateKey], [key]: value } });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    this.setState({ billingData: { ...this.state.billingData, ...map } });
  };

  // Handles checkbox
  handleCheck = (e, type) => {
    this.setState({ [type]: e.target.checked });
  };

  async handleBillingSubmit() {
    const { selectedBillingId, billingData, setAsDefaultBilling } = this.state;

    // billingFormFields.forEach((field) => {
    //   this.props.form.setFieldsValue({ [field]: billingData[field] });
    // });

    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) return;
      if (fieldsValue?.state && !states[fieldsValue?.state]) {
        notification.error({ message: "Invalid State", description: "Please select valid state from the dropdown menu", placement: "bottomLeft" });
        return;
      }

      this.setState({ loading: true }, async () => {
        if (selectedBillingId) {
          // If this is an existing billing address

          if (setAsDefaultBilling) await this.editBillingAddress();

          // Resets the form fields, otherwise some fields may carry over to payment
          this.props.form.resetFields(billingFormFields.concat(paymentFormFields));
          this.setState({ loading: false, currentStep: 2, billingAccepted: true });
        } else {
          // If this is a new billing address
          this.props.actions.addBillingAddress({ ...billingData, setAsDefault: setAsDefaultBilling }).then(async (res) => {
            await this.props.actions.getBillingAddresses();
            this.setState({ loading: false, billingData: this.props.billing[res], selectedBillingId: res }, async () => {
              if (res) {
                // Resets the form fields, otherwise some fields may carry over to payment
                this.props.form.resetFields(billingFormFields.concat(paymentFormFields));
                this.setState({ currentStep: 2, billingAccepted: true });
              }
            });
          });
        }
      });
    });
  }

  async handlePaymentSubmit() {
    const { payment } = this.props;
    const { paymentData, selectedPaymentId, stripe, elements, selectedBillingId } = this.state;

    // If no billing address selected
    if (!selectedBillingId) {
      notification.warn({ message: responses.status.warn, description: responses.description.billingAddressMissing, placement: "bottomLeft" });
      return;
    }

    // If no name on card
    if (!paymentData["nameOnCard"]) {
      notification.warn({ message: responses.status.warn, description: responses.description.cardDataMissing, placement: "bottomLeft" });
      return;
    }

    this.props.form.validateFields(async (err) => {
      if (err) return;
      this.setState({ loading: true }, async () => {
        let cardId = null;

        if (!selectedPaymentId) {
          // If card elem is missing
          if (!stripe || !elements) {
            this.setState({ loading: false });
            notification.warn({ message: responses.status.warn, description: responses.description.requiredDataMissing, placement: "bottomLeft" });
            return;
          }

          // Add card via stripe
          const cardElement = elements.getElement(CardElement);

          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: paymentData["nameOnCard"] || "",
            },
          });

          let paymentMethodId = get(paymentMethod, "id", "");

          cardId = await this.props.actions.addPaymentCardById(paymentMethodId);
        } else cardId = get(payment[selectedPaymentId], "cardId", "");

        this.props.actions.addSubscription({ cardId }).then((res) => {
          this.setState({ loading: false }, async () => {
            if (res) {
              // Resets the form fields, otherwise some fields may carry over to payment
              this.props.form.resetFields(billingFormFields.concat(paymentFormFields));
              this.setState({ currentStep: 3 }, () => {
                localStorage.setItem("subscription_redirect", true);
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              });
            }

            this.setState({ loading: false });
          });
        });
      });
    });
  }

  async handleCancelSubscription() {
    const { unsubscribeReason, tempSelectedId } = this.state;
    this.setState({ loading: true }, () => {
      this.props.actions.cancelSubscription({ cancellationReason: tempSelectedId, cancellationDescription: unsubscribeReason }).then((res) => {
        if (res) {
          this.setState({ currentStep: 0 }, () => {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
        }
        this.setState({ loading: false });
      });
    });
  }

  // Handles checkbox result for location list
  selectedRadioOption(e) {
    let value = e?.currentTarget?.id;
    this.setState({ tempSelectedId: value });
  }

  modalOk() {
    const { tempSelectedId, currentStep } = this.state;
    if (tempSelectedId === "new") {
      if (currentStep === 1) this.setState({ billingData: {}, selectedBillingId: null, tempSelectedId: null });
      if (currentStep === 2) this.setState({ paymentData: {}, selectedPaymentId: null, tempSelectedId: null });
    } else {
      if (currentStep === 1) this.setState({ billingData: this.props.billing[tempSelectedId], selectedBillingId: tempSelectedId, tempSelectedId: null });
      if (currentStep === 2) this.setState({ paymentData: this.props.payment[tempSelectedId], selectedPaymentId: tempSelectedId, tempSelectedId: null });
    }
    this.closeModal();
  }

  closeModal() {
    this.setState({ modalVisible: false, tempSelectedId: null, editAddress: false });
  }

  editBillingAddress() {
    return new Promise((resolve) => {
      const { billingData, setAsDefaultBilling } = this.state;

      this.setState({ savingEdit: true }, () => {
        let dataToSend = {};
        billingFormFields.forEach((field) => (dataToSend[field] = billingData[field]));
        this.props.actions.editBillingAddress({ billingId: billingData["_id"], data: { ...dataToSend, setAsDefault: setAsDefaultBilling } }).then(() => {
          this.setState({ savingEdit: false }, () => {
            return resolve(true);
          });
        });
      });
    });
  }

  showSetDefaultButton(type) {
    return (
      <div className={"set-as-default-wrapper"}>
        <Checkbox onChange={(e) => this.handleCheck(e, type)} checked={this.state[type]} style={{ marginRight: "5px" }} />
        Set as default {type === "setAsDefaultBilling" ? "billing address" : "payment method"}
      </div>
    );
  }

  handleCardChange({ elements, stripe }) {
    this.setState({ elements, stripe });
  }

  render() {
    const {
      mobileView,
      billing,
      payment,
      company,
      locations,
      mvix,
      form: { getFieldDecorator },
    } = this.props;
    const { unsubscribeReason, loadingPage, tempSelectedId, editAddress, selectedBillingId, selectedPaymentId, modalVisible, loading, savingEdit, currentStep, stateRes, billingData, paymentData, stripe, elements } = this.state;

    let subscribed = get(company, "subscribed", false);
    let allBillingFieldsCompleted = Boolean(billingData["addressLineOne"] && billingData["city"] && billingData["state"] && billingData["postalCode"]);

    return (
      <div className={"choose-plan-wrapper"}>
        <Modal
          visible={modalVisible}
          title={(editAddress ? "Edit " : "Select ") + (currentStep === 1 ? "Billing Address" : "Payment Card")}
          onOk={() => this.closeModal()}
          onCancel={() => this.closeModal()}
          destroyOnClose={true}
          forceRender={true}
          className={"modal-wrapper"}
          footer={[
            <div className={"modal-footer-main-button-group"}>
              <Button key="cancel" onClick={() => this.closeModal()}>
                Cancel
              </Button>

              {editAddress ? (
                <Button key="ok" type={"primary"} onClick={() => this.editBillingAddress()} loading={savingEdit} disabled={!allBillingFieldsCompleted}>
                  Save Changes
                </Button>
              ) : (
                <Button key="ok" type={"primary"} onClick={() => this.modalOk()} disabled={!tempSelectedId}>
                  Confirm
                </Button>
              )}
            </div>,
          ]}
        >
          <div className="info-display-wrapper">
            {currentStep === 1 && (
              <>
                {editAddress ? (
                  <>
                    <BillingForm data={billingData} getFieldDecorator={getFieldDecorator} handleChange={this.handleChange} handleSelect={this.handleSelect} onSearchChange={this.onAutoCompleteSearch} stateRes={stateRes} disabled={Boolean(selectedBillingId) && !editAddress} />
                    {this.showSetDefaultButton("setAsDefaultBilling")}
                  </>
                ) : (
                  <Radio.Group value={tempSelectedId}>
                    {Object.keys(billing).map((key) => (
                      <div key={key} id={key} className={"radio-wrapper noselect ".concat(tempSelectedId ? "selected" : "")} onClick={(e) => this.selectedRadioOption(e)}>
                        <div className={"radio-button-wrapper"}>
                          <Radio value={key} />
                        </div>
                        <div className={"radio-description-wrapper"}>
                          <div className={"radio-name"}>{get(billing[key], "addressName", "NA")}</div>
                          <div className={"radio-description"}>{getFormattedLocationAddress({ addressOne: billing[key].addressLineOne, addressTwo: billing[key].addressLineTwo })}</div>
                        </div>
                      </div>
                    ))}
                    <div id={"new"} className={"radio-wrapper noselect ".concat(tempSelectedId ? "selected" : "")} onClick={(e) => this.selectedRadioOption(e)}>
                      <div className={"radio-button-wrapper"}>
                        <Radio value={"new"} />
                      </div>
                      <div className={"radio-description-wrapper"}>
                        <div className={"radio-name"}>New Billing Address</div>
                        <div className={"radio-description"}>Select this option to create a new billing address</div>
                      </div>
                    </div>
                  </Radio.Group>
                )}
              </>
            )}

            {currentStep === 2 && (
              <Radio.Group value={tempSelectedId}>
                {Object.keys(payment).map((key) => (
                  <div key={key} id={key} className={"radio-wrapper noselect ".concat(tempSelectedId ? "selected" : "")} onClick={(e) => this.selectedRadioOption(e)}>
                    <div className={"radio-button-wrapper"}>
                      <Radio value={key} />
                    </div>
                    <div className={"radio-button-icon"}>
                      <FontAwesomeIcon icon={getCardBrandIcon(payment[key])} className={"creditCardIcon"} />
                    </div>
                    <div className={"radio-description-wrapper"}>
                      <div className={"radio-name"}>{getFormattedCardDetails(payment[key])[0]}</div>
                      <div className={"radio-description"}>{getFormattedCardDetails(payment[key])[1]}</div>
                      <div className={"radio-description"}>{payment[key]?.nameOnCard}</div>
                    </div>
                  </div>
                ))}
                <div id={"new"} className={"radio-wrapper noselect ".concat(tempSelectedId ? "selected" : "")} onClick={(e) => this.selectedRadioOption(e)}>
                  <div className={"radio-button-wrapper"}>
                    <Radio value={"new"} />
                  </div>
                  <div className={"radio-description-wrapper"}>
                    <div className={"radio-name"}>New Card</div>
                    <div className={"radio-description"}>Select this option to create a new payment card</div>
                  </div>
                </div>
              </Radio.Group>
            )}
          </div>
        </Modal>

        <PageTitle title={currentStep === 0 || currentStep === 1 || currentStep === 2 ? "Choose Your Plan" : currentStep === 3 ? "Thanks for subscribing!" : currentStep === 4 ? "Cancel Subscription" : "Page Not Found"} subtitle={currentStep === 0 || currentStep === 1 || currentStep === 2 ? "You can change your plan at any time." : currentStep === 3 ? "Changes will take effect immediently." : currentStep === 4 ? "We're sorry to see you go. Please select a reason for cancellation so we can improve ourselves." : "Please reload this page and try again."} />

        <div className={"content-wrapper"}>
          {currentStep < 3 && !subscribed && (
            <div className={"step-wrapper noselect"}>
              <Steps size="small" current={currentStep}>
                <Step className={"step-item"} title={mobileView ? "Plan" : "Choose Plan"} onClick={() => this.setState({ currentStep: 0 })} />
                <Step className={"step-item"} title={"Address"} onClick={() => this.setState({ currentStep: 1 })} />
                <Step className={"step-item"} title={"Payment"} onClick={() => this.setState({ currentStep: 2 })} />
              </Steps>
            </div>
          )}

          {/* Reward Tier Display */}
          {currentStep === 0 && (
            <div className={"plan-wrapper"}>
              <PlanOptionBlock
                currentOption={true}
                title={
                  <span>
                    {mvix ? "" : "Ambii"} <span className={"colored-title"}>Free</span> {mvix ? " Radio" : ""}
                  </span>
                }
                description={"For small businesses conscious of extra costs."}
                rowItems={freeItems}
                price={
                  <span>
                    $0 <span style={{ fontSize: "28px" }}>USD</span>
                  </span>
                }
                priceDescription={"Absolutely Free"}
                buttonText={subscribed ? "Cancel Subscription" : null}
                onClick={() => this.setState({ currentStep: 4 })}
              />
              <PlanOptionBlock
                currentOption={true}
                title={
                  <span>
                    {mvix ? "" : "Ambii"} <span className={"colored-title"}>Premium</span> {mvix ? " Radio" : ""}
                  </span>
                }
                description={"For businesses that want full coverage and control of their locations."}
                previousPerks={true}
                rowItems={premiumItems}
                price={
                  <span>
                    $16 <span style={{ fontSize: "28px" }}>USD</span>
                  </span>
                }
                priceDescription={"/ per zone and month *"}
                buttonText={subscribed ? null : "Choose Premium"}
                onClick={() => this.setState({ currentStep: 1 })}
                footnotes={premiumFootnotes}
              />
            </div>
          )}

          <Spin style={{ visibility: currentStep > 0 ? "visible" : "hidden" }} indicator={AntSpinner} spinning={loadingPage} size={"large"}>
            {/* Billing Address Process */}
            {currentStep === 1 && (
              <div className={"billing-wrapper"}>
                {selectedBillingId ? (
                  <>
                    <div className={"change-billing-wrapper noselect"}>
                      {Object.keys(billing).length > 0 && (
                        <span className={"change-billing-button"} onClick={() => this.setState({ modalVisible: true })}>
                          Change Address
                        </span>
                      )}
                    </div>
                    <div className={"selected-billing-address-wrapper"} onClick={() => this.setState({ editAddress: true, modalVisible: true })}>
                      <div className={"selected-billing-address-name"}>{get(billing[selectedBillingId], "addressName", "NA")}</div>
                      <div className={"selected-billing-address-description"}>{getLongBillingAddress({ addressOne: billing[selectedBillingId]?.addressLineOne, addressTwo: billing[selectedBillingId]?.addressLineTwo, city: billing[selectedBillingId]?.city, state: billing[selectedBillingId]?.state, postalCode: billing[selectedBillingId]?.postalCode, country: billing[selectedBillingId]?.country })}</div>
                    </div>
                    {selectedBillingId !== this.props.user?.defaultBillingAddressId && this.showSetDefaultButton("setAsDefaultBilling")}
                    <Button type={"primary"} className={"button-style"} loading={loading} onClick={() => this.handleBillingSubmit()} disabled={!selectedBillingId}>
                      Continue
                    </Button>
                  </>
                ) : (
                  <>
                    {Object.keys(billing).length > 0 && (
                      <div className={"change-billing-wrapper noselect"}>
                        <span className={"change-billing-button"} onClick={() => this.setState({ modalVisible: true })}>
                          Change Address
                        </span>
                      </div>
                    )}
                    <BillingForm data={billingData} getFieldDecorator={getFieldDecorator} handleChange={this.handleChange} handleSelect={this.handleSelect} onSearchChange={this.onAutoCompleteSearch} stateRes={stateRes} disabled={Boolean(selectedBillingId) && !editAddress} />
                    {this.showSetDefaultButton("setAsDefaultBilling")}
                    <Button type={"primary"} className={"button-style"} loading={loading} onClick={() => this.handleBillingSubmit()} disabled={!allBillingFieldsCompleted}>
                      <FontAwesomeIcon icon={faPlusCircle} className={"button-icon"} />
                      Add Address
                    </Button>
                  </>
                )}
              </div>
            )}

            {/* Payment Card Process */}
            {currentStep === 2 && (
              <div className={"billing-wrapper"}>
                {selectedPaymentId ? (
                  <>
                    <div className={"change-billing-wrapper noselect"}>
                      {Object.keys(payment).length > 0 && (
                        <span className={"change-billing-button"} onClick={() => this.setState({ modalVisible: true })}>
                          Change Card
                        </span>
                      )}
                    </div>
                    <div className={"selected-payment-wrapper"}>
                      <div className={"selected-payment-icon"}>
                        <FontAwesomeIcon icon={getCardBrandIcon(payment[selectedPaymentId])} className={"creditCardIcon"} />
                      </div>
                      <div className={"selected-payment-info-wrapper"}>
                        <div className={"selected-payment-name"}>{getFormattedCardDetails(payment[selectedPaymentId])[0]}</div>
                        <div className={"selected-payment-description"}>{getFormattedCardDetails(payment[selectedPaymentId])[1]}</div>
                        <div className={"selected-payment-description"}>{payment[selectedPaymentId]?.nameOnCard}</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={"change-billing-wrapper noselect"}>
                      {Object.keys(payment).length > 0 && (
                        <span className={"change-billing-button"} onClick={() => this.setState({ modalVisible: true })}>
                          Change Card
                        </span>
                      )}
                    </div>
                    <Form>
                      <FloatLabel required={true} label="Name on Card" placeholder="John Doe" value={paymentData["nameOnCard"]}>
                        <FormItem>
                          {getFieldDecorator("nameOnCard", {
                            rules: [{ required: true, message: formErrorMessages.required }],
                            initialValue: paymentData["nameOnCard"],
                          })(<Input onChange={(e) => this.handleChange(e, "payment")} />)}
                        </FormItem>
                      </FloatLabel>
                    </Form>
                    <ElementsConsumer>
                      {({ elements, stripe }) => (
                        <form>
                          <CardElement className={"stripe-element"} onChange={() => this.handleCardChange({ elements, stripe })} />
                        </form>
                      )}
                    </ElementsConsumer>
                  </>
                )}

                <div className={"payment-preview-wrapper"}>
                  <div className={"payment-preview-title"}>Payment Summary</div>
                  <div className={"payment-preview-item"}>
                    <div className={"payment-preview-count"}>
                      <strong className={"location-count"}>x{Object.keys(locations).length || 1}</strong> Zones
                    </div>
                    <div className={"payment-preview-price"}>$16.00</div>
                  </div>

                  <div className={"payment-preview-item"} style={{ fontWeight: "bold" }}>
                    <div className={"payment-preview-count"}>Total</div>
                    <div className={"payment-preview-price"}>${(Object.keys(locations).length || 1) * 16}.00 USD / mo</div>
                  </div>
                </div>
                <Button type={"primary"} className={"button-style"} loading={loading} onClick={() => this.handlePaymentSubmit()}>
                  Activate Subscription
                </Button>
              </div>
            )}

            {/* Payment Success - User Just Subscribed */}
            {currentStep === 3 && (
              <div id={"payment-animation-wrapper"} className={"animation-wrapper"}>
                <UncontrolledLottie animationData={PaymentSuccessAnimation} style={{ width: "50%", height: "50%" }} speed={0.75} loop={false} />

                <div className={"thank-you-text"}>
                  Thank you for subscribing with Ambii! <br /> This page should auto-refresh in 5 seconds.
                </div>
              </div>
            )}

            {/* Downgrade Menu */}
            {currentStep === 4 && (
              <div className={"billing-wrapper"}>
                <Radio.Group value={tempSelectedId}>
                  {unsubscribeOptions.map((elem) => {
                    let key = get(elem, "props.id", "");
                    let value = get(elem, "props.value", "");

                    return (
                      <div key={key} id={key} className={"radio-wrapper noselect ".concat(tempSelectedId ? "selected" : "")} onClick={(e) => this.selectedRadioOption(e)}>
                        <div className={"radio-button-wrapper"}>
                          <Radio value={key} />
                        </div>
                        <div className={"radio-description-wrapper"}>
                          <div className={"radio-name"}>{value}</div>
                          {/* <div className={"radio-description"}>{getFormattedLocationAddress({ addressOne: billing[key].addressLineOne, addressTwo: billing[key].addressLineTwo })}</div> */}
                        </div>
                      </div>
                    );
                  })}
                </Radio.Group>

                <div style={{ marginTop: "15px" }}>
                  <FloatLabel label="Additional Feedback" placeholder="The reason I am unsubscribing is because..." value={unsubscribeReason}>
                    <FormItem>
                      <TextArea rows={3} max={1000} onChange={(e) => this.setState({ unsubscribeReason: e.target.value })} />
                    </FormItem>
                  </FloatLabel>
                </div>

                <Button type={"primary"} className={"button-style"} style={{ marginTop: "25px" }} loading={loading} onClick={() => this.handleCancelSubscription()} disabled={!tempSelectedId}>
                  Cancel Subscription
                </Button>
              </div>
            )}
          </Spin>
        </div>
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ cancelSubscription, addPaymentCardById, addBillingAddress, addPaymentCard, editBillingAddress, getBillingAddresses, getPaymentCards, addSubscription }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    billing: state.billing,
    payment: state.payment,
    company: state.company,
    locations: state.locations,
    mvix: state.settings.mvix,
  };
}

const WrappedLocation = Form.create({})(ChoosePlan);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedLocation));
