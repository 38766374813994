import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { setInApp, setMVIX, auth0setLogin, setGlobalModalVisible, setWindowWidth, setAppLocationId, getLocations, getAnnouncements, getSchedules } from "./actions";
import Routes from "./routes/index";
import AppHeader from "./components/header";
import { Spin } from "antd";
import { startGoogleAnalytics } from "./configs";
import { get, isEqual } from "lodash";
import AntSpinner from "./components/antSpinner";
import { withAuth0 } from "@auth0/auth0-react";
import Auth0Helper from "./helpers/Auth0Helper";
import MobileAppCommunicator from "./helpers/MobileAppCommunicator";
import Settings from "./modals/settings";
import SignUpModal from "./modals/signup-modal";
import UncontrolledLottie from "./components/lottie/uncontrolled-lottie";
import Confetti from "./lotties/confetti.json";
import { colors } from "./constants";
import Player from "./components/player";
import "antd/dist/antd.css";
import "./App.scss";

let urlBeforeLogin = localStorage.getItem("urlBeforeLogin");
let windowResizeListener = null;
let refreshInterval = null;

// Check if we're inApp when the app loads
let inApp = get(window, "location.search", "").includes("inApp") || localStorage.getItem("inApp");
let mvix = get(window, "location.pathname", "").includes("mvix") || localStorage.getItem("mvix");

Auth0Helper.initialize(mvix);

class AmbiiRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpVisible: false,
      isLoading: true,
      showConfetti: false,
      initialDataLoaded: false, // Are we waiting on initial server data (auth)
    };
  }

  urlCheckForRerouting = (url) => {
    //for local
    if (url.includes("localhost")) {
      return url.slice(21);
    } else if (url.includes("business-dev")) {
      //for dev
      return url.slice(29);
    } else {
      //for prod
      return url.slice(25);
    }
  };

  componentWillMount() {
    // Called on sign-in (duplicates on-refresh call, so no need unless specific case)
    // Auth0Helper.getLock().on("authenticated", (data) => {});

    // Emitted when there is an unrecoverable error, for instance when no connection is available. Has the error as the only argument.
    Auth0Helper.getLock().on("unrecoverable_error", (err) => {
      console.log("auth0 - unrecoverable_error: ", { err });
    });

    // emitted when authorization fails. Has error as the only argument.
    Auth0Helper.getLock().on("authorization_error", (err) => {
      console.log("auth0 - authorization_error: ", { err });
    });

    // Called on refresh
    Auth0Helper.getLock().checkSession({}, (err, data) => {
      console.log("hit auth0 check session: ", { err, data });
      this.setState({ isLoading: false }, () => {
        if (!err && data?.idToken) this.initiateLogin(data?.idToken);
        else {
          if (localStorage.getItem("ambii_auth_jwt")) Auth0Helper.logout();
          if (get(err, "code", "") === "login_required") Auth0Helper.login();
          this.setState({ isLoading: false });
        }
      });
    });

    // Set our init width
    this.props.actions.setWindowWidth(window.innerWidth);

    // Set width on resize
    windowResizeListener = window.addEventListener("resize", (e) => {
      this.props.actions.setWindowWidth(e.target.innerWidth);
    });

    // If the user is coming from mobile app
    if (inApp) {
      this.props.actions.setInApp(true);

      // If user signs up via app, this fixes them getting stuck on a white-screen
      if (get(window, "location.href", "").includes("id_token")) window.location = window.origin.concat("/stations?inApp");
    }

    // If the user is coming from mvix
    if (mvix) this.props.actions.setMVIX(true);

    if (window.ReactNativeWebView) {
      window.addEventListener("message", (msg) => {
        let data = get(msg, "data.data", null); // Data from mobile
        let endpoint = get(msg, "data.endpoint", null); // Endpoint from mobile
        let mobileAuth = Auth0Helper.isLoggedIn() ? true : false;

        MobileAppCommunicator.send("log", { msg, endpoint, data, p: this.propsinApp });

        // Listener logic
        switch (endpoint) {
          case "auth":
            MobileAppCommunicator.send("auth", mobileAuth);
            break;
          case "locationId":
            this.props.actions.setAppLocationId(data);
            break;
          case "introLogin":
            localStorage.setItem("mobileIntroLogin", true);
            MobileAppCommunicator.send("introLogin", null);
            break;
          case "login":
            Auth0Helper.login();
            MobileAppCommunicator.send("login", "Login Success");
            break;
          case "logout":
            Auth0Helper.logout();
            MobileAppCommunicator.send("logout", "Logout Success");
            break;
          default:
            // MobileAppCommunicator.send("default", null);
            break;
        }
      });
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("resize", windowResizeListener);
      windowResizeListener = null;
      clearInterval(refreshInterval);
      refreshInterval = null;
    } catch (err) {
      console.log("error while unmounting app: ", err);
    }
  }

  componentDidMount() {
    // Update user data every few minutes
    refreshInterval = setInterval(() => {
      if (Auth0Helper.isLoggedIn()) {
        this.props.actions.getLocations();
        this.props.actions.getAnnouncements();
        this.props.actions.getSchedules();
      }
    }, 1000 * 60 * 3);
  }

  componentDidUpdate(prevProps) {
    if (Auth0Helper.isLoggedIn() && this.state.isLoading) {
      this.setState({ isLoading: false });
    }

    // If the user doesn't doesn't have company info, then they've just signed up (or are returning after not finishing)
    if (!isEqual(this.props.company, prevProps.company) && Object.keys(this.props?.company).length > 1 && !this.props?.company?.name) {
      this.setState({ signUpVisible: true });
    }
  }

  async initiateLogin(jwt) {
    localStorage.setItem("ambii_auth_jwt", jwt);

    // Wait for user auth
    let initialDataLoaded = await this.props.actions.auth0setLogin();
    this.setState({ initialDataLoaded });
  }

  signUpCompleted() {
    this.setState({ signUpVisible: false }, () => {
      setTimeout(() => {
        this.setState({ showConfetti: true });
      }, 1000);
    });
  }

  render() {
    const { mvix, inApp, showPlayer, bannerVisible } = this.props;
    const { showConfetti, signUpVisible, initialDataLoaded } = this.state;

    let paddingTop = inApp ? "0px" : `calc(72px + ${bannerVisible ? "40px" : "0px"})`;

    return (
      <Router>
        {!inApp && <AppHeader showMenu="userlogin" />}

        <div>
          {Routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={() => {
                  if (!this.state.isLoading && !Auth0Helper.isLoggedIn() && route.private) Auth0Helper.login();
                  if ((Auth0Helper.isLoggedIn() && initialDataLoaded) || !route.private) {
                    return (
                      <>
                        <SignUpModal visible={signUpVisible} onOk={() => this.signUpCompleted()} />
                        {showConfetti && (
                          <div id={"confetti-div"}>
                            <UncontrolledLottie animationData={Confetti} width={"100vw"} height={"100vh"} speed={0.3} loop={false} onFinish={() => this.setState({ showConfetti: false })} />
                          </div>
                        )}
                        <div className="explore_container" style={{ paddingTop, paddingBottom: showPlayer ? "80px" : "0px" }}>
                          <div className={"contentWrapper"}>
                            {urlBeforeLogin ? (
                              <>
                                {!this.state.isLoading && localStorage.removeItem("urlBeforeLogin")}
                                {!this.state.isLoading && <Redirect to={this.urlCheckForRerouting(urlBeforeLogin)}></Redirect>}
                                {!this.state.isLoading && (urlBeforeLogin = null)}
                              </>
                            ) : (
                              route.component()
                            )}
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <div className="loading-app">
                        {/* <div className={"logo-wrapper"}>
                          <img src={images.appLogo} className={"loading-image"} />
                        </div>                   */}
                        <div className="loading-app-text">
                          {mvix ? (
                            <span>Logging You In...</span>
                          ) : (
                            <span>
                              Logging Into <span style={{ color: colors.primaryColor }}>Ambii - Music for Business</span>
                            </span>
                          )}
                        </div>
                        <Spin indicator={AntSpinner} spinning={true} size={"large"}></Spin>
                      </div>
                    );
                  }
                }}
              />
            );
          })}
        </div>
      </Router>
    );
  }
}

function mapDispatchToPropsRouter(dispatch) {
  return {
    actions: bindActionCreators({ auth0setLogin, setInApp, setMVIX, setWindowWidth, setAppLocationId, getLocations, getAnnouncements, getSchedules }, dispatch),
  };
}

function mapStateToPropsRouter(state) {
  return {
    company: state.company,
    inApp: state.settings.inApp,
    mvix: state.settings.mvix,
  };
}

var RouterWithAuth = connect(mapStateToPropsRouter, mapDispatchToPropsRouter)(withAuth0(AmbiiRouter));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      initAnalytics: false,
      modalVisible: false, // Is our modal visible
      defaultTab: null, // Default Tab to open modal to
    };

    startGoogleAnalytics();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props?.settings?.globalModalVisible, prevProps?.settings?.globalModalVisible)) {
      let globalModalVisible = this.props?.settings?.globalModalVisible;
      if (globalModalVisible) this.showModal(globalModalVisible);
    }
  }

  // Opens modal
  showModal = async (defaultTab) => {
    this.setState({ modalVisible: true, defaultTab });
  };

  // Closes modal and resets modal states
  closeModal = () => {
    this.setState({ modalVisible: false, defaultTab: null }, () => {
      this.props.actions.setGlobalModalVisible(false);
    });
  };

  render() {
    const { modalVisible, defaultTab, isLoading } = this.state;
    const { showPlayer, bannerVisible } = this.props;
    return !isLoading ? (
      <>
        <RouterWithAuth showPlayer={showPlayer} bannerVisible={bannerVisible} />
        <Settings visible={modalVisible} onOk={this.closeModal} onCancel={this.closeModal} defaultTab={defaultTab} />
        {showPlayer && <Player />}
      </>
    ) : (
      <div className="loading-app">
        <div className="loading-app-text">
          Loading <span style={{ color: colors.primaryColor }}>Ambii - Music for Business</span>
        </div>
        <Spin indicator={AntSpinner} spinning={true} size={"large"}></Spin>
      </div>
    );
  }
}
/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setGlobalModalVisible }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    showPlayer: state.player.isVisible,
    bannerVisible: state.settings.bannerVisible,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(App));
