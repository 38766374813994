import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { assignStation, toggleFavorite, _editSchedule } from "../../actions";
import { Button, Modal, notification, Spin, Divider } from "antd";
import { logEvent } from "../../configs";
import { categories, actions, colors } from "../../constants";
import { get } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faMapMarkerAlt, faPause, faPlay, faTags, faHeadphones } from "@fortawesome/pro-solid-svg-icons";
import { getStationImageURL, objArrayToMap, filterObjectMap } from "../../util";
import Auth0Helper from "../../helpers/Auth0Helper";
import MobileAppCommunicator from "../../helpers/MobileAppCommunicator";
import AntSpinner from "../../components/antSpinner";
import LocationList from "../../components/location-list";
import { setPlayerVisiblity, createTrackList, setIsPlaying } from "../../actions";
import "./index.scss";

const { confirm } = Modal;

class StationPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startStationDisabled: false,
      favoriteDisabled: false,
      locationListVisible: false,
      selectedLocations: {},
    };
  }

  componentDidUpdate(prevProps) {
    // Reset 'locationListVisible' when we change station previews
    if (this.props.visible !== prevProps.visible) {
      this.setState({ locationListVisible: false });
    }
  }

  handleAssign = () => {
    const { locations, inApp, appLocationId } = this.props;
    const { selectedLocations } = this.state;
    let locationsWithSchedule = []; // Array of the location names that are using a schedule
    let promptUserConfig = { data: locationsWithSchedule, assignAnyways: () => this.assignStation(), closeModal: () => console.log("cancel") };

    const checkIfLocationHasSchedule = () => {
      // Check to see if any of the locations being assigned have a schedule already assigned
      Object.keys(selectedLocations).forEach((locationId) => {
        if (locations[locationId]?.schedule) locationsWithSchedule.push(locations[locationId]?.name);
      });

      // If location(s) had a schedule, inform the user
      if (locationsWithSchedule.length > 0) this.promptUserForScheduledLocations(promptUserConfig);
      else this.assignStation();
    };

    // If we're in-app, auto-assign the location the user is connected to
    if (inApp) {
      this.setState({ selectedLocations: { [appLocationId]: true } }, () => {
        checkIfLocationHasSchedule();
      });
    } else checkIfLocationHasSchedule();
  };

  assignStation() {
    const { history, selectedStationId, inApp, appLocationId, onCancel } = this.props;
    const { selectedLocations } = this.state;

    // If user is not logged in, prompt for login
    if (!Auth0Helper.isLoggedIn()) {
      Auth0Helper.signUp();
      return;
    }

    this.setState({ startStationDisabled: true }, () => {
      this.props.actions
        .assignStation({ locations: Object.keys(selectedLocations), stationId: selectedStationId })
        .then((data) => {
          this.setState({ startStationDisabled: false }, () => {
            logEvent({ category: categories.stations, action: actions.startStation, value: selectedStationId });

            if (data) {
              if (inApp) {
                // Things to do if we're using Mobile App
                let locationMap = objArrayToMap(data?.locations, "_id");
                let location = locationMap[appLocationId] || null;

                onCancel(); // close the modal

                // Sends needed data to player for instant station swap
                if (location) MobileAppCommunicator.send("newStation", { location });
                else MobileAppCommunicator.send("newStation", null);
              } else history.push("/locations");
            }
          });
        })
        .catch((err) => {
          notification.error({ message: "Error", description: "There was an error when assigning the station. Please refresh and try again.", placement: "bottomLeft" });
          this.setState({ startStationDisabled: false });
        });
    });
  }

  // Prompts user to overwrite schedule
  promptUserForScheduledLocations = ({ data, assignAnyways, closeModal }) => {
    confirm({
      maskClosable: true,
      title: "Overwrite Schedules?",
      content: (
        <>
          The following locations have schedules assigned. Are you sure you'd like to overwrite them? <br />
          <span style={{ fontWeight: "bold" }}>{data?.join(", ")}</span>
        </>
      ),
      onOk() {
        assignAnyways();
      },
      onCancel() {
        closeModal();
      },
    });
  };

  favoriteStation = (stationData) => {
    const { selectedStationId } = this.props;

    // Check if user is logged in
    if (!Auth0Helper.isLoggedIn()) {
      Auth0Helper.signUp();
      return;
    }

    // If user is logged in
    this.setState({ favoriteDisabled: true }, () => {
      let favorites = get(this.props, "user.favorites", []);
      this.props.actions.toggleFavorite({ stationId: selectedStationId, favorites, stationData }).then(() => {
        logEvent({ category: categories.general, action: actions.toggledFavorite, value: selectedStationId });
        this.setState({ favoriteDisabled: false });
      });
    });
  };

  // Handles checkbox result for location list
  assignLocationToggle(e) {
    const { selectedLocations } = this.state;
    let key = e?.currentTarget?.id;
    let newSelectedLocations = { ...selectedLocations };
    if (newSelectedLocations[key]) delete newSelectedLocations[key];
    else newSelectedLocations[key] = true;

    this.setState({ selectedLocations: newSelectedLocations });
  }

  assignStationClicked() {
    const { inApp } = this.props;

    if (inApp) this.handleAssign();
    else {
      if (Auth0Helper.isLoggedIn()) this.setState({ locationListVisible: !this.state.locationListVisible });
      else Auth0Helper.login();
    }
  }

  previewTracks = async () => {
    const { stations, selectedStationId } = this.props;

    let stationData = stations[selectedStationId];

    this.props.actions.createTrackList(stationData);
    this.props.actions.setPlayerVisiblity(true);
  };

  previewClickHandler = () => {
    const { playingStation, isPlaying, selectedStationId } = this.props;
    // Create trackList
    if (playingStation?._id !== selectedStationId) this.previewTracks();
    // Just Play or pause if trackList already created
    else if (playingStation?._id === selectedStationId && isPlaying) this.props.actions.setIsPlaying(false);
    else if (playingStation?._id === selectedStationId && !isPlaying) this.props.actions.setIsPlaying(true);
  };

  render() {
    const { visible, onOk, onCancel, loading, selectedStationId, stations, user, inApp, playingStation, isPlaying, locations } = this.props;
    const { selectedLocations, locationListVisible, startStationDisabled, favoriteDisabled, previewLoader } = this.state;
    let stationData = stations[selectedStationId];
    // TO DO: For mobile, need to add 'location favorites' so we can show favorited station
    let favoritedStation = inApp ? false : selectedStationId ? Boolean(get(user, `favoritesMap[${selectedStationId}]`, false)) : false;
    let topArtists = stationData?.top_artists || [];
    let topTracks = stationData?.top_tracks || [];
    // let genres = stationData?.genres || {};
    let tags = stationData?.tags || [];

    return (
      <Modal className={"modal-wrapper"} visible={visible} onOk={onOk} onCancel={onCancel} footer={null} bodyStyle={{ padding: 0 }} style={{ borderRadius: 8 }} destroyOnClose={true}>
        <Spin indicator={AntSpinner} spinning={loading}>
          <div className="station-preview-wrapper" style={{ backgroundImage: `${getStationImageURL({ name: stationData?.name, genres: stationData?.genres })}` }}>
            <div className={"station-preview-header"}>
              <div className={"title-section-wrapper"}>
                <div className={"header-title"}>{stationData?.name}</div>
                <div className={"header-subtitle"}>{stationData?.description}</div>
                <div className={"header-details-item"}>
                  <FontAwesomeIcon icon={faTags} className={"button-icon"} />
                  {tags.slice(0, 7).map((tag) => (
                    <div className={"tag-capsule"}> {tag} </div>
                  ))}
                </div>
              </div>

              <div className={"header-buttons"}>
                <div className={"assign-station-dropdown-wrapper"}>
                  <Button size="large" type={"primary"} onClick={() => this.assignStationClicked()} loading={inApp ? startStationDisabled : false}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className={"button-icon"} />
                    Assign
                  </Button>

                  {!inApp && locationListVisible && (
                    <div className={"assign-station-list-wrapper"}>
                      <LocationList locations={filterObjectMap("isActive", locations)} showIconForScheduledLocations={true} selected={selectedLocations} onClick={(e) => this.assignLocationToggle(e)} showSubmit={true} onSubmitClick={() => this.handleAssign()} submitButtonDisabled={startStationDisabled} history={this.props.history} />
                    </div>
                  )}
                </div>
                <Button size="large" onClick={() => this.favoriteStation(stationData)} loading={favoriteDisabled} disabled={favoriteDisabled}>
                  <FontAwesomeIcon icon={faHeart} className={"button-icon"} style={{ color: favoritedStation ? colors.primaryColor : "" }} />
                  {favoritedStation ? "Favorited" : "Favorite"}
                </Button>
                <Button size="large" onClick={() => this.previewClickHandler()} loading={previewLoader}>
                  <FontAwesomeIcon icon={selectedStationId === playingStation?._id ? (isPlaying ? faPause : faPlay) : faHeadphones} className={"button-icon"} />
                  Preview
                </Button>
              </div>
            </div>
            <div className={"station-preview-content"}>
              <div className={"section-wrapper"}>
                <div className={"section-header"}>Featured Artists</div>
                <div className={"artist-display-box spaced-scrollbar"}>
                  <div className={"artist-row-wrapper"}>
                    {topArtists.slice(0, 5).map((artist) => (
                      <div className={"artist-wrapper"}>
                        <img src={artist?.portrait_med || ""} className={"artist-image"} />
                        <div className={"artist-name"}>{artist?.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={"section-wrapper"}>
                <div className={"section-header"}>Popular Songs on this Station</div>

                <div className={"track-row-wrapper"}>
                  <div className={"track-wrapper"}>
                    <div className={"track-image"} />
                    <div className={"track-property title"}> Title </div>
                    <div className={"track-property title"}> Artist </div>
                    <div className={"track-property title album"}> Album </div>
                  </div>

                  <Divider />

                  {topTracks.slice(0, 10).map((track) => (
                    <div className={"track-wrapper"}>
                      <img src={track?.album?.cover_small || ""} className={"track-image"} />
                      <div className={"track-property"} style={{ fontWeight: "bold" }}>
                        {track?.title}
                      </div>
                      <div className={"track-property"}>{track?.artist?.name}</div>
                      <div className={"track-property album"}>{track?.album?.title}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ assignStation, toggleFavorite, _editSchedule, createTrackList, setPlayerVisiblity, setIsPlaying }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    company: state.company,
    locations: state.locations,
    stations: state.stations,
    inApp: state.settings.inApp,
    appLocationId: state.settings.appLocationId,
    playingStation: state.player.stationDetails,
    isPlaying: state.player.isPlaying,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StationPreview));
