import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { createPreviewAnnouncement, editAnnouncement, getVoiceOptions, createAnnouncement, updateUploadedAnnouncement, deleteAnnouncement } from "../../actions";
import { Tabs, Form, Button, Modal, notification, Icon } from "antd";
import { get, isEqual } from "lodash";
import { full_language_names, responses, productTypes, defaultLanguage, defaultLanguageCode, defaultVoice, defaultFrequency, defaultSpeed, defaultBackgroundMusic, colors } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faVolume, faPlusCircle, faEdit, faStopCircle, faBan, faMapMarkerAlt, faArrowRight, faArrowLeft, faCrown } from "@fortawesome/pro-solid-svg-icons";
import ReactAudioPlayer from "react-audio-player";
import md5 from "md5";
import { getAnnouncements } from "../../actions";
import { faDrum, faGuitar, faPianoKeyboard, faTriangleMusic, faWaveform } from "@fortawesome/pro-regular-svg-icons";
import Dragger from "antd/lib/upload/Dragger";
import { API_URL, AMBII_API_KEY } from "../../configs";
import { getArrayAsString, checkValidAnnouncement, filterObjectMap } from "../../util";
import LocationList from "../../components/location-list";
import AnnouncementForm from "../../components/announcement-form";
import UpgradePreview from "../../components/upgrade-preview";
import "./index.scss";

const { TabPane } = Tabs;
const { confirm } = Modal;

class AnnouncementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedISMPreviews: [], // Array of saved ISM previews
      voiceOptions: {},
      editExisting: false, // Are we editing an existing announcement
      hashOnLoad: "", // Hash of our announcement as it loads (useful for edit comparison)
      announcementEdited: false, // Was the announcement edited (used for hash grab)
      isSaving: false, // Are we currently saving an ISM
      previewURL: "", // Track URL to play (voice)
      isPreviewing: false, // Are we currently previewing an announcement
      loadingPreview: false, // Are we loading a preview
      postPreviewChanges: false, // Have any changes been made since we previewed
      savedPreviews: {},
      backgroundMusics: [
        { musicNo: 1, icon: faBan, musicName: "No Music" },
        { musicNo: 2, icon: faWaveform, musicName: "Smooth" },
        { musicNo: 3, icon: faPianoKeyboard, musicName: "Bright" },
        { musicNo: 4, icon: faTriangleMusic, musicName: "Light" },
        { musicNo: 5, icon: faDrum, musicName: "Distinct" },
        { musicNo: 6, icon: faGuitar, musicName: "Mellow" },
      ],
      selectedTime: null,
      selectedDays: [],
      specifiedTimeArray: get(this.props.data, "specifiedTimes", []),
      specifiedTimeMap: {},
      uploadedAnnouncement: false, // Has the user uploaded an announcement
      uploadedAnnouncementData: {}, // Uploaded announcement data
      tabSection: "edit", // Current tab in-focus
      isDeleting: false, // Is the user deleting a location
      announcementData: {}, // Announcement data that we're currently viewing
      steps: 2, // How many steps for new announcements
      currentStep: 1, // What step is the user on
    };
  }

  componentDidMount() {
    let locallySavedPreviews = JSON.parse(localStorage.getItem("savedISMPreviews"));
    this.props.actions.getVoiceOptions().then((voiceOptions) => {
      this.setState({ voiceOptions, sortedLanguageArray: Object.keys(voiceOptions).sort(), savedPreviews: locallySavedPreviews || {} }, () => {
        this.setAnnouncementData();
      });
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    // if ((prevState.uploadedAnnouncement !== this.state.uploadedAnnouncement) && !this.state.title) this.setState({ announcementData: { ...this.state.announcementData, ["title"]: this.props.data?.fileName } });

    // What to do if we recieve new announcement data
    if (!isEqual(this.props.data, prevProps.data)) await this.setAnnouncementData();

    // If parent sends a default tab to open to
    if (this.props.defaultTab) {
      this.setState({ tabSection: this.props.defaultTab }, () => {
        this.props.setDefaultTab(null); // reset default tab after initial set
      });
    }
  }

  // Sets 'locationData' state variable based on incoming 'locationId'
  async setAnnouncementData() {
    return new Promise((resolve) => {
      const { data } = this.props;

      const { resetFields } = this.props.form;
      let announcementData = {};
      let allFields = data ? Object.keys(data) : Object.keys(this.state.announcementData) || [];
      let announcementId = data?._id;
      let editExisting = false;
      let uploadedAnnouncement = false;

      let initArray = get(data, "specifiedTimes", []);

      let specifiedTimeMap = {};
      let specifiedTimeArray = [];

      // console.log("setAnnouncementData: ", { _id: data?._id, data, allFields, announcementId, specifiedTimeMap, specifiedTimeArray });

      if (announcementId) {
        // Clears the stored location field values (otherwise may carry over from 'add location' fields)
        resetFields(allFields);

        // Set editing to true
        editExisting = true;

        // Loop through our initial array to build a map + add 'hash' data
        initArray.forEach((item) => {
          let hash = this.createTimeHash({ days: item?.days, time: item?.time });
          let data = { ...item, hash }; // Add hash to local state's data
          specifiedTimeMap[hash] = data;
          specifiedTimeArray.push(data);
        });

        allFields.forEach((field) => {
          let value = data[field];
          if (field === "language") {
            announcementData[field] = full_language_names[value || defaultLanguageCode] || value || defaultLanguage;
            announcementData["languageCode"] = value; // Props will give us the language code. So we need to store it on-load so we don't lose it.
          } else if (field === "speed" && value) announcementData[field] = value || defaultSpeed;
          else if (field === "uploaded" && value) {
            uploadedAnnouncement = true;
            announcementData[field] = value;
          } else if (field === "assignedLocations") {
            announcementData[field] = {};
            value.forEach((location) => {
              if (!announcementData[field][location]) announcementData[field][location] = true;
            });
          } else announcementData[field] = value;
        });

        allFields.forEach((field) => {
          if (field === "assignedLocations") {
            data[field].forEach((location, index) => {
              announcementData[field] = { ...announcementData[field], [location]: true };
              delete announcementData[field][index];
            });
          }
        });
      } else {
        // Default Empty Options
        announcementData["language"] = full_language_names[defaultLanguageCode];
        announcementData["languageCode"] = defaultLanguageCode;
        announcementData["voice"] = defaultVoice;
        announcementData["speed"] = defaultSpeed;
        announcementData["frequency"] = defaultFrequency;
        announcementData["backgroundMusic"] = defaultBackgroundMusic;
        announcementData["assignedLocations"] = {};
      }

      this.setState({ tabSection: "edit", announcementData, editExisting, specifiedTimeArray, specifiedTimeMap, selectedDays: [], uploadedAnnouncement, uploadedAnnouncementData: {}, currentStep: 1 }, () => {
        // console.log("setAnnouncementData post: ", { announcementData, state: this.state });
        this.setState({ hashOnLoad: this.getAnnouncementHash() });
        resolve(true);
      });
    });
  }

  // Plays our announcement preview
  playAnnouncement() {
    try {
      let delay = setTimeout(() => {
        this.setState({ loadingPreview: false }, () => {
          this.rap.audioEl.current.play();
          clearInterval(delay);
        });
      }, 1000);
    } catch (err) {
      console.log("playAnnouncement err: ", err);
    }
  }

  stopTrack = () => {
    this.setState({ isPreviewing: false, previewURL: null }, () => {
      this.rap.audioEl.current.pause();
    });
  };

  setPreviewUrl(e) {
    this.setState({ previewURL: e });
  }

  // Checks if hash already exists. If so, returns the hash.
  checkIfHashExists() {
    const { savedPreviews } = this.state;
    let hash = this.getAnnouncementHash();
    let hashExists = savedPreviews[hash];
    if (hashExists) return hash;
    else return false;
  }

  // Creates the MD5 hash for the current announcement settings
  getAnnouncementHash() {
    const { announcementData } = this.state;
    // if (announcementData["_id"]) return announcementData["_id"];
    let hash = md5(JSON.stringify(announcementData));
    return hash;
  }

  // Creates a disposable preview MP3 we can play
  previewAnnouncement() {
    const { hashOnLoad, announcementData, uploadedAnnouncement, isPreviewing, savedPreviews, specifiedTimeArray } = this.state;

    this.setState({ loadingPreview: true }, () => {
      if (!announcementData["uploaded"]) {
        if (checkValidAnnouncement({ data: announcementData, uploadedAnnouncement, specifiedTimeArray }) && !isPreviewing) {
          // Create a hash based off the current announcement config
          let hash = this.getAnnouncementHash();

          if (!this.checkIfHashExists() && hash !== hashOnLoad) {
            this.setState({ isPreviewing: true }, () => {
              this.props.actions
                .createPreviewAnnouncement({
                  hash,
                  voice: announcementData["voice"],
                  language: announcementData["languageCode"],
                  message: announcementData["message"],
                  speed: announcementData["speed"],
                  backgroundMusic: announcementData["backgroundMusic"],
                })
                .then((res) => {
                  if (res) {
                    let previewURL = res;
                    this.setState({ previewURL, savedPreviews: { ...savedPreviews, [hash]: previewURL } }, () => {
                      localStorage.setItem("savedISMPreviews", JSON.stringify(this.state.savedPreviews)); // Save to local storage in case of page-reload
                      this.playAnnouncement();
                    });
                  } else
                    this.setState({ isPreviewing: false, loadingPreview: false }, () => {
                      this.onFailure("There was an error when creating your preview file");
                    });
                });
            });
          } else {
            let previewURL = hashOnLoad === hash ? `https://ambii-in-store-messages-bucket.s3.us-west-2.amazonaws.com/${announcementData["_id"]}.mp3` : savedPreviews[hash];
            this.setState({ isPreviewing: true, previewURL }, () => {
              this.playAnnouncement();
            });
          }
        } else this.setState({ loadingPreview: false });
      } else {
        //because uploaded announcements are already in aws
        this.setState({ isPreviewing: true, previewURL: `https://ambii-in-store-messages-bucket.s3.us-west-2.amazonaws.com/${announcementData["_id"]}.mp3` }, () => {
          this.playAnnouncement();
        });
      }
    });
  }

  // Attempts to create announcment
  handleSubmit() {
    const { announcementData, savedPreviews, uploadedAnnouncement, editExisting, specifiedTimeArray, isSaving } = this.state;
    if (checkValidAnnouncement({ data: announcementData, uploadedAnnouncement, specifiedTimeArray }) && !isSaving) {
      this.setState({ isSaving: true }, () => {
        if (editExisting) {
          // Editing an existing ISM
          this.props.actions
            .editAnnouncement({
              assignedLocations: Object.keys(announcementData["assignedLocations"]),
              announcementId: announcementData["_id"],
              title: announcementData["title"],
              voice: announcementData["voice"],
              language: announcementData["languageCode"],
              message: announcementData["message"],
              speed: announcementData["speed"],
              frequency: announcementData["frequency"],
              description: announcementData["description"] || responses.description.defaultAnnouncementDescription,
              backgroundMusic: announcementData["backgroundMusic"],
              savedPreviews,
              hash: this.checkIfHashExists(),
              specifiedTimes: announcementData["frequency"] !== "specific time" ? [] : specifiedTimeArray,
            })
            .then((res) => {
              //sends empty array when frequency is not at specific time
              if (res) this.onSuccess(announcementData);
              else this.onFailure();
            });
        } else {
          // Creating a new ISM
          if (uploadedAnnouncement) this.editAnnouncement();
          else {
            this.props.actions
              .createAnnouncement({
                assignedLocations: Object.keys(announcementData["assignedLocations"]),
                title: announcementData["title"],
                voice: announcementData["voice"],
                language: announcementData["languageCode"],
                message: announcementData["message"],
                speed: announcementData["speed"],
                frequency: announcementData["frequency"],
                description: announcementData["description"] || responses.description.defaultAnnouncementDescription,
                backgroundMusic: announcementData["backgroundMusic"],
                savedPreviews,
                hash: this.checkIfHashExists(),
                specifiedTimes: announcementData["frequency"] !== "specific time" ? [] : specifiedTimeArray,
              })
              .then((res) => {
                if (res) {
                  this.onSuccess(announcementData);
                  this.props.actions.getAnnouncements(); // Fetch new data
                } else this.onFailure();
              });
          }
        }
      });
    }
  }

  async editAnnouncement() {
    const { savedPreviews, announcementData, specifiedTimeArray, editExisting } = this.state;
    if (!announcementData["frequency"] || (announcementData["frequency"] === "specific time" && specifiedTimeArray.length === 0)) {
      if (!announcementData["frequency"]) notification.error({ message: "Frequency Required", placement: "bottomLeft" });
      notification.warn({ message: "Please select a time to play your announcement", placement: "bottomLeft" });
    } else {
      this.setState({ isSaving: true }, async () => {
        let dataToSend = {
          assignedLocations: Object.keys(announcementData["assignedLocations"]),
          companyId: announcementData["company"],
          announcementId: announcementData["_id"],
          title: announcementData["title"],
          voice: announcementData["voice"],
          language: announcementData["language"],
          message: announcementData["message"],
          speed: announcementData["speed"],
          frequency: announcementData["frequency"],
          description: announcementData["description"] || responses.description.defaultAnnouncementDescription,
          backgroundMusic: announcementData["backgroundMusic"],
          specifiedTimes: announcementData["frequency"] !== "specific time" ? [] : specifiedTimeArray,
          savedPreviews,
          editExisting,
        };

        if (announcementData["uploaded"]) {
          const response = await this.props.actions.updateUploadedAnnouncement({ ...dataToSend });
          if (response) this.onSuccess(announcementData);
        } else {
          const response = await this.props.actions.editAnnouncement(dataToSend);
          if (response) this.onSuccess(announcementData);
          else this.onFailure();
        }
      });
    }
  }

  // General success message
  async onSuccess() {
    this.setState({ isSaving: false, savedISMPreviews: [] }, async () => {
      localStorage.removeItem("savedISMPreviews"); // Remove any existing savedPreviews from local storage
      this.props.onOk();
      this.setAnnouncementData();
    });
  }

  // General fail message
  onFailure() {
    this.setState({ isSaving: false });
    this.props.onCancel();
  }

  beforeUpload(file) {
    const isMP3 = file.type === "audio/mpeg";
    const isProperSize = file.size / 1024 / 1024 < 25;

    if (!isMP3) notification.error({ message: "You can only upload MP3 files!", placement: "bottomLeft" });
    if (!isProperSize) notification.error({ message: "File must smaller than 50MB!", placement: "bottomLeft" });
    return isMP3 && isProperSize;
  }

  /**
   * Handles Input Changes
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleChange = (e) => {
    const { setFieldsValue } = this.props.form;
    const key = e.target.id;
    const value = e.target.value;

    setFieldsValue({ [key]: value });
    this.setState({ announcementData: { ...this.state.announcementData, [key]: value } });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    // console.log("handle select fired", { map, ...this.state.fieldData });
    this.setState({ fieldData: { ...this.state.fieldData, ...map }, announcementData: { ...this.state.announcementData, ...map } });
  };

  // Handles Checkbox Toggles
  checkboxTimeUpdate = (selectedDays) => {
    this.setState({ selectedDays });
  };

  // Reusuable function for creating time hash based on specified time(s)
  createTimeHash({ days, time }) {
    return md5(get(time, "unixTimeStamp", "").toString().concat(getArrayAsString(days)));
  }

  // Handles adding new times to state
  addTime = () => {
    const { selectedDays, selectedTime, specifiedTimeArray, specifiedTimeMap } = this.state;

    let timeHash = this.createTimeHash({ days: selectedDays, time: selectedTime });
    let data = { days: selectedDays, time: selectedTime, hash: timeHash };

    if (selectedTime && selectedDays.length > 0 && !specifiedTimeMap[timeHash]) this.setState({ selectedDays: [], specifiedTimeArray: [...specifiedTimeArray, data], specifiedTimeMap: { ...specifiedTimeMap, [timeHash]: data } });
    else {
      if (!selectedTime) notification.warn({ message: "Please select the time", description: "Time selection is necessary", placement: "bottomLeft" });
      if (selectedDays.length === 0) notification.warn({ message: "Please select days", description: "Days selection is necessary", placement: "bottomLeft" });
      if (specifiedTimeMap[timeHash]) notification.warn({ message: "Redundant Time", description: "Exact time selection is already in use for this message", placement: "bottomLeft" });
    }
  };

  // Handles removing times from state
  removeTime = (hashToRemove) => {
    // Create clean copies of our state data
    let specifiedTimeMap = { ...this.state.specifiedTimeMap };
    let specifiedTimeArray = [...this.state.specifiedTimeArray];

    // Delete elem from our hash
    delete specifiedTimeMap[hashToRemove];

    // Delete elem from our array
    for (let i = 0; i < specifiedTimeArray.length; i++) {
      if (specifiedTimeArray[i]?.hash === hashToRemove) specifiedTimeArray.splice(i, 1);
    }

    // Save changes to state
    this.setState({ specifiedTimeArray, specifiedTimeMap });
  };

  // Returns 'edit' form for announcements
  getEditForm() {
    const { getFieldDecorator } = this.props.form;
    const { voiceOptions, uploadedAnnouncement, uploadedAnnouncementData, announcementData, backgroundMusics, selectedDays, specifiedTimeArray, specifiedTimeMap, editExisting } = this.state;

    return (
      <div className={"edit-form-wrapper"}>
        {!editExisting && !uploadedAnnouncement && (
          <>
            <Dragger
              accept={".mp3"}
              name={"sampleFile"}
              disabled={uploadedAnnouncement}
              beforeUpload={(e) => this.beforeUpload(e)}
              action={`${API_URL}/v3/auth/announcement/upload`}
              headers={{
                Authorization: "Bearer " + localStorage.getItem("ambii_auth_jwt"),
                ambii_api_key: AMBII_API_KEY,
              }}
              onChange={({ file, fileList, event }) => {
                const { status, name, response } = file;

                if (status === "done") {
                  if (get(response, "success", false)) {
                    if (!announcementData["title"]) this.setState({ announcementData: { ...this.state.announcementData, ...response.data, title: name } });
                    this.setState({ uploadedAnnouncement: true, uploadedAnnouncementData: file, announcementData: { ...this.state.announcementData, voice: "", language: "", languageCode: "", backgroundMusic: 1 } });
                    notification.success({ message: responses.status.success, description: get(response, "message", responses.description.failedToAddData), placement: "bottomLeft" });
                  } else {
                    this.props.onCancel();
                    notification.warn({ message: responses.status.warn, description: get(response, "message", responses.description.failedToAddData), placement: "bottomLeft" });
                  }
                }
              }}
              showUploadList={{ showRemoveIcon: true }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" style={{ color: colors.primaryColorOpaque }} />
              </p>
              <p className="ant-upload-text">Upload an Announcement</p>
              <p className="ant-upload-hint">Click or drag MP3 file to upload</p>
            </Dragger>

            <div className={"or-wrapper"}>
              <div className={"or-text"}>or, create one right away</div>
              <div className={"or-line"}></div>
            </div>
          </>
        )}

        {uploadedAnnouncementData?.name && (
          <div className={"filename"}>
            <div className={"title"}>Original File Name: </div>
            {uploadedAnnouncementData?.name}
          </div>
        )}

        <AnnouncementForm data={announcementData} getFieldDecorator={getFieldDecorator} uploadedAnnouncement={uploadedAnnouncement} selectedDays={selectedDays} backgroundMusics={backgroundMusics} specifiedTimeArray={specifiedTimeArray} specifiedTimeMap={specifiedTimeMap} voiceOptions={voiceOptions} handleChange={this.handleChange} handleSelect={this.handleSelect} checkboxTimeUpdate={this.checkboxTimeUpdate} removeTime={this.removeTime} addTime={this.addTime} onSpecificTimeChange={(e) => this.setState({ ...e })} />
      </div>
    );
  }

  // Location Assignment Section
  getAssignForm() {
    const { locations } = this.props;
    const { announcementData } = this.state;

    return (
      <div className={"assign-form-wrapper"}>
        <div className={"modal-subtitle"}>Assign Locations to play this announcement at</div>
        <LocationList locations={filterObjectMap("isActive", locations)} selected={announcementData["assignedLocations"]} onClick={(e) => this.assignLocationToggle(e)} showDefault={true} history={this.props.history} />
      </div>
    );
  }

  // Handles checkbox result for location list
  assignLocationToggle(e) {
    const { announcementData } = this.state;
    let key = e?.currentTarget?.id;
    let assignedLocations = { ...announcementData["assignedLocations"] };
    if (assignedLocations[key]) delete assignedLocations[key];
    else assignedLocations[key] = true;

    this.setState({ announcementData: { ...announcementData, ["assignedLocations"]: assignedLocations } });
  }

  // When user swaps tab menus
  async newTabSelection(tabSection) {
    this.setState({ tabSection });
  }

  // Prompts user to delete an announcement
  deleteAnnouncement = ({ id, data, props, isDeleting, closeModal }) => {
    confirm({
      maskClosable: true,
      title: "Delete Announcement?",
      content: (
        <>
          Are you sure you wish to delete <br />
          <span style={{ fontWeight: "bold" }}>
            {data["title"]} - {data["message"] || data["description"]}
          </span>
        </>
      ),
      onOk() {
        isDeleting(true);
        props.actions.deleteAnnouncement(id).then(() => {
          isDeleting(false);
          closeModal();
        });
      },
      onCancel() {
        isDeleting(false);
      },
    });
  };

  render() {
    const { mvix, visible, onOk, onCancel, subscribed } = this.props;
    const { announcementData, tabSection, loadingPreview, editExisting, isSaving, isPreviewing, previewURL, specifiedTimeMap, steps, currentStep } = this.state;

    let disableSave = announcementData["frequency"] === "specific time" && Object.keys(specifiedTimeMap).length <= 0;
    let nextStepAvailable = currentStep < steps;

    let deleteAnnouncementConfig = {
      id: announcementData["_id"],
      data: announcementData,
      props: this.props,
      isDeleting: (isDeleting) => this.setState({ isDeleting }),
      closeModal: onCancel,
    };

    return (
      <Modal
        visible={visible}
        title={`${editExisting ? "Edit" : "Add"} Announcement`}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose={true}
        forceRender={true}
        className={"modal-wrapper"}
        afterClose={() => {
          this.setState({ previewURL: null, isPreviewing: false });
        }}
        footer={[
          <div className={"modal-footer-main-button-group-left"}>
            {editExisting && (
              <Button key="delete" loading={this.state.isDeleting} onClick={() => this.deleteAnnouncement(deleteAnnouncementConfig)}>
                <FontAwesomeIcon icon={faTrash} className={"button-icon"} />
                Delete
              </Button>
            )}
          </div>,

          <div className={"modal-footer-main-button-group"}>
            {tabSection !== "upgrade" && (
              <Button key="preview" onClick={() => (isPreviewing ? this.stopTrack() : this.previewAnnouncement())} loading={loadingPreview}>
                <FontAwesomeIcon icon={isPreviewing ? faStopCircle : faVolume} className={"button-icon"} />
                {isPreviewing ? "Stop Preview" : "Preview"}
              </Button>
            )}

            {editExisting && tabSection !== "upgrade" ? (
              <Button key="save" type={"primary"} onClick={() => this.editAnnouncement()} loading={isSaving} disabled={disableSave}>
                <FontAwesomeIcon icon={editExisting ? faEdit : faPlusCircle} className={"button-icon"} />
                Save Changes
              </Button>
            ) : tabSection === "upgrade" ? (
              <Button key="upgrade" type={"primary"} onClick={() => this.props.history.push("/choose-plan")} disabled={subscribed}>
                <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                Upgrade Now
              </Button>
            ) : !nextStepAvailable || tabSection === "assign" ? (
              <>
                <Button key="back" onClick={() => this.setState({ tabSection: "edit" })}>
                  <FontAwesomeIcon icon={faArrowLeft} className={"button-icon"} />
                  Back
                </Button>
                <Button key="save" type={"primary"} loading={isSaving} onClick={() => this.handleSubmit()}>
                  <FontAwesomeIcon icon={faPlusCircle} className={"button-icon"} />
                  Add Announcement
                </Button>
              </>
            ) : (
              <Button key="next" type={"primary"} onClick={() => this.setState({ tabSection: "assign" })} loading={isSaving} disabled={disableSave}>
                <FontAwesomeIcon icon={faArrowRight} className={"button-icon"} />
                Next
              </Button>
            )}
          </div>,
        ]}
      >
        <div style={{ display: "none" }}>
          <ReactAudioPlayer
            ref={(element) => {
              this.rap = element;
            }}
            src={previewURL}
            autoPlay={false}
            controls={false}
            muted={false}
            volume={0.75}
            onCanPlay={() => this.playAnnouncement()}
            onEnded={() => {
              this.setState({ isPreviewing: false, previewURL: null });
            }}
            onError={() => this.setState({ previewURL: null })}
          />
        </div>

        <div className="create-announcement-container">
          <Tabs type="card" defaultActiveKey={tabSection} activeKey={tabSection} onTabClick={(e) => this.newTabSelection(e)}>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faEdit} className={"button-icon"} />
                  Edit
                </span>
              }
              key={"edit"}
            >
              {this.getEditForm()}
            </TabPane>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className={"button-icon"} />
                  Assign
                </span>
              }
              key={"assign"}
            >
              {this.getAssignForm()}
            </TabPane>
            {!subscribed && (
              <TabPane
                tab={
                  <span className={"noselect"}>
                    <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                    Upgrade
                  </span>
                }
                key={"upgrade"}
              >
                <UpgradePreview type={productTypes.announcement} mvix={mvix} />
              </TabPane>
            )}
          </Tabs>
        </div>
      </Modal>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ createPreviewAnnouncement, editAnnouncement, createAnnouncement, getVoiceOptions, updateUploadedAnnouncement, getAnnouncements, deleteAnnouncement }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    announcements: state.announcements,
    mvix: state.settings.mvix,
  };
}

const WrappedLocation = Form.create()(AnnouncementModal);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedLocation));
