import { combineReducers } from "redux";
import announcements from "./announcements";
import billing from "./billing";
import company from "./company";
import invoices from "./invoices";
import locations from "./locations";
import payment from "./payment";
import schedules from "./schedules";
import scheduleBlocks from "./scheduleBlocks";
import settings from "./settings";
import stations from "./stations";
import user from "./user";
import zones from "./zones";
import player from "./player";

export default combineReducers({
  announcements,
  billing,
  company,
  invoices,
  locations,
  payment,
  schedules,
  scheduleBlocks,
  settings,
  stations,
  user,
  zones,
  player,
});
