import React from "react";
import { get } from "lodash";
import LocationBlock from "../location-block";
import FloatLabel from "../floating-label";
import { Input } from "antd";
import "./index.scss";

const FilterList = ({ data = [], searchText, onSearchChange, onClick }) => (
  <div className={"filter-list-wrapper"}>
    <div className="search-wrapper">
      <FloatLabel label="Search" placeholder="Search for locations by name or address" value={searchText}>
        <Input.Search value={searchText} onChange={onSearchChange} />
      </FloatLabel>
    </div>

    <div className={"list-wrapper"}>
      {data.map((location) => (
        <div onClick={() => onClick(location?._id)}>
          <LocationBlock simple={true} key={get(location, "_id", location)} data={location} />
        </div>
      ))}
    </div>
  </div>
);

export default FilterList;
