/*eslint no-case-declarations: "off"*/

import { ADD_LOCATION, EDIT_LOCATION, DELETE_LOCATION, SET_LOCATIONS, SET_STATION, SET_PAIRED_DEVICE, SET_SCHEDULE, SET_SUBSCRIPTION } from "./../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const locations_reducer = (state = initialState.locations, action) => {
  let locationId = get(action, "data._id", "").toString();
  let data = get(action, "data", {});

  switch (action.type) {
    case SET_LOCATIONS:
      return Object.assign({}, state, { ...state, ...data });
    case ADD_LOCATION:
      return Object.assign({}, state, { ...state, [locationId]: data });
    case EDIT_LOCATION:
      return Object.assign({}, state, { ...state, [locationId]: { ...state[locationId], ...data } });
    case DELETE_LOCATION:
      let newLocations = { ...state };
      delete newLocations[get(action, "data", "").toString()];
      return Object.assign({}, newLocations);
    case SET_STATION:
      delete data._id; // Removes location ID from being added
      return Object.assign({}, state, { [locationId]: { ...state[locationId], ...data } });
    case SET_PAIRED_DEVICE:
      return Object.assign({}, state, { ...state.location, pairedDevice: action.data });
    case SET_SCHEDULE:
      return Object.assign({}, state, { ...state.location, schedule: action.data });
    case SET_SUBSCRIPTION:
      return Object.assign({}, state, { ...state.location, subscription: action.data });
    default:
      return state;
  }
};

export default locations_reducer;
