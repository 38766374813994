import { HTTP } from "../configs";
import { _addBillingAddress, _editBillingAddress, _setBillingAddresses, _deleteBillingAddress } from "./dispatch";
import { get } from "lodash";
import { notification } from "antd";
import { objArrayToMap } from "../util";
import { responses } from "../constants";

// Adds a billing address to the user's profile
export function addBillingAddress(incomingData) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("post", "/v3/auth/billing-address", incomingData)
        .then(({ success, message, data }) => {
          let newBillingId = get(data, "billingAddressId", "");
          let newBillingAddress = { ...incomingData, _id: newBillingId };
          if (success) dispatch(_addBillingAddress(newBillingAddress));
          else notification.error({ message: responses.status.error, description: responses.description.failedToAddData, placement: "bottomLeft" });
          resolve(newBillingId);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Updates a user's billing address
export function editBillingAddress({ billingId, data }) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("patch", `/v3/auth/billing-address/${billingId}`, data)
        .then(({ success, message }) => {
          if (success) {
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
            dispatch(_editBillingAddress({ _id: billingId, ...data }));
          } else notification.error({ message: responses.status.error, description: responses.description.failedToEditData, placement: "bottomLeft" });
          resolve(success);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Deletes a user's billing address
export function deleteBillingAddress(incomingId) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("delete", `/v3/auth/billing-address/${incomingId}`)
        .then(({ success, message, data }) => {
          if (success) dispatch(_deleteBillingAddress(data));
          else notification.error({ message: responses.status.error, description: responses.description.failedToDeleteData, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Retrieves all of the user's billing addresses
export function getBillingAddresses() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", "/v3/auth/billing-address")
        .then(({ success, message, data }) => {
          if (success) {
            let billingMap = objArrayToMap(data, "_id");
            if (Object.keys(billingMap).length > 0) dispatch(_setBillingAddresses(billingMap));
          }
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
}
