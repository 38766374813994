/*eslint no-case-declarations: "off"*/

import { SET_INVOICES } from "../constants";
import initialState from "../redux-store/initialState";

const invoices_reducer = (state = initialState.invoices, action) => {
  switch (action.type) {
    case SET_INVOICES:
      return Object.assign([], state, action.data);
    default:
      return state;
  }
};

export default invoices_reducer;
