import {
  SET_BILLING_ADDRESSES,
  DELETE_BILLING_ADDRESS,
  EDIT_BILLING_ADDRESS,
  ADD_BILLING_ADDRESS,
  SET_WINDOW_WIDTH,
  EDIT_SCHEDULE_BLOCK,
  SET_SCHEDULE_BLOCKS,
  DELETE_SCHEDULE,
  EDIT_SCHEDULE,
  ADD_SCHEDULE,
  SET_GLOBAL_MODAL_VISIBLE,
  ADD_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION,
  SET_ANNOUNCEMENTS,
  DELETE_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_HASH,
  SET_APP_LOCATION_ID,
  SET_INAPP,
  SET_MVIX,
  SET_IS_APPLE,
  SET_STATIONS,
  SET_SCHEDULE,
  SET_SCHEDULES,
  SET_FAVORITES,
  SET_COMPANY,
  SET_USER,
  SET_LOCATIONS,
  SET_PAIRED_DEVICE,
  SET_STATION,
  SET_SUBSCRIPTION,
  ADD_CARD,
  DELETE_CARD,
  SET_CARDS,
  ADD_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  SET_BANNER_VISIBLE,
  SET_ZONES,
  EDIT_ZONE,
  DELETE_ZONE,
  SET_INVOICES,
  TOGGLE_VISIBLE,
  SET_PLAYER_VISIBLITY,
  SET_IS_PLAYING,
  SET_STATION_DETAILS,
  SET_CURRENT_TRACK,
  SET_TRACKS_LIST,
  SET_CURRENT_TRACK_NUMBER,
} from "../constants";

export const setInvoices = (data) => (dispatch) => {
  dispatch({
    type: SET_INVOICES,
    data,
  });
};

export const setZones = (data) => (dispatch) => {
  dispatch({
    type: SET_ZONES,
    data,
  });
};

export const _editZone = (data) => (dispatch) => {
  dispatch({
    type: EDIT_ZONE,
    data,
  });
};

export const _deleteZone = (data) => (dispatch) => {
  dispatch({
    type: DELETE_ZONE,
    data,
  });
};

export const _addSubscription = (data) => (dispatch) => {
  dispatch({
    type: ADD_SUBSCRIPTION,
    data,
  });
};

export const _cancelSubscription = (data) => (dispatch) => {
  dispatch({
    type: CANCEL_SUBSCRIPTION,
    data,
  });
};

export const _addCard = (data) => (dispatch) => {
  dispatch({
    type: ADD_CARD,
    data,
  });
};

export const _deleteCard = (data) => (dispatch) => {
  dispatch({
    type: DELETE_CARD,
    data,
  });
};

export const _setCards = (data) => (dispatch) => {
  dispatch({
    type: SET_CARDS,
    data,
  });
};

export const _addBillingAddress = (data) => (dispatch) => {
  dispatch({
    type: ADD_BILLING_ADDRESS,
    data,
  });
};

export const _editBillingAddress = (data) => (dispatch) => {
  dispatch({
    type: EDIT_BILLING_ADDRESS,
    data,
  });
};

export const _deleteBillingAddress = (data) => (dispatch) => {
  dispatch({
    type: DELETE_BILLING_ADDRESS,
    data,
  });
};

export const _setBillingAddresses = (data) => (dispatch) => {
  dispatch({
    type: SET_BILLING_ADDRESSES,
    data,
  });
};

export const setScheduleBlocks = (data) => (dispatch) => {
  dispatch({
    type: SET_SCHEDULE_BLOCKS,
    data,
  });
};

export const editScheduleBlock = (data) => (dispatch) => {
  dispatch({
    type: EDIT_SCHEDULE_BLOCK,
    data,
  });
};

export const _addSchedule = (data) => (dispatch) => {
  dispatch({
    type: ADD_SCHEDULE,
    data,
  });
};

export const _deleteSchedule = (data) => (dispatch) => {
  dispatch({
    type: DELETE_SCHEDULE,
    data,
  });
};

export const _editSchedule = (data) => (dispatch) => {
  dispatch({
    type: EDIT_SCHEDULE,
    data,
  });
};

export const setInApp = (data) => (dispatch) => {
  dispatch({
    type: SET_INAPP,
    data,
  });
};

export const setMVIX = (data) => (dispatch) => {
  dispatch({
    type: SET_MVIX,
    data,
  });
};

export const setAppLocationId = (data) => (dispatch) => {
  dispatch({
    type: SET_APP_LOCATION_ID,
    data,
  });
};

export const setGlobalModalVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_MODAL_VISIBLE,
    data,
  });
};

export const setIsApple = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_APPLE,
    data,
  });
};

export const setWindowWidth = (data) => (dispatch) => {
  dispatch({
    type: SET_WINDOW_WIDTH,
    data,
  });
};

export const setBannerVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_BANNER_VISIBLE,
    data,
  });
};

export const setFavorites = (data) => (dispatch) => {
  dispatch({
    type: SET_FAVORITES,
    data,
  });
};

export const setPairedDevice = (data) => (dispatch) => {
  dispatch({
    type: SET_PAIRED_DEVICE,
    data,
  });
};

export const setLocations = (data) => (dispatch) => {
  dispatch({
    type: SET_LOCATIONS,
    data,
  });
};

export const _addLocation = (data) => (dispatch) => {
  dispatch({
    type: ADD_LOCATION,
    data,
  });
};

export const _deleteLocation = (data) => (dispatch) => {
  dispatch({
    type: DELETE_LOCATION,
    data,
  });
};

export const _editLocation = (data) => (dispatch) => {
  dispatch({
    type: EDIT_LOCATION,
    data,
  });
};

export const setUser = (data) => (dispatch) => {
  dispatch({
    type: SET_USER,
    data,
  });
};

export const setCompany = (data) => (dispatch) => {
  dispatch({
    type: SET_COMPANY,
    data,
  });
};

// Updates the schedule property in 'locations' reducer
export const setSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_SCHEDULE,
    data,
  });
};

// Sets schedules to 'schedules' reducer
export const setSchedules = (data) => (dispatch) => {
  dispatch({
    type: SET_SCHEDULES,
    data,
  });
};

// Updates the station propery in 'locations' reducer
export const setStation = (data) => (dispatch) => {
  dispatch({
    type: SET_STATION,
    data,
  });
};

// Sets stations in 'stations' reducer
export const setStations = (data) => (dispatch) => {
  dispatch({
    type: SET_STATIONS,
    data,
  });
};

export const setSubscription = (data) => (dispatch) => {
  dispatch({
    type: SET_SUBSCRIPTION,
    data,
  });
};

export function setAnnouncements(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_ANNOUNCEMENTS,
      data,
    });
  };
}

export function _deleteAnnouncement(data) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT,
      data,
    });
  };
}

export function setAnnouncementHash(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_ANNOUNCEMENT_HASH,
      data,
    });
  };
}

export const togglePlayerVisiblity = () => (dispatch) => dispatch({ type: TOGGLE_VISIBLE });
export const setPlayerVisiblity = (data) => (dispatch) => dispatch({ type: SET_PLAYER_VISIBLITY, data });
export const setIsPlaying = (data) => (dispatch) => dispatch({ type: SET_IS_PLAYING, data });
export const setStationDetails = (data) => (dispatch) => dispatch({ type: SET_STATION_DETAILS, data });
export const setTrackList = (data) => (dispatch) => dispatch({ type: SET_TRACKS_LIST, data });
export const setCurrentTrackNumber = (data) => (dispatch) => dispatch({ type: SET_CURRENT_TRACK_NUMBER, data });
export const setCurrentTrack = (data) => (dispatch) => dispatch({ type: SET_CURRENT_TRACK, data });
