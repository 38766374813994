import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";

export * from "./actionTypes";
export * from "./analytics";
export * from "./formFields";
export * from "./images";
export * from "./serverResponses";
export * from "./supersetDescriptions";
export * from "./supersets";
export * from "./tempos";
export * from "./states";
export * from "./variables";
export * from "./voices";

export const envTypes = {
  local: "local",
  dev: "dev",
  prod: "production",
};

export const userTypes = {
  owner: "owner",
  manager: "manager",
};

export const formErrorMessages = {
  required: "This field is required.",
};

export const defaultStationDisplayLimit = 12;

export const defaultScheduleBlocks = {
  defaultOne: {
    name: "Morning",
    startTime: 21600,
    endTime: 43200,
    days: [0, 1, 2, 3, 4, 5, 6],
    genres: {},
    tempo: {},
  },
  defaultTwo: {
    name: "Afternoon",
    startTime: 43200,
    endTime: 64800,
    days: [0, 1, 2, 3, 4, 5, 6],
    genres: {},
    tempo: {},
  },
  defaultThree: {
    name: "Evening",
    startTime: 64800,
    endTime: 86400,
    days: [0, 1, 2, 3, 4, 5, 6],
    genres: {},
    tempo: {},
  },
  defaultFour: {
    name: "Late Night",
    startTime: 0,
    endTime: 21600,
    days: [0, 1, 2, 3, 4, 5, 6],
    genres: {},
    tempo: {},
  },
};

// Should have same key names as 'defaultScheduleBlocks'
export const devQuickFillScheduleBlocks = {
  defaultOne: { name: "Morning", startTime: 21600, endTime: 43200, days: [0, 1, 2, 3, 4, 5, 6], genres: { electro: 100 }, tempo: { min: 121, max: 127, selected: 2, name: "Moderate" }, newHash: "80df341b7bbef67bcc96904ef8babda6" },
  defaultTwo: { name: "Afternoon", startTime: 43200, endTime: 64800, days: [0, 1, 2, 3, 4, 5, 6], genres: { rock: 100 }, tempo: { min: 111, max: 120, selected: 1, name: "Slow" }, newHash: "bfdff3f274271594cf96577603e93ab2" },
  defaultThree: { name: "Evening", startTime: 64800, endTime: 86400, days: [0, 1, 2, 3, 4, 5, 6], genres: { jazz: 100, alternative: 100 }, tempo: { min: 0, max: 110, selected: 0, name: "Slower" }, newHash: "05f638f7180c0e7d4c5088ca019a6426" },
  defaultFour: { name: "Late Night", startTime: 0, endTime: 21600, days: [0, 1, 2, 3, 4, 5, 6], genres: { pop: 100, dance: 100 }, tempo: { min: 121, max: 127, selected: 2, name: "Moderate" }, newHash: "77ee2d042c46c8cc17f11d72b3b6c26a" },
};

export const productTypes = {
  location: "location",
  schedule: "schedule",
  announcement: "announcement",
};

export const locationSettingTypes = {
  delete: "delete",
  deactivate: "deactivate",
  activate: "activate",
  upgrade: "upgrade",
};

export const defaultLanguage = "English (US)";
export const defaultLanguageCode = "en-US";
export const defaultVoice = "en-US-Wavenet-A";
export const defaultFrequency = "30";
export const defaultSpeed = 1;
export const defaultBackgroundMusic = 2;

export const upgradePerks = [
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "No hardware purchase required" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Multiple music streaming platforms" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Hundreds of ready-made stations" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Explicit-lyrics filter" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Locations" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Announcements" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Schedules" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Music licenses for business" },
];

export const freeItems = [
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "No hardware purchase required" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Multiple music streaming platforms" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Hundreds of ready-made stations" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "1x Locations" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "1x Announcement" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "1x Schedule" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Explicit-lyrics filter" },
  { icon: <FontAwesomeIcon icon={faTimes} className={"not-included"} />, text: "Music licenses for business" },
];

export const premiumItems = [
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Everything in Free tier" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Locations" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Announcements" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Unlimited Schedules" },
  { icon: <FontAwesomeIcon icon={faCheck} className={"included"} />, text: "Music licenses for business" },
];

export const genreProperNames = {
  "Electronica": "Electronic",
  "Alternative & Punk": "Alternative",
  "Rock": "Rock",
  "Pop": "Pop",
  "Classical": "Classical",
  "Traditional": "Traditional",
  "Jazz": "Jazz",
  "Soundtrack": "Soundtrack",
  "Urban": "Urban",
  "Other": "Other",
};

export const genreColors = {
  "pop": "#ECACCF",
  "rock": "#C4C4C0",
  "alternative": "#D7BCAD",
  "dance": "#ABE9ED",
  "electro": "#F8E5B5",
  "rap/hip hop": "#CFCFC9",
  "r&b": "#BFB0BA",
  "folk": "#C6B0E8",
  "country": "#C7EFBD",
  "jazz": "#B3B6D0",
  "blues": "#F5A3A8",
  "classical": "#ADCFEB",
  "soul & funk": "#F3C6A5",
};

export const frequencies = [
  { title: "Play at Specific Time(s)", time: "specific time" },
  { title: "Every 15 Minutes", time: "15" },
  { title: "Every 30 Minutes", time: "30" },
  { title: "Every Hour", time: "60" },
  { title: "Every Few Hours", time: "180" },
  { title: "Inactive", time: "0" },
];

export const defaultLocationSettings = {
  explicitAllowed: false,
  allowPlayerToChangeStation: false,
};
