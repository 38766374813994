import React from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tempos } from "../../constants";
import "./index.scss";

const ButtonGroup = Button.Group;

const TempoSelection = (props) => {
  let tempoKeys = Object.keys(tempos);

  const performSelectTempo = (minMax, index, tempo) => () => {
    if (index !== props.currentSelectedTempo) props.onSelectTempo(minMax, index, tempo);
    else props.onDeselectTempo();
  };

  return (
    <div className="tempo-container">
      <ButtonGroup>
        {tempoKeys.map((tempo, i) => (
          <Button key={tempo} onClick={performSelectTempo([tempos[tempo].min, tempos[tempo].max], i, tempo)} type={i === props.currentSelectedTempo ? "primary" : "secondary"}>
            <FontAwesomeIcon icon={tempos[tempo].icon} className={"button-icon"} />
            {tempos[tempo].title}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default TempoSelection;
