/*eslint no-case-declarations: "off"*/

import { SET_USER, SET_FAVORITES } from "../constants";
import initialState from "../redux-store/initialState";
import { objArrayToMap } from "../util";
import { get } from "lodash";

const user_reducer = (state = initialState.user, action) => {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, { ...action.data, favoritesMap: objArrayToMap(get(action, "data.favorites", []), "_id") });
    case SET_FAVORITES:
      return Object.assign({}, state, { favorites: action.data, favoritesMap: objArrayToMap(get(action, "data", []), "_id") });
    default:
      return state;
  }
};

export default user_reducer;
