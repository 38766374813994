export const images = {
  googleBtn: require("../common/assets/images/androidBtn.png"),
  iosBtn: require("../common/assets/images/appleBtn.png"),
  appLogo: require("../common/assets/images/appLogo.png"),
  bg1: require("../common/assets/images/bg1.png"),
  bg2: require("../common/assets/images/bg2.png"),
  bg3: require("../common/assets/images/bg3.png"),
  bg4: require("../common/assets/images/bg4.jpg"),
  bg5: require("../common/assets/images/bg5.jpg"),
  bg6: require("../common/assets/images/bg6.jpg"),
};
