/*eslint no-case-declarations: "off"*/

import { ADD_CARD, DELETE_CARD, SET_CARDS } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const payment_reducer = (state = initialState.payment, action) => {
  let cardId = get(action, "data.cardId", "").toString();
  let data = get(action, "data", {});

  switch (action.type) {
    case SET_CARDS:
      return Object.assign({}, state, { ...state, ...data });
    case ADD_CARD:
      return Object.assign({}, state, { ...state, [cardId]: data });
    case DELETE_CARD:
      let newCards = { ...state };
      delete newCards[get(action, "data", "").toString()];
      return Object.assign({}, newCards);
    default:
      return state;
  }
};

export default payment_reducer;
