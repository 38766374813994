import { SET_STATIONS } from "../constants";
import initialState from "../redux-store/initialState";

const stations_reducer = (state = initialState.stations, action) => {
  switch (action.type) {
    case SET_STATIONS:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};
export default stations_reducer;
