/*eslint no-case-declarations: "off"*/

import { SET_SCHEDULES, ADD_SCHEDULE, EDIT_SCHEDULE, DELETE_SCHEDULE } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const schedules_reducer = (state = initialState.schedules, action) => {
  let scheduleId = get(action, "data._id", "").toString();
  let data = get(action, "data", {});

  // console.log("schedules reducer: ", { state, action, scheduleId, data })

  switch (action.type) {
    case SET_SCHEDULES:
      return Object.assign({}, state, { ...state, ...data });
    case ADD_SCHEDULE:
      return Object.assign({}, state, { ...state, [scheduleId]: data });
    case EDIT_SCHEDULE:
      return Object.assign({}, state, { ...state, [scheduleId]: { ...state[scheduleId], ...data } });
    case DELETE_SCHEDULE:
      let newSchedules = { ...state };
      delete newSchedules[get(action, "data", "").toString()];
      return Object.assign({}, newSchedules);
    default:
      return state;
  }
};
export default schedules_reducer;
