import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { get, isEqual } from "lodash";
import { Dropdown, Menu, Button, notification, Tooltip } from "antd";
import { API_URL, logEvent } from "../../configs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAcorn, faMicrophone, faClock, faSignalStream, faCog, faStoreAlt, faUser, faCrown } from "@fortawesome/pro-solid-svg-icons";
import { categories, actions, images, responses } from "../../constants";
import { withAuth0 } from "@auth0/auth0-react";
import Auth0Helper from "../../helpers/Auth0Helper";
import { faSignOut, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Animated } from "react-animated-css";
import { setGlobalModalVisible, setBannerVisible } from "../../actions";
import moment from "moment";
import "./index.scss";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: undefined,
      userInitialsVisible: false,
      showBanner: false,
    };
  }

  componentDidMount() {
    let str = get(this.props, "location.pathname", "");
    var activePath = str.replace("/", "");
    this.setState({ current: activePath });
    this.showBanner();
  }

  componentDidUpdate(prevProps) {
    // Check if subscription changed
    if (!isEqual(get(this.props, "company.subscribed", false), get(prevProps, "company.subscribed", false))) {
      this.showBanner();
    }

    // Check if user obj changed
    if (!isEqual(get(this.props, "user", false), get(prevProps, "user", false))) {
      this.showBanner();
    }
  }

  // Logic to determine if we should show banner
  showBanner() {
    const { company, user } = this.props;
    if (get(company, "subscribed", false)) this.setBannerValue(false);
    else {
      let bannerHiddenTime = localStorage.getItem("showBannerAfter");

      // Wait until we have user data to show banner
      if (Object.keys(user).length > 1) {
        if (bannerHiddenTime) {
          if (moment(Date.now()).diff(bannerHiddenTime) > 0) {
            localStorage.removeItem("showBannerAfter");
            this.setBannerValue(true);
          } else this.setBannerValue(false);
        } else this.setBannerValue(true);
      }
    }
  }

  // Logic to hide banner
  hideBanner(e) {
    e.stopPropagation();
    let showAgainDate = moment(Date.now()).add(5, "days");
    localStorage.setItem("showBannerAfter", showAgainDate);
    this.setBannerValue(false);
  }

  setBannerValue(value) {
    this.setState({ showBanner: value });
    this.props.actions.setBannerVisible(value);
  }

  handleClick = async (route) => {
    if (route === "auth") this.logInOut();
    else if (route === "company" || route === "billing" || route === "profile") this.props.actions.setGlobalModalVisible(route);
    else if (route === "debug") this.printStuff();
    else {
      this.setState({ current: route });
      this.props.history.push("/" + route);
    }
  };

  logInOut = () => {
    if (Auth0Helper.isLoggedIn()) {
      try {
        logEvent({ category: categories.general, action: actions.signout });
        Auth0Helper.logout();
        notification.success({ message: responses.action.signOut, description: responses.description.signOutSuccess, placement: "bottomLeft" });
      } catch (err) {
        console.log("Caught logout err: ", err);
      }
    } else Auth0Helper.login();
  };

  checkIfActiveTab(tab) {
    if (!tab) return "";
    return get(this.props, "location.pathname", "").toLowerCase().includes(tab.toLowerCase());
  }

  printStuff() {
    const { locations } = this.props;
    console.log({ entireReduxStore: this.props.state, props: this.props, locations });
  }

  // Reusable logic for 'sign in / sign out'
  getAuthVerbiage() {
    return (
      <>
        {Auth0Helper.isLoggedIn() ? (
          <>
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faSignOut} rotation={180} />
            </div>
            <div className={"icon-menu-text"}>Sign Out</div>
          </>
        ) : (
          <>
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faSignOut} />
            </div>
            <div className={"icon-menu-text"}>Sign In</div>
          </>
        )}
      </>
    );
  }

  // Menu to show on mobile-view
  getMobileMenu(devMode) {
    const { isApple } = this.props;
    const { current } = this.state;

    return (
      <Menu mode="horizontal" onClick={(e) => this.handleClick(e.key)} selectedKeys={[current]} className="menu-wrapper">
        <Menu.Item className="icon-menu-item" key="locations">
          <div className={"icon-menu-icon"}>
            <FontAwesomeIcon icon={faStoreAlt} />
          </div>
          <div className={"icon-menu-text"}>Locations</div>
        </Menu.Item>
        <Menu.Item className="icon-menu-item" key="stations">
          <div className={"icon-menu-icon"}>
            <FontAwesomeIcon icon={faSignalStream} />
          </div>
          <div className={"icon-menu-text"}>Stations</div>
        </Menu.Item>
        <Menu.Item className="icon-menu-item" key="schedules">
          <div className={"icon-menu-icon"}>
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className={"icon-menu-text"}>Schedules</div>
        </Menu.Item>
        <Menu.Item className="icon-menu-item" key="announcements">
          <div className={"icon-menu-icon"}>
            <FontAwesomeIcon icon={faMicrophone} />
          </div>
          <div className={"icon-menu-text"}>Announcements</div>
        </Menu.Item>

        {!isApple && (
          <Menu.Item className="icon-menu-item" key="choose-plan">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faCrown} />
            </div>
            <div className={"icon-menu-text"}>Upgrade</div>
          </Menu.Item>
        )}

        {Auth0Helper.isLoggedIn() && (
          <Menu.Item className="icon-menu-item" key="company">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faCog} />
            </div>
            <div className={"icon-menu-text"}>Settings</div>
          </Menu.Item>
        )}

        {devMode && (
          <Menu.Item className="icon-menu-item" key="debug">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faAcorn} />
            </div>
            <div className={"icon-menu-text"}>Debug</div>
          </Menu.Item>
        )}

        <Menu.Item className="icon-menu-item" key="auth">
          {this.getAuthVerbiage()}
        </Menu.Item>
      </Menu>
    );
  }

  // The drop-down user menu
  getUserMenu(devMode) {
    const { isApple } = this.props;

    return (
      <Menu onClick={(e) => this.handleClick(e.key)}>
        {devMode && (
          <Menu.Item key="debug" className="icon-menu-item">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faAcorn} />
            </div>
            <div className={"icon-menu-text"}>Debug</div>
          </Menu.Item>
        )}
        {Auth0Helper.isLoggedIn() && (
          <Menu.Item key="company" className="icon-menu-item">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faCog} />
            </div>
            <div className={"icon-menu-text"}>Settings</div>
          </Menu.Item>
        )}

        {!isApple && (
          <Menu.Item key="choose-plan" className="icon-menu-item">
            <div className={"icon-menu-icon"}>
              <FontAwesomeIcon icon={faCrown} />
            </div>
            <div className={"icon-menu-text"}>Upgrade</div>
          </Menu.Item>
        )}

        <Menu.Item className="icon-menu-item" key="auth">
          {this.getAuthVerbiage()}
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { inApp, isApple, windowWidth, mvix } = this.props;
    const { userInitialsVisible, showBanner } = this.state;
    let devMode = API_URL.includes("localhost") || API_URL.includes("-dev");
    let userInitials = get(this.props, "user.firstName", "").substring(0, 1) + get(this.props, "user.lastName", "").substring(0, 1);

    return (
      <div className={"header-container"}>
        <div className={"header-content"}>
          {
            // No branding allowed for MVIX
            !mvix && (
              <div className={"logo-wrapper"}>
                <img src={images.appLogo} className="header_logo pointer noselect" onClick={() => this.props.history.push("/locations")} alt="logo" />
              </div>
            )
          }

          <div style={{ flex: 0 }}>{devMode && inApp ? "inApp Mode" : ""}</div>

          {windowWidth <= 800 ? (
            <div className={"mobileButtonsContainer"}>
              <Dropdown overlay={this.getMobileMenu(devMode)} trigger={["click"]}>
                <Button>
                  <FontAwesomeIcon icon={faBars} />
                </Button>
              </Dropdown>
            </div>
          ) : (
            <>
              <div className="mainButtonsContainer noselect">
                <div className={"main-button-background"}>
                  <div className={"mainButtons" + (this.checkIfActiveTab("locations") ? " mainButtonActive" : "")} key="locations" onClick={() => this.handleClick("locations")}>
                    <div className="mainButtonsIcon">
                      <FontAwesomeIcon icon={faStoreAlt} />
                    </div>
                    <div className="mainButtonsText">Locations</div>
                  </div>

                  <div className={"mainButtons" + (this.checkIfActiveTab("stations") || this.checkIfActiveTab("browse") ? " mainButtonActive" : "")} key="stations" onClick={() => this.handleClick("stations")}>
                    <div className="mainButtonsIcon">
                      <FontAwesomeIcon icon={faSignalStream} />
                    </div>
                    <div className="mainButtonsText">Stations</div>
                  </div>

                  <div className={"mainButtons" + (this.checkIfActiveTab("schedules") ? " mainButtonActive" : "")} key="schedules" onClick={() => this.handleClick("schedules")}>
                    <div className="mainButtonsIcon">
                      <FontAwesomeIcon icon={faClock} />
                    </div>
                    <div className="mainButtonsText">Schedules</div>
                  </div>

                  <div className={"mainButtons" + (this.checkIfActiveTab("announcements") || this.checkIfActiveTab("announcements") ? " mainButtonActive" : "")} key="announcements" onClick={() => this.handleClick("announcements")}>
                    <div className="mainButtonsIcon">
                      <FontAwesomeIcon icon={faMicrophone} />
                    </div>
                    <div className="mainButtonsText">Announcements</div>
                  </div>
                </div>
              </div>

              {get(this.props, "company.subscribed", false) && (
                <div className={"subscribed-icon"}>
                  <Animated animationIn="fadeInRight" isVisible={true}>
                    <Tooltip title="You are subscribed">
                      <FontAwesomeIcon icon={faCrown} />
                    </Tooltip>
                  </Animated>
                </div>
              )}

              <div onMouseOver={() => this.setState({ userInitialsVisible: true })} onMouseLeave={() => this.setState({ userInitialsVisible: false })}>
                <Dropdown overlay={this.getUserMenu(devMode)} placement="bottomRight" overlayClassName={"user-menu-style"}>
                  <Button key="user" type={"primary"} className={"animated-button"}>
                    <FontAwesomeIcon icon={faUser} className={"animated-button-icon ".concat(userInitialsVisible ? "button-icon" : "")} style={{ transition: ".2s ease" }} />
                    {userInitialsVisible && (
                      <Animated animationIn="fadeInRight" isVisible={true}>
                        {userInitials}
                      </Animated>
                    )}
                  </Button>
                </Dropdown>
              </div>
            </>
          )}
        </div>
        {!isApple && showBanner && (
          <div id={"subscribe-banner"} onClick={() => this.props.history.push("/choose-plan")}>
            <div className={"banner-item"}>You are using {mvix ? "this app's" : " Ambii's"} free version. For the full experience, please upgrade your account</div>
            <div className={"banner-item"} style={{ justifyContent: "flex-end", flex: 0 }}>
              <FontAwesomeIcon icon={faTimes} className={"banner-close"} onClick={(e) => this.hideBanner(e)} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setGlobalModalVisible, setBannerVisible }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    inApp: state.settings.inApp,
    mvix: state.settings.mvix,
    state: state,
    isApple: state.settings.isApple,
    company: state.company,
    windowWidth: state.settings.windowWidth,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth0(AppHeader)));
