import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollContainer from "react-indiana-drag-scroll";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../constants";
import "./index.scss";

export default class GenresSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArrows: false,
      showLeftArrow: false,
      showRightArrow: true,
      diff: 0,
    };
  }

  componentDidMount() {
    const outer = this.scrollView.getElement().clientWidth;
    const inner = this.scrollWrapper.clientWidth;

    this.setState({ diff: inner - outer });

    if (inner > outer) this.setState({ showArrows: true });
    else this.setState({ showArrows: false });
  }

  render() {
    const { showArrows, showLeftArrow, showRightArrow, diff } = this.state;
    return (
      <div className="genre_container">
        <div
          className="edgeStart"
          onMouseEnter={() => {
            let rightMoveSpeed = 2;
            this.scrollRight = setInterval(() => {
              rightMoveSpeed = Math.min(12, rightMoveSpeed + 0.1);
              if (showLeftArrow) this.scrollView.getElement().scrollBy({ left: -rightMoveSpeed });
            }, 10);
          }}
          onMouseLeave={() => {
            clearInterval(this.scrollRight);
          }}
        >
          {showArrows && <FontAwesomeIcon className="icon" icon={faCaretLeft} style={{ fontSize: 24, color: showLeftArrow ? colors.darkGray : colors.gray }} />}
        </div>
        <ScrollContainer
          className="itemWrapper"
          ref={(me) => (this.scrollView = me)}
          onScroll={(e) => {
            if (e === 0) this.setState({ showLeftArrow: false });
            else this.setState({ showLeftArrow: true });

            if (e === diff) this.setState({ showRightArrow: false });
            else this.setState({ showRightArrow: true });
          }}
        >
          <div className="innerWrapper" ref={(me) => (this.scrollWrapper = me)}>
            {this.props.items}
          </div>
        </ScrollContainer>
        <div
          className="edgeEnd"
          onMouseEnter={() => {
            let leftMoveSpeed = 2;
            this.scrollLeft = setInterval(() => {
              leftMoveSpeed = Math.min(12, leftMoveSpeed + 0.1);
              this.scrollView.getElement().scrollBy({ left: leftMoveSpeed });
            }, 10);
          }}
          onMouseLeave={() => {
            clearInterval(this.scrollLeft);
          }}
        >
          {showArrows && <FontAwesomeIcon icon={faCaretRight} style={{ fontSize: 24, color: showRightArrow ? colors.darkGray : colors.gray }} />}
        </div>
      </div>
    );
  }
}
