import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { genreColors } from "../../constants";
import { getBPMInfo } from "../../util";
import { get } from "lodash";
import "./index.scss";

/**
 * @param {array} genres - Array of genres
 * @param {bool} showStats - Display of text % (such as '72%', 26%', ...)
 * @param {object} style - React in-line styling
 * @param {object} simple - Show only the genre color bar (no bpm info)
 */

const GenreColorBar = ({ genres = {}, bpm, simple, limit }) => {
  var keys = Object.keys(genres);
  let sortedGenres = keys.sort((a, b) => genres[b] - genres[a]);
  if (limit) sortedGenres = sortedGenres.slice(0, limit);
  let bpmInfo = getBPMInfo(bpm);

  let totalPercentage = 0;
  sortedGenres.forEach((genre) => (totalPercentage += genres[genre]));

  const calculatePercentage = (percent) => {
    return percent / totalPercentage;
  };

  return (
    <div className="genre-container">
      <div className="genre-color-bar">
        {sortedGenres.map((genre) => (
          <div className={"genre-wrapper"} style={{ width: `${calculatePercentage(genres[genre]) * 100}%` }} key={genre}>
            <div className={"genre-chunk"} style={{ backgroundColor: genreColors[genre] }} />
          </div>
        ))}
      </div>

      {!simple && (
        <div className={"genre-tile-info-wrapper"}>
          <div className={"genre-tile-genre-wrapper"}>
            {sortedGenres.map((genre, i) => (
              <div className={"flexRow"} key={genre}>
                <div className={"genre-color-dot"} style={{ backgroundColor: genreColors[genre] }} />
                <span style={{ position: "relative", bottom: 4, left: 4, fontWeight: i === 0 ? "bold" : "normal", textTransform: "capitalize" }}>{genre}</span>
              </div>
            ))}
          </div>
          <div className={"genre-bpm"}>
            <FontAwesomeIcon icon={get(bpmInfo, "icon", "")} className={"bpm-icon"} />
            <div>{get(bpmInfo, "title", "")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenreColorBar;
