import React from "react";
import { Button } from "antd";
import "./index.scss";

/**
 * @param {string} title - Big text to display
 * @param {string} subtitle - Small text below title
 * @param {object} buttonText -Button text to display
 * @param {function} onButtonPress - Action to take when button is pressed
 */

const PageTitle = ({ title, subtitle, buttonText, onButtonPress, style, rightElement }) => {
  return (
    <div className={"page-title-container"} style={style}>
      <div className={"page-title-left"}>
        <div className={"title-wrapper"}> {title} </div>
        <div className={"subtitle-wrapper"}> {subtitle} </div>
      </div>

      <div className={"page-title-right"}>
        {rightElement}

        {buttonText && (
          <Button type="primary" shape="round" icon="plus" size={"large"} onClick={onButtonPress}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
