import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import configureStore from "./redux-store/storeConfiguration";
import RootContainer from "./containers/rootContainer";
import { loadStripe } from "@stripe/stripe-js";
import * as serviceWorker from "./serviceWorker";
import { STRIPE_KEY } from "./configs";
import "react-slidedown/lib/slidedown.css";
import "./common/styles/styles.scss";

const store = configureStore();
const stripePromise = loadStripe(STRIPE_KEY);

if (document.location.hostname !== "localhost" && document.location.hostname !== "business-dev.ambii.io") console.log = () => {};
render(
  <AppContainer>
    <RootContainer store={store} stripePromise={stripePromise} />
  </AppContainer>,
  document.getElementById("root")
);

serviceWorker.unregister();
