import React from "react";
import { Form, Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FloatLabel from "../floating-label";
import { formErrorMessages } from "../../constants";

const UserProfileForm = ({ data, signup, company, getFieldDecorator, handleChange }) => (
  <Form>
    <FloatLabel required={true} label="First Name" placeholder="First Name" value={data["firstName"]}>
      <FormItem>
        {getFieldDecorator("firstName", {
          initialValue: data["firstName"],
          rules: [
            {
              required: true,
              message: formErrorMessages.required,
            },
            {
              max: 50,
              message: "First name too long",
            },
          ],
        })(<Input max={50} onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    <FloatLabel required={true} label="Last Name" placeholder="Last Name" value={data["lastName"]}>
      <FormItem>
        {getFieldDecorator("lastName", {
          initialValue: data["lastName"],
          rules: [
            {
              required: true,
              message: formErrorMessages.required,
            },
            {
              max: 50,
              message: "Last name too long",
            },
          ],
        })(<Input max={50} onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    {!signup && (
      <FloatLabel required={true} label="Email" placeholder="Email" value={data["email"]}>
        <FormItem>
          {getFieldDecorator("email", {
            initialValue: data["email"],
            rules: [
              {
                required: true,
                message: formErrorMessages.required,
              },
            ],
          })(<Input onChange={handleChange} disabled={true} />)}
        </FormItem>
      </FloatLabel>
    )}

    <FloatLabel required={true} label="Phone Number" placeholder="Phone Number" value={data["phoneNumber"]}>
      <FormItem>
        {getFieldDecorator("phoneNumber", {
          initialValue: data["phoneNumber"],
          rules: [
            {
              required: true,
              message: formErrorMessages.required,
            },
          ],
        })(<Input type="number" onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    {!signup && (
      <FloatLabel label="Company" value={company["name"]}>
        <FormItem>{getFieldDecorator("company", { initialValue: company["name"] })(<Input disabled={true} />)}</FormItem>
      </FloatLabel>
    )}
  </Form>
);

export default Form.create()(UserProfileForm);
