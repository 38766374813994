import React from "react";
import { Redirect } from "react-router-dom";
import Landing from "../containers/landing";
import Stations from "../containers/stations";
import Schedules from "../containers/schedules";
import Announcements from "../containers/announcements";
import Locations from "../containers/locations";
import ChoosePlan from "../containers/choose-plan";

let routes = [
  {
    path: "/",
    exact: true,
    isChild: false,
    private: false,
    component: () => <Redirect to="/stations" />,
  },
  {
    path: "/landing",
    exact: false,
    isChild: false,
    private: false,
    component: () => <Landing />,
  },
  {
    path: "/locations",
    exact: true,
    isChild: true,
    private: true,
    component: () => <Locations />,
  },
  {
    path: "/stations",
    exact: true,
    isChild: true,
    private: false,
    component: () => <Stations />,
  },
  {
    path: "/stations/:stationId",
    exact: true,
    isChild: true,
    private: false,
    component: () => <Stations />,
  },
  {
    path: "/schedules",
    exact: true,
    isChild: true,
    private: true,
    component: () => <Schedules />,
  },
  {
    path: "/announcements",
    exact: true,
    isChild: true,
    private: true,
    component: () => <Announcements />,
  },
  {
    path: "/choose-plan",
    exact: true,
    isChild: true,
    private: true,
    component: () => <ChoosePlan />,
  },
  {
    path: "/mvix",
    exact: true,
    isChild: false,
    private: false,
    component: () => <Redirect to={"/stations"} />,
  },
];

export default routes;
