import React from "react";
import { Button } from "antd";
import { Animated } from "react-animated-css";
import "./index.scss";

const PlanOptionBlock = ({ animate, currentOption, title, description, previousPerks, rowItems = [], price, priceDescription, buttonText, onClick, simple, footnotes = [] }) => {
  return (
    <Animated animateOnMount={animate} className={"choose-plan-block-animated-wrapper no-flick"} animationIn={"fadeIn"} visible={true}>
      <div className={"inner-wrapper"}>
        <div className={"current-option"}> {currentOption} </div>
        <div className={"title"}> {title} </div>
        <div className={"description"}> {description} </div>

        <div className={"row-item-wrapper ".concat(simple ? "simple" : "")}>
          {rowItems.map((item, i) => (
            <div className={"row-item ".concat(previousPerks && i === 0 ? "previous-perks" : "")}>
              <div className={"row-item-icon"}>{item?.icon}</div>
              <div className={"row-item-text"}>{item?.text}</div>
            </div>
          ))}
        </div>

        {!simple && (
          <>
            <div className={"price"}> {price} </div>
            <div className={"price-description"}> {priceDescription} </div>

            {buttonText && (
              <Button type={"primary"} className={"button-style"} loading={false} onClick={onClick}>
                {buttonText}
              </Button>
            )}
          </>
        )}

        {footnotes.length > 0 && (
          <div className={"footnote-wrapper"}>
            {footnotes.map((item) => (
              <div className={"footnote-item"}>{item}</div>
            ))}
          </div>
        )}
      </div>
    </Animated>
  );
};

export default PlanOptionBlock;
