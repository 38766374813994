export const supersetDescriptions = {
  "Popular Today": "The Top Hits of Today",
  "Holiday Music": "Get into the spirit of the season!",
  "Flattening the Curve": "Battling COVID-19, one song at a time.",
  "Upbeat Energy": "Energetic songs to get the mood flowing.",
  "Laid-back, Low-key.": "Songs for relaxation and chill-out times.",
  "Learning to Grow": "A series for those looking to overcome rough times, through different genres of music.",
  "Decades": "Your home for all decades of music.",
  "Ahead of the Curve": "A series for those who are tomorrow's trendsetters.",
  "Liquid Courage": "Beats for those that are bold and those that are beautiful.",
  "Beats and Beers": "Cheer on your favorite team with these country hits!",
  "Taproom Treasures": "Stripped-down music for the sophisticated drinker.",
  "Flirtatious Friday Night": "She's sitting right there.",
  "Settled-Down Speakeasy": "Jazzy tunes for a sparkilng night.",
  "Dancin' Across the World": "Iresistable, international glimmers of firey fun.",
  "Coffee Curiosity": "A perfect morning moodsetter.",
  "Bookstore Barista": "Quiet jazz for newspapers and novelists.",
  "Classical Cappuccino": "Upscale grandiose through caffine and classical.",
  "Countryside Cafe": "For french toast, syrup, and kickin' up those boots in the morning.",
  "Late Night Study ": "Beats for the studious individual ",
  "Glitz At the Ritz": "Wonder and wow through jazzy band ballads.",
  "Loungeside Lovers": "Ambiance for the low-key days and nights.",
  "Hearthside Hip": "R&B treasures for the late-night bonfire vibes",
  "Travelers Rest": "Soft stripped music to lay down to.",
  "Elegant Entertainment": "Classical masterpieces for the sophisticated traveler.",
  "Tropical Getaway": "A cabana soundtrack for pina coladas and the poolside.",
  "Pep Rally Pop": "Let's get this bread.",
  "Study Beats": "Lo-fi music for the late study nights.",
  "Intelligence through Inspiration": "Ambiance for peaceful reflection and quiet times.",
  "Classical Concentration": "Inspiration through piano, strings, and eras of talent",
  "Developing Brains": "For the little ones.",
  "Academy Alternative": "Alternative hits for those looking to get work done.",
  "Boutique Beats": "Continuous flow and feel-good vibes.",
  "Diamonds Are Forever": "Inspirational classical music for the sophisticated shopper.",
  "Retail R&B": "R&B and pop hits for the casual customer.",
  "Food Court Finds": "Grab a bite to eat with this easy-listening soundtrack!",
  "Girls Night Out": "Empowering women through confident, feel-good beats.",
  "Upscaled Grace": "Elegance through classical masterpieces.",
  "Jukebox of the Ages": "Your home for all decades of music.",
  "Wax and Relax": "Set the mood with the perfect ambiiance.",
  "Hairdresser Hop": "R&B and hip hop hits for the trendsetter.",
  "Nails, Hair, Hips, Heels": "Endless feel-good beats.",
  "Pumpin Metal": "Rock and Metal soundtrack for the heavy lifter.",
  "Persistence Pop": "Keep that grind up.",
  "Pre-Game Pump Up": "Get in your head, get in the ring.",
  "Fire Dancing Fiesta": "High-energy music for the dancers...and for that perfect body.",
  "Heavy Lifting Hype": "Get turnt.",
  "Hamburger Hits": "Quick beats for quick bites.",
  "Barbecue Bash": "Perfect for the backyard and the beer.",
  "Asian Eats": "You don't need a plane ticket.",
  "Taco Truck Temposetter": "Are you going to finish all 10 of those tacos?",
  "Boba Beats": "Passionfruit, peach stars, pearls, and perfection.",
  "Royalty Dining": "Elegnat music for roses and candlelit dinners.",
  "Ethereal Elegance": "Transcend within yourself.",
  "Self-Care Classical": "Classical music to rejuvinate the brain.",
  "Asian Getaway": "Sounds through Japaneese cherry blossoms and night-market eats.",
  "Relaxin' R&B": "Good vibes only.",
  "Hot Spring Hits": "Glimmering hits for feel-good moments.",
  "Working From Home": "Chill beats for ultimate focus and productivity.",
  "New Hobby, New Self": "Cooking, dancing, stretching, reading- with this time, anything is possible.",
  "A Time For Reflection": "Rise above and let serenity flow in this time of unrest.",
  "Go Hard or (Stay) Home": "Quarantine ain't stopping these muscle gains.",
  "Treasured Throwbacks": "Remembering the days before this period of time.",
  "Positive Vibes Only (Singing in the Shower)": "Sing your heart out to these songs of joy and jubilation!",
};
