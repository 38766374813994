import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import { Button, Menu, Input } from "antd";
import { getStations, getStationDetails, assignStation } from "../../actions";
import StationSection from "../../components/stationSection";
import StationTile from "../../components/station-tile";
import GenresSection from "../../components/genre-section";
import { Animated } from "react-animated-css";
import { Spin } from "antd";
import { trackScreen, logEvent, trackModal } from "../../configs";
import { get, isEqual } from "lodash";
import { colors, supersets, categories, actions, labels, defaultStationDisplayLimit } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import PageTitle from "../../components/page-title";
import AntSpinner from "../../components/antSpinner";
import MobileAppCommunicator from "../../helpers/MobileAppCommunicator";
import Auth0Helper from "../../helpers/Auth0Helper";
import FloatLabel from "../../components/floating-label";
import StationPreview from "../../modals/station-preview";
import SelectBar from "../../components/select-bar";
import TempoSelection from "../../components/tempo-selection";
import "./index.scss";

let searchTimeout = null;

class Stations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      searchText: "",
      businessType: "other",
      tagSearchText: "",
      browseGenres: {},
      defaultGenres: {}, // Easy reset for browseGenres
      browseTags: {},
      browseTempoRange: { min: 0, max: 999 },
      selectedStationId: null,
      filteredStations: [],
      showAdvancedSearch: false,
      isFiltering: false,
      currentSelectedTempo: null,
      selectedGenres: {},
      selectedTags: {},
      stationPreviewLoading: false,
      stationPreviewVisible: false,
      stationArray: [],
      globalStations: {},
      businessTypeStations: {},
      userTyping: false,
      displayLimit: defaultStationDisplayLimit,
    };
  }

  getBusinessType() {
    let businessType = get(this.props, "company.businessType", "restaurant").toLowerCase();
    if (businessType.toLowerCase() === "other") businessType = "other";
    this.setState({ businessType });
  }

  componentDidUpdate(prevProps, prevState) {
    const { inApp, company, appLocationId, locations } = this.props;
    const { businessType, isLoading, stationArray } = this.state;

    // Once the page is done loading
    if (this.state.isLoading !== prevState.isLoading && !isLoading) {
      let globalStations = {};
      let businessTypeStations = {};

      for (var superset of supersets.global) globalStations[superset] = [];

      let businessTypeSuperset = supersets[businessType.toLowerCase()];

      for (var superset of businessTypeSuperset) businessTypeStations[superset] = [];

      for (var station of stationArray)
        for (var stationSuperset of get(station, "supersets", [])) {
          if (stationSuperset in globalStations) globalStations[stationSuperset].push(station);
          if (stationSuperset in businessTypeStations) businessTypeStations[stationSuperset].push(station);
        }

      this.setState({ globalStations, businessTypeStations });
    }

    if (!isEqual(get(this.props, "locations", null), get(prevProps, "locations", null))) {
      this.getBusinessType();

      // Make mobile pass store code, since we'll have no way of knowing
      if (inApp) {
        let storeCode = this.props?.locations[appLocationId]?.storeCode || "";
        let station = this.props?.locations[appLocationId]?.station?._id || "";

        // If the user just signed up, we'll need to grab the auto-created location
        if (!storeCode) {
          let locationId = Object.keys(locations || {})[0];
          storeCode = locations[locationId]?.storeCode;
          station = locations[locationId]?.station?._id;
        }

        // If we're coming from the mobile intro screen & we're logged in & have a station selected already
        if (localStorage.getItem("mobileIntroLogin") && Auth0Helper.isLoggedIn() && storeCode && station) {
          localStorage.removeItem("mobileIntroLogin");
          MobileAppCommunicator.send("player", "player/" + storeCode);
        }
      }
    }
  }

  async componentDidMount() {
    const {
      params: { stationId },
      url,
    } = this.props.match;

    trackScreen();
    this.getBusinessType();

    if (Object.keys(this.props.stations).length <= 0) await this.props.actions.getStations();

    // Load station if stationId is provided
    if (url !== "/stations" && stationId) this.showStationPreview(stationId);

    // Create array of station keys
    let stationKeys = Object.keys(this.props.stations);

    let stationArray = [];

    // Create stations array
    stationKeys.forEach((key) => {
      stationArray.push(this.props.stations[key]);
    });

    let browseGenres = stationArray.reduce((total, current) => {
      if (current?.genres) Object.keys(current.genres).forEach((genre) => (total[genre] = false));
      return total;
    }, {});

    let browseTags = stationArray.reduce((total, current) => {
      get(current, "tags", []).forEach((tag) => {
        if (tag && !total[tag]) total[tag] = false;
      });
      return total;
    }, {});

    this.setState({ isLoading: false, stationArray, browseGenres, defaultGenres: browseGenres, browseTags }, () => {
      this.calculateBrowse();
    });
  }

  scrollIntoView = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  calculateBrowse = () => {
    const { selectedGenres, selectedTags, currentSelectedTempo, stationArray, browseTempoRange, searchText } = this.state;

    const scoreString = (searchString, targetString) => {
      var score = 0;

      for (var i = 0; i < searchString.length; i++) {
        for (var j = i + 1; j < searchString.length + 1; j++) {
          var ngram = searchString.slice(i, j);
          if (!targetString.includes(ngram)) break;
          score += Math.pow(ngram.length, 1.5) / targetString.length;
        }
      }

      return score * 100;
    };

    let filteredStations = [...stationArray];

    // Filter by Tempo
    if (currentSelectedTempo >= 0) {
      filteredStations = filteredStations.filter((station) => {
        if (browseTempoRange?.min < station?.bpm?.mean && station?.bpm?.mean < browseTempoRange?.max) return true;
        return false;
      });
    }

    // Filter by Genres
    Object.keys(selectedGenres).forEach((genre) => {
      filteredStations = filteredStations.filter((station) => {
        return Boolean(station?.genres[genre]);
      });
    });

    // Filter by Tags
    Object.keys(selectedTags).forEach((tag) => {
      filteredStations = filteredStations.filter((station) => {
        return Boolean(station?.tags.includes(tag));
      });
    });

    // Filter by Search
    filteredStations = filteredStations
      .map((station) => {
        var searchScore = 0;
        searchScore += scoreString(searchText, station.name);
        if (Object.keys(selectedGenres)) Object.keys(selectedGenres).forEach((genre) => (searchScore += get(station, "genres[" + genre + "]", 1) * 0.25));
        return { ...station, searchScore };
      })
      .sort((a, b) => b.searchScore - a.searchScore);

    this.setState({ filteredStations, displayLimit: defaultStationDisplayLimit });
  };

  showStationPreview = async (selectedStationId) => {
    const { stations, match, history } = this.props;

    let stationHasTracks = Boolean(stations[selectedStationId]?.top_tracks);
    // push stationId in routes
    // if (match.url === "/stations") window.history.pushState("", "", "/stations/" + selectedStationId);
    // else window.history.pushState("", "", "/" + selectedStationId);
    window.history.pushState("", "", "/stations/" + selectedStationId);
    trackModal("Station Preview: " + selectedStationId);

    // If our station doesn't have tracks populated, fetch the data
    if (!stationHasTracks)
      this.props.actions.getStationDetails(selectedStationId).then((res) => {
        if (res) this.setState({ stationPreviewLoading: false });
        else this.closeStationPreview();
      });

    this.setState({ selectedStationId, stationPreviewVisible: true, stationPreviewLoading: !stationHasTracks });
  };

  updatePreference(e) {
    this.setState({ businessType: e.key });
    logEvent({
      category: categories.stations,
      action: actions.updatePreference,
      value: e.key,
      label: labels.button,
    });
  }

  _checkIfAllFalse = (arr) => {
    for (const select of arr) if (select) return false;
    return true;
  };

  checkIfFiltering = () => {
    const { searchText, browseGenres, selectedTags, currentSelectedTempo } = this.state;
    const isSearching = searchText.length > 0;
    const isSelectingGenre = !this._checkIfAllFalse(Object.values(browseGenres));
    const isSelectingTag = !this._checkIfAllFalse(Object.values(selectedTags));
    const isSelectingTempo = currentSelectedTempo && currentSelectedTempo >= 0;

    let isFiltering = Boolean(isSearching || isSelectingGenre || isSelectingTag || isSelectingTempo);
    this.setState({ isFiltering, showAdvancedSearch: isFiltering });
  };

  // Selects a genre
  onSelectGenre = (genre) => () => {
    let selectedGenres = { ...this.state.selectedGenres };
    selectedGenres[genre] ? delete selectedGenres[genre] : (selectedGenres[genre] = true);

    this.setState({ selectedGenres, browseGenres: { ...this.state.browseGenres, [genre]: !this.state.browseGenres[genre] } }, () => {
      this.checkIfFiltering();
      this.calculateBrowse();
      this.showClearFilters();
    });
  };

  // Selects a tag
  onSelectTag = (tags) => {
    let selectedTags = {};
    tags.forEach((item) => (selectedTags[item] = true));
    this.setState({ selectedTags }, () => {
      this.checkIfFiltering();
      this.calculateBrowse();
      this.showClearFilters();
    });
  };

  // Selects a tempo
  onSelectTempo = (tempo, index) => {
    this.setState({ browseTempoRange: { min: tempo[0], max: tempo[1] }, currentSelectedTempo: index }, () => {
      this.checkIfFiltering();
      this.calculateBrowse();
      this.showClearFilters();
    });
  };

  // Deselects tempo
  onDeselectTempo = () => {
    this.setState({ browseTempoRange: { min: 30, max: 150 }, currentSelectedTempo: null }, () => {
      this.checkIfFiltering();
      this.calculateBrowse();
      this.showClearFilters();
    });
  };

  // Updates the station search text
  onSetSearchText = (e) => {
    const { userTyping } = this.state;

    if (userTyping) clearInterval(searchTimeout);

    this.setState({ searchText: e.target.value, userTyping: true }, () => {
      searchTimeout = setTimeout(() => {
        this.checkIfFiltering();
        this.setState({ userTyping: false });
        this.calculateBrowse();
        clearInterval(searchTimeout);
      }, 500);
    });
  };

  // Handles tag search text update
  tagSearchTextUpdate = (tagSearchText) => {
    this.setState({ tagSearchText });
  };

  // Clears all selections from advanced filters
  clearAdvancedFilters() {
    const { defaultGenres } = this.state;
    this.setState({ searchText: "", selectedGenres: {}, selectedTags: {}, browseGenres: defaultGenres, currentSelectedTempo: null, tagSearchText: "" }, () => {
      this.checkIfFiltering();
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(this.calculateBrowse, 250);
    });
  }

  // Decides whether to show or hide advanced search menu
  showClearFilters() {
    const { currentSelectedTempo, selectedGenres, selectedTags, searchText } = this.state;
    if (searchText.length > 0 || Object.keys(selectedGenres).length > 0 || Object.keys(selectedTags).length > 0 || currentSelectedTempo >= 0) return true;
    else return false;
  }

  // Closes modal and resets modal states
  closeStationPreview = () => {
    const { history, match, location } = this.props;

    this.setState({ stationPreviewLoading: false, stationPreviewVisible: false });
    // remove stationId from history
    window.history.pushState("", "", "/stations");
  };

  // Returns the businessType menu
  getBusinessTypeMenu() {
    const { businessType } = this.state;
    return (
      <Menu onClick={(e) => this.updatePreference(e)} selectedKeys={[businessType]} className="menu-wrapper">
        <Menu.Item key="bar">Bar</Menu.Item>
        <Menu.Item key="cafe">Cafe</Menu.Item>
        <Menu.Item key="gym">Gym</Menu.Item>
        <Menu.Item key="hotel">Hotel</Menu.Item>
        <Menu.Item key="other">Other</Menu.Item>
        <Menu.Item key="restaurant">Restaurant</Menu.Item>
        <Menu.Item key="salon">Salon</Menu.Item>
        <Menu.Item key="school">School</Menu.Item>
        <Menu.Item key="spa">Spa</Menu.Item>
        <Menu.Item key="shop">Shop</Menu.Item>
      </Menu>
    );
  }

  render() {
    const { isApple, inApp, mvix } = this.props;
    const { displayLimit, globalStations, businessTypeStations, stationPreviewLoading, stationPreviewVisible, isLoading, businessType, selectedStationId, showAdvancedSearch, currentSelectedTempo, isFiltering, browseGenres, searchText, filteredStations, selectedTags, tagSearchText, browseTags } = this.state;

    return (
      <div className={"station-wrapper"}>
        <StationPreview visible={stationPreviewVisible} loading={stationPreviewLoading} selectedStationId={selectedStationId} onOk={this.closeStationPreview} onCancel={this.closeStationPreview} />

        {/* Display page title if we're not viewing from mobile app */}
        {!inApp && (
          <div className="station-head-wrapper">
            <PageTitle
              title={
                <span>
                  Let's Find Your
                  {mvix ? (
                    <span style={{ color: colors.primaryColor }}>&nbsp;Ambience</span>
                  ) : (
                    <span>
                      <span style={{ color: colors.primaryColor }}>&nbsp;Ambii</span>-ence
                    </span>
                  )}
                </span>
              }
              subtitle={"Discover the right music for your business"}
            />
          </div>
        )}

        <Spin indicator={AntSpinner} spinning={isLoading} style={{ backgroundColor: colors.offWhite, maxHeight: "100%" }}>
          <Animated animationIn="fadeIn" isVisible={true}>
            <div className={"station-navbar-wrapper ".concat(showAdvancedSearch && "showAdvancedSearch")}>
              <div className="station-navbar-main">
                <div className="station-navbar-top">
                  <div className="search-input">
                    <FloatLabel label="Search" placeholder="Search for stations by name or genre" value={searchText}>
                      <Input.Search value={searchText} className="searchBar" onChange={this.onSetSearchText} />
                    </FloatLabel>
                  </div>
                  <Button onClick={() => this.setState((prevState) => ({ showAdvancedSearch: !prevState.showAdvancedSearch }))} style={{ alignSelf: "center" }} className={showAdvancedSearch ? "active" : null}>
                    <FontAwesomeIcon icon={faFilter} style={{ paddingRight: "4px" }} />
                    Filters
                  </Button>
                </div>

                <div className="station-navbar-bottom">
                  <GenresSection
                    items={Object.keys(browseGenres).map((genre) => (
                      <span key={genre} className={"genre-item"} onClick={this.onSelectGenre(genre)} style={{ backgroundColor: browseGenres[genre] ? colors.selectedOffWhite : "" }}>
                        {genre}
                      </span>
                    ))}
                  />
                </div>
              </div>

              <SlideDown style={{ width: "100%" }}>
                {showAdvancedSearch && (
                  <div className={"advanced-filter-wrapper"}>
                    <SelectBar className={"select-bar"} tags={Object.keys(browseTags)} performSelect={this.onSelectTag} selectedTags={selectedTags} onSearchChange={this.tagSearchTextUpdate} searchText={tagSearchText} />
                    <TempoSelection currentSelectedTempo={currentSelectedTempo} onSelectTempo={this.onSelectTempo} onDeselectTempo={this.onDeselectTempo} />
                  </div>
                )}
              </SlideDown>
            </div>

            {isFiltering && (
              <div className="advanced-station-wrapper">
                <div className="playlistsContainer">
                  {filteredStations.slice(0, displayLimit).map((station, i) => (
                    <StationTile key={get(station, "_id", i)} showStationPreview={this.showStationPreview} {...station} />
                  ))}
                </div>
                {displayLimit < filteredStations.length && (
                  <Button className={"load-more"} onClick={() => this.setState({ displayLimit: this.state.displayLimit + defaultStationDisplayLimit })}>
                    Load More
                  </Button>
                )}
              </div>
            )}

            {!isFiltering && (
              <div>
                {get(this.props, "user.favorites", []).length > 0 && <StationSection showStationPreview={this.showStationPreview} key={"favorites"} id={"favorites"} title={"Favorites"} type={"favorite"} rowIndex={0} data={get(this.props, "user.favorites", [])} isApple={isApple} showViewAll />}
                {!isApple &&
                  Object.keys(globalStations).map((superset, i) => {
                    if (globalStations[superset].length > 0) return <StationSection showStationPreview={this.showStationPreview} key={superset} id={superset} rowIndex={get(this.props, "user.favorites", []).length > 0 ? null : i} title={superset} data={globalStations[superset]} showViewAll />;
                  })}
                {!isApple &&
                  Object.keys(businessTypeStations).map((superset) => {
                    if (businessTypeStations[superset].length > 0) return <StationSection showStationPreview={this.showStationPreview} key={superset} id={superset} title={superset} data={businessTypeStations[superset]} showViewAll />;
                  })}
              </div>
            )}
          </Animated>
        </Spin>
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getStations,
        getStationDetails,
        assignStation,
      },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    company: state.company,
    locations: state.locations,
    stations: state.stations,
    isApple: state.settings.isApple,
    inApp: state.settings.inApp,
    mvix: state.settings.mvix,
    appLocationId: state.settings.appLocationId,
    user: state.user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stations));
