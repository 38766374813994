import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faWaveform, faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import { Button, Spin, List, Typography, Skeleton } from "antd";
import { getFormattedLocationAddress } from "../../util";
import AntSpinner from "../antSpinner";
import "./index.scss";

const MultiLocationDisplayTile = ({ image, title, subtitle, description, locations, isActive, assignedLocations = [], onClick, onClickAddLocation, type }) => (
  <div className={"multi-location-display-tile-wrapper no-flicker ".concat(!isActive ? "multi-location-inactive" : "")} onClick={onClick}>
    {!title ? (
      <Skeleton active={true} title={false} paragraph={{ rows: 3 }} />
    ) : (
      <>
        {isActive ? (
          <>
            <div className={"display-tile-image-wrapper"}>
              <div className={"display-tile-image"}>{image ? <img src={image} /> : <FontAwesomeIcon icon={type === "schedule" ? faCalendarAlt : faWaveform} className={"waveform-icon"} />}</div>
            </div>
            <div className={"display-tile-content-wrapper"}>
              <div className={"display-tile-title"}>{title}</div>
              <div className={"display-tile-subtitle"}>{subtitle}</div>
              <div className={"display-tile-description"}>{description}</div>
            </div>
            <div className={"display-tile-location-wrapper"}>
              {/* If we have assignedLocations, but locations hasn't loaded yet */}
              {assignedLocations.length > 0 && Object.keys(locations).length === 0 && (
                <div className={"spin-wrapper"}>
                  <Spin indicator={AntSpinner} spinning={true} size={"large"} />
                </div>
              )}

              {/* If we have assignedLocations, and locations have load */}
              {assignedLocations.length > 0 && Object.keys(locations).length > 0 && (
                <List
                  dataSource={assignedLocations}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text strong>{locations[item]?.name}</Typography.Text> - {getFormattedLocationAddress({ addressOne: locations[item]?.addressLineOne, addressTwo: locations[item]?.addressLineTwo })}
                    </List.Item>
                  )}
                />
              )}

              {/* If we don't have assignedLocations */}
              {assignedLocations.length <= 0 && (
                <div className={"no-location-wrapper"}>
                  <div className={"no-location-text"}>No Locations Assigned</div>
                  <div className={"no-location-button-wrapper"}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickAddLocation();
                      }}
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} className={"button-icon"} />
                      Assign Locations
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={"display-tile-content-wrapper"}>
            <div className={"display-tile-title"}>{title}</div>
            <div className={"display-tile-description"}>{description}</div>
          </div>
        )}
      </>
    )}
  </div>
);

export default MultiLocationDisplayTile;
