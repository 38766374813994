import { defaultScheduleBlocks } from "../constants";

let initialState = {
  settings: {
    isApple: false,
    inApp: false, // Are we viewing web app via our mobile app
    mvix: false, // Is the user from MVIX
    appLocationId: null, // LocationID that our mobile app is connected to
    globalModalVisible: false,
    mobileView: false,
    windowWidth: 1024,
    bannerVisible: false, // Is the page banner visible (subscribe banner)
  },
  user: {},
  player: {
    isVisible: false,
    currentTrack: null,
    currentTrackNumber: 0,
    tracks: [],
    isPlaying: false,
    stationDetails: null,
  },
  locations: {},
  company: {},
  stations: {},
  schedules: {},
  billing: {}, // User's billing info
  payment: {}, // User's payment info (non-sensitive info only)
  zones: {}, // Zones belonging to locations
  invoices: [], // User's invoices
  scheduleBlocks: defaultScheduleBlocks,
  announcements: {
    announcements: {}, // Map of all announcements
    announcementHash: "", // The hash of all current announcements
  },
};

export default initialState;
