import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { addSchedule, editSchedule, deleteSchedule, editScheduleBlock, setScheduleBlocks } from "../../actions";
import { Tabs, Form, Button, Input, Modal, notification } from "antd";
import { get, isEqual } from "lodash";
import { responses, devQuickFillScheduleBlocks, defaultScheduleBlocks, scheduleFormFields, productTypes } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlusCircle, faEdit, faMapMarkerAlt, faArrowRight, faArrowLeft, faThumbsUp, faCrown } from "@fortawesome/pro-solid-svg-icons";
import FormItem from "antd/lib/form/FormItem";
import { API_URL } from "../../configs";
import { checkIfValidSchedule, filterObjectMap } from "../../util";
import FloatLabel from "../../components/floating-label";
import LocationList from "../../components/location-list";
import SimpleScheduler from "../../components/simple-scheduler";
import UpgradePreview from "../../components/upgrade-preview";
import "./index.scss";

const { TabPane } = Tabs;
const { confirm } = Modal;

class ScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editExisting: false, // Are we editing an existing item
      isSaving: false, // Are we currently saving an ISM
      tabSection: "edit", // Current tab in-focus
      isDeleting: false, // Is the user deleting a location
      steps: 2, // How many steps for new announcements
      currentStep: 1, // What step is the user on
      scheduleData: {},
      focusedScheduleBlockId: null,
    };
  }

  componentDidMount() {
    this.setScheduleData();
  }

  async componentDidUpdate(prevProps) {
    // What to do if we recieve new schedule data
    if (!isEqual(this.props.data, prevProps.data)) {
      await this.setScheduleData(this.props?.data?._id);
    }

    // If parent sends a default tab to open to
    if (this.props.defaultTab) {
      this.setState({ tabSection: this.props.defaultTab }, () => {
        this.props.setDefaultTab(null); // reset default tab after initial set
      });
    }
  }

  // Sets 'scheduleData' state variable based on incoming 'incomingId'
  async setScheduleData() {
    return new Promise((resolve) => {
      const { data } = this.props;

      const { resetFields } = this.props.form;
      let scheduleData = {};
      let editExisting = false;
      let scheduleId = data?._id;

      // console.log("setScheduleData pre: ", { scheduleId });

      if (scheduleId) {
        // Clears the stored location field values (otherwise may carry over from 'add location' fields)
        resetFields(scheduleFormFields);

        // Set editing to true
        editExisting = true;

        scheduleFormFields.forEach((field) => {
          if (field === "assignedLocations") {
            scheduleData[field] = {};
            data[field].forEach((location) => {
              if (!scheduleData[field][location]) scheduleData[field][location] = true;
            });
          } else scheduleData[field] = data[field];
        });

        // Add some extra data fields for convienence
        scheduleData["_id"] = data["_id"];
      } else {
        // Default Empty Options
        scheduleData["name"] = "";
        scheduleData["description"] = "";
        scheduleData["assignedLocations"] = {};
        scheduleData["scheduleBlocks"] = Object.keys(defaultScheduleBlocks); // Sets our default keys to the default values
      }

      this.setState({ scheduleData, editExisting, tabSection: "edit", currentStep: 1 }, () => {
        // console.log("setScheduleData post: ", this.state.scheduleData);
        resolve(true);
      });
    });
  }

  // Prompts user to delete a schedule
  deleteSchedule = ({ id, data, props, isDeleting, closeModal }) => {
    confirm({
      maskClosable: true,
      title: "Delete Schedule?",
      content: (
        <>
          Are you sure you wish to delete <br />
          <span style={{ fontWeight: "bold" }}>
            {data["name"]} - {data["messasge"] || data["description"]}
          </span>
        </>
      ),
      onOk() {
        isDeleting(true);
        props.actions.deleteSchedule(id).then(() => {
          isDeleting(false);
          closeModal();
        });
      },
      onCancel() {
        isDeleting(false);
      },
    });
  };

  // Returns 'edit' form for announcements
  getEditForm() {
    const { getFieldDecorator } = this.props.form;
    const { scheduleData, focusedScheduleBlockId } = this.state;

    return (
      <div className={"edit-form-wrapper"}>
        <Form>
          <FloatLabel required={true} label="Name" placeholder="Schedule Name" value={scheduleData["name"]}>
            <FormItem>
              {getFieldDecorator("name", {
                initialValue: scheduleData["name"],
                rules: [
                  {
                    required: true,
                    message: "Please input a name",
                  },
                  {
                    min: 1,
                    message: "Name too short",
                  },
                  {
                    max: 50,
                    message: "Name too long",
                  },
                ],
              })(<Input max={50} onChange={this.handleChange} />)}
            </FormItem>
          </FloatLabel>

          <FloatLabel label="Description" placeholder="Schedule Description" value={scheduleData["description"]}>
            <FormItem>
              {getFieldDecorator("description", {
                initialValue: scheduleData["description"],
              })(<Input max={50} onChange={this.handleChange} />)}
            </FormItem>
          </FloatLabel>
        </Form>

        <SimpleScheduler blockIds={scheduleData?.scheduleBlocks} scheduleBlocks={get(this.props, "scheduleBlocks", {})} onSave={(e) => this.scheduleBlockEdited(e)} focusedScheduleBlockId={focusedScheduleBlockId} setFocusedScheduleBlockId={(focusedScheduleBlockId) => this.setState({ focusedScheduleBlockId })} />
      </div>
    );
  }

  // Updates the modified scheduleBlock in redux
  scheduleBlockEdited(e) {
    this.props.actions.editScheduleBlock({ ...e });
    this.setState({ focusedScheduleBlockId: null });
  }

  // Location Assignment Section
  getAssignForm() {
    const { locations } = this.props;
    const { scheduleData } = this.state;

    return (
      <div className={"assign-form-wrapper"}>
        <div className={"modal-subtitle"}>Assign Locations to play this schedule at</div>
        <LocationList locations={filterObjectMap("isActive", locations)} selected={scheduleData["assignedLocations"]} onClick={(e) => this.assignLocationToggle(e)} showDefault={true} history={this.props.history} />
      </div>
    );
  }

  // Handles checkbox result for location list
  assignLocationToggle(e) {
    const { scheduleData } = this.state;
    let key = e?.currentTarget?.id;
    let assignedLocations = { ...scheduleData["assignedLocations"] };
    if (assignedLocations[key]) delete assignedLocations[key];
    else assignedLocations[key] = true;

    this.setState({ scheduleData: { ...scheduleData, ["assignedLocations"]: assignedLocations } });
  }

  // When user swaps tab menus
  async newTabSelection(tabSection) {
    this.setState({ tabSection });
  }

  /**
   * Handles Input Changes
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleChange = (e) => {
    const { setFieldsValue } = this.props.form;
    const key = e.target.id;
    const value = e.target.value;
    setFieldsValue({ [key]: value });
    this.setState({ scheduleData: { ...this.state.scheduleData, [key]: value } });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    this.setState({ fieldData: { ...this.state.fieldData, ...map } });
  };

  // Attempts to create / edit schedule
  handleSubmit = async (e) => {
    const { schedules, scheduleBlocks } = this.props;
    const { scheduleData, editExisting } = this.state;

    e.preventDefault();

    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        this.setState({ tabSection: "edit" });
        notification.warn({ message: responses.status.warn, description: responses.description.requiredDataMissing, placement: "bottomLeft" });
        return;
      }
      if (!checkIfValidSchedule({ scheduleData, scheduleBlocks })) {
        this.setState({ tabSection: "edit" });
        return;
      }

      for (const blockId of get(scheduleData, "scheduleBlocks", [])) {
        if (Object.keys(get(scheduleBlocks[blockId], "genres", {})).length <= 0) {
          this.setState({ tabSection: "edit" });
          notification.warn({ message: responses.action.requiredDataMissing, description: "Please select a genre for " + get(scheduleBlocks[blockId], "name", {}), placement: "bottomLeft" });
          return;
        }

        if (Object.keys(get(scheduleBlocks[blockId], "tempo", {})).length <= 0) {
          this.setState({ tabSection: "edit" });
          notification.warn({ message: responses.action.requiredDataMissing, description: "Please select a tempo for " + get(scheduleBlocks[blockId], "name", {}), placement: "bottomLeft" });
          return;
        }
      }

      this.setState({ isSaving: true }, () => {
        let scheduleBlockArray = [];

        // Only push this schedule's block elements that have been modified
        get(scheduleData, "scheduleBlocks", []).forEach((blockId) => {
          if (scheduleBlocks[blockId] && scheduleBlocks[blockId]?.newHash && scheduleBlocks[blockId]?.hash !== scheduleBlocks[blockId]?.newHash) {
            let blockData = { ...scheduleBlocks[blockId] };
            blockData["hash"] = blockData["newHash"];
            delete blockData["newHash"];
            if (get(blockData, "_id", "").includes("default")) delete blockData["_id"];
            scheduleBlockArray.push({ ...blockData });
          }
        });

        // Modify final data
        let dataToSend = {}; // Final data
        let tempData = { ...scheduleData, scheduleBlocks: scheduleBlockArray, assignedLocations: Object.keys(scheduleData["assignedLocations"]) }; // Create a copy of our state / form data

        if (!tempData["description"]) tempData["description"] = responses.description.defaultScheduleDescription;

        if (editExisting) {
          // Use 'scheduleFormFields' as the only fields to send to the server, as we occasionally mix in some other support fields that the server will reject
          scheduleFormFields.forEach((field) => {
            if (tempData[field] && !isEqual(tempData[field], schedules[scheduleData["_id"]][field])) dataToSend[field] = tempData[field];
          });

          if (Object.keys(dataToSend).length > 0) this.props.actions.editSchedule({ dataToSend, scheduleId: tempData["_id"] }).then(() => this.onSuccessfulUpdate());
          else this.setState({ isSaving: false }, () => notification.warn({ message: responses.status.warn, description: responses.description.noChangesToSave, placement: "bottomLeft" }));
        } else {
          this.props.actions.addSchedule(tempData).then(async (res) => {
            this.onSuccessfulUpdate();
            this.props.setFocusedId(get(res, "_id", "")); // Swap parent's 'focusedId' to the new schedule id
          });
        }
      });
    });
  };

  // Reusable function for successful schedule edit
  onSuccessfulUpdate() {
    this.setState({ isSaving: false }, () => {
      this.props.actions.setScheduleBlocks(defaultScheduleBlocks);
      this.props.onOk();
      this.setScheduleData();
    });
  }

  // Quick-fill data for dev purposes
  async devFill() {
    await this.props.actions.setScheduleBlocks(devQuickFillScheduleBlocks);
    this.setState({ scheduleData: { name: "Test Schedule", description: "", assignedLocations: {}, scheduleBlocks: Object.keys(devQuickFillScheduleBlocks) } });
  }

  render() {
    const { mvix, visible, onOk, onCancel, subscribed, hasSchedule } = this.props;
    const { focusedScheduleBlockId, isDeleting, scheduleData, tabSection, editExisting, isSaving, steps, currentStep } = this.state;

    let disableSave = false;
    let nextStepAvailable = currentStep < steps;

    let deleteScheduleConfig = {
      id: scheduleData["_id"],
      data: scheduleData,
      props: this.props,
      isDeleting: (isDeleting) => this.setState({ isDeleting }),
      closeModal: onCancel,
    };

    return (
      <Modal
        visible={visible}
        title={`${editExisting ? "Edit" : "Add"} Schedule`}
        onOk={onOk}
        onCancel={onCancel}
        destroyOnClose={true}
        forceRender={true}
        className={"modal-wrapper"}
        footer={[
          <div className={"modal-footer-main-button-group-left"}>
            {editExisting && (
              <Button key="delete" onClick={() => this.deleteSchedule(deleteScheduleConfig)} loading={isDeleting}>
                <FontAwesomeIcon icon={faTrash} className={"button-icon"} />
                Delete
              </Button>
            )}
          </div>,

          <div className={"modal-footer-main-button-group"}>
            {(API_URL.includes("localhost") || API_URL.includes("-dev")) && (
              <Button key="dev" onClick={() => this.devFill()} disabled={disableSave}>
                <FontAwesomeIcon icon={faThumbsUp} className={"button-icon"} />
                Autofill
              </Button>
            )}

            {editExisting && tabSection !== "upgrade" ? (
              <Button key="save" type={"primary"} onClick={this.handleSubmit} loading={isSaving} disabled={focusedScheduleBlockId}>
                <FontAwesomeIcon icon={editExisting ? faEdit : faPlusCircle} className={"button-icon"} />
                Save Changes
              </Button>
            ) : tabSection === "upgrade" ? (
              <Button key="upgrade" type={"primary"} onClick={() => this.props.history.push("/choose-plan")} disabled={subscribed}>
                <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                Upgrade Now
              </Button>
            ) : !nextStepAvailable || tabSection === "assign" ? (
              <>
                <Button key="back" onClick={() => this.setState({ tabSection: "edit" })}>
                  <FontAwesomeIcon icon={faArrowLeft} className={"button-icon"} />
                  Back
                </Button>
                <Button key="save" type={"primary"} loading={isSaving} onClick={this.handleSubmit}>
                  <FontAwesomeIcon icon={faPlusCircle} className={"button-icon"} />
                  Add Schedule
                </Button>
              </>
            ) : (
              <Button key="next" type={"primary"} onClick={() => this.setState({ tabSection: "assign" })} loading={isSaving} disabled={focusedScheduleBlockId}>
                <FontAwesomeIcon icon={faArrowRight} className={"button-icon"} />
                Next
              </Button>
            )}
          </div>,
        ]}
      >
        <div className="create-schedule-container">
          <Tabs type="card" defaultActiveKey={tabSection} activeKey={tabSection} onTabClick={(e) => this.newTabSelection(e)}>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faEdit} className={"button-icon"} />
                  Edit
                </span>
              }
              key="edit"
            >
              {this.getEditForm()}
            </TabPane>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className={"button-icon"} />
                  Assign
                </span>
              }
              key="assign"
            >
              {this.getAssignForm()}
            </TabPane>
            {!subscribed && (
              <TabPane
                tab={
                  <span className={"noselect"}>
                    <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                    Upgrade
                  </span>
                }
                key="upgrade"
              >
                <UpgradePreview type={productTypes.schedule} mvix={mvix} />
              </TabPane>
            )}
          </Tabs>
        </div>
      </Modal>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ addSchedule, editSchedule, deleteSchedule, editScheduleBlock, setScheduleBlocks }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    company: state.company,
    schedules: state.schedules,
    scheduleBlocks: state.scheduleBlocks,
    mvix: state.settings.mvix,
  };
}

const WrappedLocation = Form.create()(ScheduleModal);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedLocation));
