import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Form, Steps, notification, Input, Divider, Spin } from "antd";
import { completeSignUp } from "../../actions";
import GeoSearchForm from "../../components/company-geo-suggest";
import { API_URL, trackScreen, logEvent } from "../../configs";
import { get, isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckCircle, faEdit, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { categories, actions, labels, responses, states, formErrorMessages, locationFormFields, userFormFields, colors } from "../../constants";
import LocationForm from "../../components/location-form";
import FloatLabel from "../../components/floating-label";
import FormItem from "antd/lib/form/FormItem";
import AntSpinner from "../../components/antSpinner";
import "./index.scss";

const { Step } = Steps;
let allSteps = [{ key: "Location" }, { key: "Contact Info" }];

class SignUpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchLoading: false,
      currentStep: 0,
      locationSearch: true,
      geoSuggestSearchValue: "",
      userData: {},
      locationData: {},
      stepOneDisabled: true,
      stepTwoDisabled: true,
      stateRes: [], // Filtered States to show
    };
  }

  componentDidMount() {
    trackScreen();

    let stateRes = [];
    let stateKeys = Object.keys(states);
    stateKeys.forEach((state) => stateRes.push(state));
    this.setState({ stateRes });
  }

  componentDidUpdate(prevProps, prevState) {
    // If user updates LocationData, check if we have all valid fields
    if (!isEqual(this.state.locationData, prevState.locationData)) {
      this.setState({ stepOneDisabled: !this.checkForAllLocationFields() });
    }

    // If user updates userData, check if we have all valid fields
    if (!isEqual(this.state.userData, prevState.userData)) {
      this.setState({ stepTwoDisabled: !this.checkForAllUserFields() });
    }
  }

  // Checks if each required location field has a value
  checkForAllLocationFields() {
    const { locationData } = this.state;
    if (get(locationData, "name", false) && get(locationData, "addressLineOne", false) && get(locationData, "city", false) && get(locationData, "state", false) && get(locationData, "postalCode", false) && get(locationData, "phoneNumber", false)) return true;
    return false;
  }

  // Checks if each required user field has a value
  checkForAllUserFields() {
    const { userData } = this.state;
    if (get(userData, "companyName", false) && get(userData, "firstName", false) && get(userData, "lastName", false) && get(userData, "phoneNumber", false)) return true;
    return false;
  }

  handleSubmit = async () => {
    const { locationData, userData } = this.state;

    this.setState({ isLoading: true }, async () => {
      let dataToSend = {};

      // Location
      dataToSend["locationName"] = locationData["name"];
      dataToSend["addressLineOne"] = locationData["addressLineOne"];
      dataToSend["addressLineTwo"] = locationData["addressLineTwo"];
      dataToSend["city"] = locationData["city"];
      dataToSend["state"] = locationData["state"];
      dataToSend["postalCode"] = locationData["postalCode"];
      dataToSend["locationPhoneNumber"] = locationData["phoneNumber"];
      dataToSend["cords"] = locationData["cords"];

      // Company
      dataToSend["companyName"] = userData["companyName"];

      // User
      dataToSend["firstName"] = userData["firstName"];
      dataToSend["lastName"] = userData["lastName"];
      dataToSend["userPhoneNumber"] = userData["phoneNumber"];

      let signupRes = await this.props.actions.completeSignUp(dataToSend);
      if (signupRes) this.props.onOk();
      this.setState({ isLoading: false });
    });
  };

  // Goes to the next step
  next() {
    const { currentStep, locationData, userData, locationSearch } = this.state;

    // If we're on the first step (location data)
    if (currentStep === 0) {
      // Validate the form
      this.props.form.validateFields([locationFormFields], async (err) => {
        // Set 'locationSearch' to false, so any errors are taken to the 'manual entry' page
        this.setState({ locationSearch: false }, () => {
          if (err) return; // If general form errors, return

          // If required fields missing, return
          if (!this.checkForAllLocationFields()) {
            notification.error({ message: responses.action.requiredDataMissing, description: responses.description.requiredDataMissing, placement: "bottomLeft" });
            return;
          }

          // If state was invalid, return
          if (!states[locationData?.state]) {
            notification.error({ message: "Invalid State", description: "Please select valid state from the dropdown menu", placement: "bottomLeft" });
            return;
          }

          Object.keys(this.props.form.getFieldsValue()).forEach((field) => this.props.form.setFieldsValue({ [field]: userData[field] }));

          this.setState({ currentStep: 1 });
        });
      });
    }
  }

  // Goes to the previous step
  prev() {
    const { locationData } = this.state;
    Object.keys(this.props.form.getFieldsValue()).forEach((field) => this.props.form.setFieldsValue({ [field]: locationData[field] }));
    this.setState({ currentStep: this.state.currentStep - 1, geoSuggestSearchValue: "" });
  }

  // Quick way for devs to signup w/o needing to fill PaymentForm
  quickDevSignup = () => {
    let locationData = { name: "Some Test Location", addressLineOne: "Santa Clara County", city: "Mountain View", state: "California", postalCode: "94043", phoneNumber: "123456789" };
    let userData = { companyName: "asdf", firstName: "Test", lastName: "User", phoneNumber: "1234567890" };
    this.setState({ locationData, userData }, () => {
      this.handleSubmit();
    });
  };

  /**
   * Handles Input Changes
   * Future Reference: We're using both setState & setFieldsValue because antd will compain not to use setState while also using fieldDecorator, so we're using setFieldsValue as a remedy so we can make use of fieldDecorator
   *  */
  handleChange = (e, type) => {
    const key = e.target.id;
    const value = e.target.value;
    this.props.form.setFieldsValue({ [key]: value });
    this.setState({ [type]: { ...this.state[type], [key]: value } });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map, type) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    this.setState({ [type]: { ...this.state[type], ...map } });
  };

  // On Autocomplete serach (filters data list)
  onAutoCompleteSearch = (type, searchText) => {
    let initValue = type === "state" ? states : {};
    let results = type === "state" ? "stateRes" : "";
    let keys = Object.keys(initValue);
    let filteredData = keys.filter((s) => s.toLowerCase().includes(searchText.toLowerCase()));
    this.setState({ filteredData: searchText.length <= 0 ? keys : filteredData, [results]: filteredData, locationData: { ...this.state.locationData, [type]: searchText } });
  };

  onSuggestSelect = async (suggest) => {
    if (suggest) {
      // This function is triggered when user removes search value, so the 'if' stops the loader
      this.setState({ searchLoading: true }, async () => {
        let addressLineOne, addressLineTwo, city, state, postalCode, country;
        if (Object.keys(get(suggest, "gmaps", {})).length > 1) {
          let address = suggest?.gmaps?.address_components;
          address.forEach((component) => {
            let types = component.types;
            if (types.indexOf("locality") > -1) city = component.long_name;
            if (types.indexOf("sublocality_level_1") > -1 || types.indexOf("route") > -1 || types.indexOf("administrative_area_level_2") > -1) addressLineOne = component.long_name;
            if (types.indexOf("sublocality_level_2") > -1) addressLineTwo = component.long_name;
            if (types.indexOf("administrative_area_level_1") > -1) state = component.long_name;
            if (types.indexOf("postal_code") > -1) postalCode = component.long_name;
            if (types.indexOf("country") > -1 || types.indexOf("political") > -1) country = component.long_name;
          });

          let lng = get(suggest, "location.lng", "");
          let lat = get(suggest, "location.lat", "");

          let locationData = {
            name: get(suggest, "gmaps.name", suggest.label),
            addressLineOne,
            addressLineTwo,
            city,
            state,
            postalCode,
            country,
            phoneNumber: get(suggest, "gmaps.formatted_phone_number", ""),
            cords: [lng, lat],
          };
          this.setState({ locationData, searchLoading: false }, () => {
            this.next();
          });
        }
      });
    }
  };

  getContactForm() {
    const { getFieldDecorator } = this.props.form;
    const { userData } = this.state;

    return (
      <Form>
        <div className={"form-section"}>
          <div className={"section-title"}>Company Info</div>
          <FloatLabel required={true} label="Company Name" placeholder="Company Name" value={userData["companyName"]}>
            <FormItem>
              {getFieldDecorator("companyName", {
                initialValue: userData["companyName"],
                rules: [
                  {
                    required: true,
                    message: formErrorMessages.required,
                  },
                  {
                    max: 50,
                    message: "Company name too long",
                  },
                ],
              })(<Input max={50} onChange={(e) => this.handleChange(e, "userData")} />)}
            </FormItem>
          </FloatLabel>
        </div>

        <Divider />

        <div className={"form-section"}>
          <div className={"section-title"}>User Info</div>
          <FloatLabel required={true} label="First Name" placeholder="First Name" value={userData["firstName"]}>
            <FormItem>
              {getFieldDecorator("firstName", {
                initialValue: userData["firstName"],
                rules: [
                  {
                    required: true,
                    message: formErrorMessages.required,
                  },
                  {
                    max: 50,
                    message: "First name too long",
                  },
                ],
              })(<Input max={50} onChange={(e) => this.handleChange(e, "userData")} />)}
            </FormItem>
          </FloatLabel>

          <FloatLabel required={true} label="Last Name" placeholder="Last Name" value={userData["lastName"]}>
            <FormItem>
              {getFieldDecorator("lastName", {
                initialValue: userData["lastName"],
                rules: [
                  {
                    required: true,
                    message: formErrorMessages.required,
                  },
                  {
                    max: 50,
                    message: "Last name too long",
                  },
                ],
              })(<Input max={50} onChange={(e) => this.handleChange(e, "userData")} />)}
            </FormItem>
          </FloatLabel>

          <FloatLabel required={true} label="Phone Number" placeholder="Phone Number" value={userData["phoneNumber"]}>
            <FormItem>
              {getFieldDecorator("phoneNumber", {
                initialValue: userData["phoneNumber"],
                rules: [
                  {
                    required: true,
                    message: formErrorMessages.required,
                  },
                ],
              })(<Input onChange={(e) => this.handleChange(e, "userData")} />)}
            </FormItem>
          </FloatLabel>
        </div>
      </Form>
    );
  }

  render() {
    const {
      visible,
      onOk,
      mvix,
      form: { getFieldDecorator },
    } = this.props;
    const { isLoading, searchLoading, stateRes, stepOneDisabled, stepTwoDisabled, geoSuggestSearchValue, currentStep, locationSearch, userData, locationData } = this.state;
    let devMode = API_URL.includes("localhost") || API_URL.includes("-dev");

    return (
      <Modal
        visible={visible}
        onOk={onOk}
        destroyOnClose={true}
        forceRender={true}
        header={false}
        closable={false}
        className={"modal-wrapper"}
        wrapClassName={!visible ? "" : "modal-override-wrapper"}
        footer={[
          <div className={"modal-footer-main-button-group"}>
            {currentStep === 0 && locationSearch ? (
              <>
                {devMode ? <Button onClick={() => this.quickDevSignup()}>Dev Signup</Button> : null}
                <Button onClick={() => this.setState({ locationSearch: false })}>
                  <FontAwesomeIcon icon={faEdit} className={"button-icon"} />
                  Manually Enter Location
                </Button>
              </>
            ) : currentStep === 0 && !locationSearch ? (
              <>
                <Button onClick={() => this.setState({ locationSearch: true })}>
                  <FontAwesomeIcon icon={faSearch} className={"button-icon"} />
                  Search Location
                </Button>
                <Button type={"primary"} onClick={() => this.next()} disabled={stepOneDisabled}>
                  <FontAwesomeIcon icon={faArrowRight} className={"button-icon"} />
                  Next
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => this.prev()}>
                  <FontAwesomeIcon icon={faArrowLeft} className={"button-icon"} />
                  Back
                </Button>
                <Button type={"primary"} onClick={() => this.handleSubmit()} loading={isLoading} disabled={stepTwoDisabled}>
                  <FontAwesomeIcon icon={faCheckCircle} className={"button-icon"} />
                  Submit
                </Button>
              </>
            )}
          </div>,
        ]}
      >
        <div className="signup-modal-wrapper">
          <div className={"signup-head-wrapper"}>
            <div className={"signup-title"}>
              {"Welcome to "}
              <span style={{ color: colors.primaryColor, marginLeft: "10px" }}>{mvix ? "MVIX Radio" : "Ambii"}</span>
            </div>
            <div className={"signup-subtitle"}>{"Let's set up your first location!"}</div>
          </div>

          <div className="content-wrapper">
            <div className="step-wrapper">
              {currentStep >= 0 && (
                <Steps className="steps" progressDot current={currentStep} style={{ textAlign: "justify" }}>
                  {allSteps.map((item) => (
                    <Step key={item.key} title={item.key} />
                  ))}
                </Steps>
              )}
              {<div className={"description"}>{currentStep === 0 && locationSearch ? "Enter location name or address" : currentStep === 0 && !locationSearch ? "Enter company details" : "Enter your contact information"}</div>}
            </div>

            <div className="form-wrapper">
              {
                {
                  0: locationSearch ? (
                    <Spin indicator={AntSpinner} spinning={searchLoading}>
                      <GeoSearchForm searchValue={geoSuggestSearchValue} getFieldDecorator={getFieldDecorator} geoSuggestSearchChange={(e) => this.setState({ geoSuggestSearchValue: e })} handleChange={(e) => this.handleChange(e, "locationData")} onSuggestSelect={(e) => this.onSuggestSelect(e)} placeholder={"123 Main St, San Jose CA"} errorMessage={"Please enter location name or address"} label={"Location Name or Address"} />
                    </Spin>
                  ) : (
                    <LocationForm data={locationData} getFieldDecorator={getFieldDecorator} handleChange={(e) => this.handleChange(e, "locationData")} handleSelect={(e) => this.handleSelect(e, "locationData")} onSearchChange={this.onAutoCompleteSearch} stateRes={stateRes} />
                  ),
                  1: <div>{this.getContactForm()}</div>,
                }[currentStep]
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ completeSignUp }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    company: state.company,
    locations: state.locations,
    mvix: state.settings.mvix,
  };
}

const WrappedLocation = Form.create()(SignUpModal);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedLocation);
