import { HTTP } from "../configs";
import { notification } from "antd";
import { setIsApple, setUser, setLocations, setCompany } from "./dispatch";
import { get } from "lodash";
import { getLocations } from "./locations";
import { getZones } from "./zones";
import { responses } from "../constants";
import { objArrayToMap } from "../util";

export function auth0setLogin() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", "/v3/auth/jwt")
        .then(async ({ success, data }) => {
          await Promise.all([dispatch(getLocations()), dispatch(getZones())]);

          // The fact this line (and any subsequent code) exists is reltih
          if (get(data, "email", "").toLowerCase() === "apple@ambii.io") dispatch(setIsApple(true));

          if (success) {
            dispatch(setUser(data)); // Set user data
            dispatch(setCompany(data?.company)); // Set company data
            notification.success({ message: data?.firstName ? responses.action.signIn : responses.action.signUp, description: data?.firstName ? responses.description.welcomeBack.concat(`, ${data?.firstName}`) : responses.description.welcome, placement: "bottomLeft" });
          } else notification.error({ message: responses.action.signIn, description: responses.description.loginFail, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.action.signIn, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

export function completeSignUp(data) {
  return function (dispatch) {
    return new Promise(function (resolve) {
      HTTP("post", "/v3/auth/signup", data)
        .then(({ success, message, data }) => {
          if (success) {
            let locationMap = objArrayToMap(data?.locations, "_id");
            dispatch(setUser(get(data, "user", {})));
            dispatch(setCompany(get(data, "user.company", {})));
            dispatch(setLocations(locationMap));
            notification.success({ message: responses.status.success, description: responses.description.signUpSuccess, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.success, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
