import { HTTP } from "../configs";
import { setAnnouncements, setAnnouncementHash, _deleteAnnouncement } from "./dispatch";
import { get } from "lodash";
import { notification } from "antd";
import { responses } from "../constants";

// Gets a location's announcements
export const getAnnouncements = () => (dispatch, getState) => {
  return new Promise(async (resolve) => {
    let storedAnnouncementHash = get(getState(), "announcements.announcementHash", "");

    HTTP("get", `/v3/api/announcement/?hash=${storedAnnouncementHash}`)
      .then(({ success, message, data }) => {
        if (success) {
          let announcements = get(data, "announcements", null);
          let announcementHash = get(data, "announcementHash", []);
          let announcementMap = {};

          // Create Announcement Map
          for (const announcement of announcements) announcementMap[announcement?._id] = announcement;

          dispatch(setAnnouncements(announcementMap));
          dispatch(setAnnouncementHash(announcementHash));
        }
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

// API request for creating an announcement preview
export function createPreviewAnnouncement({ voice, language, message, speed, hash, announcementId, backgroundMusic }) {
  return function (dispatch) {
    return new Promise((resolve) => {
      // Return if required data missing
      if (!hash) return resolve(false);

      HTTP("post", "/v3/auth/announcement/preview", { voice, language, message, speed, hash, announcementId, backgroundMusic })
        .then(({ success, message, data }) => {
          if (success) resolve(data);
          else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// API request for creating an announcement
export function createAnnouncement({ assignedLocations, title, voice, language, message, speed, frequency, savedPreviews, hash, backgroundMusic, specifiedTimes }) {
  return function (dispatch) {
    return new Promise((resolve) => {
      // Return if required data missing
      if (!title || !message) {
        notification.error({ message: responses.status.error, description: responses.description.requiredDataNotFound, placement: "bottomLeft" });
        return resolve(false);
      }

      HTTP("post", "/v3/auth/announcement", { assignedLocations, title, voice, language, message, speed, frequency, savedPreviews, hash, backgroundMusic, specifiedTimes })
        .then(({ success, message, type }) => {
          if (success) notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          else {
            if (type === "PRODUCT_LIMIT_REACHED_ANNOUNCEMENT") notification.warn({ message: responses.status.warn, description: message, placement: "bottomLeft" });
            else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          }
          resolve(true);
        })
        .catch((error) => {
          console.log("error", error);
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// API request for editing an existing announcement
export function editAnnouncement({ assignedLocations, announcementId, title, voice, language, message, speed, frequency, savedPreviews, hash, backgroundMusic, specifiedTimes }) {
  return function (dispatch) {
    return new Promise((resolve) => {
      // Return if required data missing
      if (!title || !message) return resolve(false);

      HTTP("patch", `/v3/auth/announcement/${announcementId}`, { assignedLocations, announcementId, title, voice, language, message, speed, frequency, savedPreviews, hash, backgroundMusic, specifiedTimes })
        .then(({ success, message, data }) => {
          if (success) {
            dispatch(getAnnouncements()).then(() => {
              notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
              resolve(data);
            });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// API request for deleting an announcement
export function deleteAnnouncement(id) {
  console.log(id);
  return function (dispatch, getState) {
    return new Promise((resolve) => {
      // Return if required data missing
      if (!id) return resolve(false);

      HTTP("delete", `/v3/auth/announcement/${id}`)
        .then(({ success, message, data }) => {
          if (success) {
            dispatch(_deleteAnnouncement(id));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// API request for retrieving Google text-to-speech voice options
export function getVoiceOptions() {
  return function (dispatch) {
    return new Promise((resolve) => {
      // Return if required data missing
      HTTP("get", "/v3/api/voices")
        .then(({ success, message, data }) => {
          if (success) resolve(data || []);
          else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

export function updateUploadedAnnouncement(incomingAnnouncement) {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/announcement/upload/${incomingAnnouncement?.announcementId}`, incomingAnnouncement)
        .then(({ message, success, data }) => {
          if (success) {
            dispatch(getAnnouncements()).then(() => {
              notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
              resolve(data);
            });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
        })
        .catch((err) => {
          console.log(err);
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
