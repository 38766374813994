import Auth0Lock from "auth0-lock";
import { AUTH0_KEY } from "../configs";
import { colors } from "../constants";

let urlBeforeLogin = localStorage.getItem("urlBeforeLogin");
let mvix = localStorage.getItem("mvix");

export default class Auth0Helper {
  static initialize(isMvix) {
    if (!mvix) mvix = isMvix; // Checking for mvix

    window.lock = new Auth0Lock(AUTH0_KEY, "auth.ambii.io", {
      configurationBaseUrl: "https://ambii.us.auth0.com",
      autoclose: true,
      autofocus: true,
      closable: true,
      allowShowPassword: true,
      auth: {
        redirectUrl: window.location.origin,
        responseType: "id_token",
        sso: true,
      },
      theme: {
        logo: mvix ? "https://public-ambii-images.s3.us-west-2.amazonaws.com/mvix-logo.png" : `${window.location.origin}/icon.png`,
        // logo: `${window.location.origin}/icon.png`,
        primaryColor: colors.primaryColor,
      },
      languageDictionary: {
        title: "",
        signUpTitle: "",
        forgotPasswordTitle: "",
      },
      additionalSignUpFields: [
        // Save this in case we need it later.
        //
        // {
        //   name: "given_name",
        //   icon: iconToDataURI(faIdBadge),
        //   placeholder: "First Name",
        //   storage: "root",
        //   validator: function (txt) {
        //     return {
        //       valid: txt.length > 0 && txt.length < 100,
        //       hint: "The first name field is required", // optional
        //     };
        //   },
        // },
      ],
    });
  }

  static getLock() {
    return window.lock;
  }

  static isLoggedIn() {
    return localStorage.getItem("ambii_auth_jwt");
  }

  static login() {
    if (!urlBeforeLogin) localStorage.setItem("urlBeforeLogin", window.location.href);
    window.lock.show();
  }

  static signUp() {
    if (!urlBeforeLogin) localStorage.setItem("urlBeforeLogin", window.location.href);
    window.lock.show({ initialScreen: "signUp" });
  }

  static logout() {
    localStorage.removeItem("ambii_auth_jwt");
    window.lock.logout();
  }
}
