/*eslint no-case-declarations: "off"*/

import { SET_COMPANY, ADD_SUBSCRIPTION, CANCEL_SUBSCRIPTION } from "../constants";
import initialState from "../redux-store/initialState";

const company_reducer = (state = initialState.company, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return Object.assign({}, state, action.data);
    case ADD_SUBSCRIPTION:
      return Object.assign({}, state, { ...state, subscribed: true });
    case CANCEL_SUBSCRIPTION:
      return Object.assign({}, state, { ...state, subscribed: false });
    default:
      return state;
  }
};

export default company_reducer;
