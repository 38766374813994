import React from "react";
import { Select } from "antd";
import FloatLabel from "../floating-label";
import "./index.scss";

const { Option } = Select;

const SelectBar = ({ tags, performSelect, selectedTags, onSearchChange, searchText, label, placeholder, className }) => {
  const handleChange = (value) => {
    performSelect(value);
  };

  return (
    <div className={"mood-container ".concat(className)}>
      <FloatLabel className={"flex"} label={label || "Search for Tags"} value={(Object.keys(selectedTags).length > 0 ? true : false) || searchText} placeholder={placeholder || "Search for stations by name or genre"} placeholderStyle={{ top: "29px" }}>
        <Select mode="multiple" style={{ width: "100%" }} onChange={handleChange} onSearch={onSearchChange} maxTagCount={3} value={Object.keys(selectedTags)} onBlur={() => onSearchChange("")}>
          {tags.map((mood) => {
            return (
              <Option key={mood} style={{ textTransform: "capitalize" }}>
                {mood}
              </Option>
            );
          })}
        </Select>
      </FloatLabel>
    </div>
  );
};

export default SelectBar;
