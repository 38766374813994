import { HTTP } from "../configs";
import { setLocations, setSchedules, setStation, setStations, _editLocation, _editSchedule } from "./dispatch";
import { get } from "lodash";
import { notification } from "antd";
import { responses } from "../constants";
import { objArrayToMap } from "../util";

export function getStations() {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("get", "/v3/api/station")
        .then(({ success, message, data }) => {
          if (success) {
            let stationMap = {};
            data.forEach((station) => (stationMap[station?._id] = station));
            dispatch(setStations(stationMap));
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

export function getStationDetails(stationId) {
  return function (dispatch, getState) {
    return new Promise((resolve) => {
      if (!stationId) return resolve(false); // Return if required data missing
      HTTP("get", "/v3/api/station/" + stationId)
        .then(({ success, message, data }) => {
          if (success) {
            let stations = get(getState(), "stations", {});
            dispatch(setStations({ ...stations, [stationId]: data })); // Update redux w/ new station data. Lets us remake call w/o needing to make the call again
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(data);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

export function assignStation({ locations, stationId }) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      // Return if required data missing
      if (!stationId || !locations || (locations && locations.length <= 0)) return resolve(false);

      HTTP("post", `/v3/api/station/${stationId}`, { locations })
        .then(async ({ success, message, data }) => {
          if (success) {
            let locationMap = objArrayToMap(data?.locations, "_id");
            let scheduleMap = objArrayToMap(data?.schedules, "_id");

            if (Object.keys(locationMap).length > 0) dispatch(setLocations(locationMap));
            if (Object.keys(scheduleMap).length > 0) dispatch(setSchedules(scheduleMap));

            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(data);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
