import { notification } from "antd";
import { HTTP } from "../configs";
import { setCompany } from "./dispatch";
import { responses } from "../constants";

// Edits a company
export function editCompany(dataToSend) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      HTTP("patch", "/v3/auth/company", dataToSend)
        .then(({ success, message, data }) => {
          if (success) {
            dispatch(setCompany(dataToSend));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
