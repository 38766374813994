import { HTTP } from "../configs";
import { _addSubscription, _cancelSubscription, setInvoices } from "./dispatch";
import { notification } from "antd";
import { responses } from "../constants";

// Adds subscription to company
export function addSubscription(incomingData) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("post", "/v3/auth/subscription", incomingData)
        .then(({ success, message, data }) => {
          if (success) {
            dispatch(_addSubscription());
            notification.success({ message: responses.status.success, description: responses.description.addSubscriptionSuccess, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: responses.description.addSubscriptionFailed, placement: "bottomLeft" });
          resolve(success);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Removes subscription from company
export function cancelSubscription(incomingData) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("patch", "/v3/auth/subscription", incomingData)
        .then(({ success, data, message }) => {
          console.log("cancelSubscription res: ", { success, data, message });
          if (success) notification.success({ message: responses.status.success, description: responses.description.cancelSubscriptionSuccess, placement: "bottomLeft" });
          else notification.error({ message: responses.status.error, description: responses.description.cancelSubscriptionFailed, placement: "bottomLeft" });
          resolve(success);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Gets a user's invoices
export function getInvoices() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", "/v3/auth/invoice")
        .then(({ success, message, data }) => {
          if (success) dispatch(setInvoices(data));
          else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(data || []);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
