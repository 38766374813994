import { HTTP } from "../configs";
import { setZones, _editZone, _deleteZone } from "./dispatch";
import { notification } from "antd";
import { objArrayToMap } from "../util";
import { responses } from "../constants";

// Ges all of a user's zones
export function getZones() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", `/v3/auth/zone`)
        .then(({ success, data }) => {
          if (success) {
            let zoneMap = objArrayToMap(data, "_id");
            dispatch(setZones(zoneMap));
          } else notification.error({ message: responses.status.error, description: responses.description.failedToRetrieveData, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
}

// Edits a zone's data
export function editZone({ dataToSend, zoneId }) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/zone/${zoneId}`, dataToSend)
        .then(({ success, message }) => {
          if (success) {
            dispatch(_editZone({ ...dataToSend, _id: zoneId }));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Deletes a zone
export function deleteZone(zoneId) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("delete", `/v3/auth/zone/${zoneId}`)
        .then(({ success, message }) => {
          if (success) {
            dispatch(_deleteZone(zoneId));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
