import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCrown, faExchangeAlt, faMusic, faPlayCircle, faSignalStream } from "@fortawesome/pro-solid-svg-icons";
import { faRecordVinyl } from "@fortawesome/pro-duotone-svg-icons";
import { get } from "lodash";
import { Button, notification } from "antd";
import AnimatedIcon from "../animated-icon";
import { responses, images, colors } from "../../constants";
import { getFormattedLocationAddress } from "../../util";
import "./index.scss";

const LocationBlock = ({ simple, data, onEdit, onUpgrade, onConnect, mvix }) => {
  // Copies store code text
  const copyStoreCodeText = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(storeCode); // Copy the text inside the text field
    notification.success({ message: responses.action.copy, description: responses.description.copiedStoreCode, placement: "bottomLeft" });
  };

  // Listener for when track images fail to load
  const [imageError, setImageError] = useState(false);

  let { _id, isActive, subscription, station, schedule, currentTrack, pairedDevice, storeCode } = data || {};
  simple = isActive ? simple : true;
  let isPlaying = get(data, "pairedDevice.isPlaying", false);
  let trackName = get(currentTrack, "title", "");
  let trackArtist = get(currentTrack, "artist.name", "");

  console.log("location: ", { data, _id, isActive, subscription, station, schedule, currentTrack, pairedDevice, storeCode });
  // Truncate name if too long
  if (trackName.length > 50) trackName = trackName.substring(0, 50) + "...";
  if (trackArtist.length > 50) trackArtist = trackArtist.substring(0, 50) + "...";

  return (
    <div id={simple ? "" : _id} className={"location-block-wrapper no-flicker ".concat(simple ? "simple-wrapper" : "")} onClick={onEdit}>
      <div className={!isActive ? "inactive" : ""} />
      <div className={"location-block-head-wrapper ".concat(simple ? "simple-header " : "").concat(isActive ? "active" : "inactive")}>
        <div className={"location-block-head-content-wrapper"}>
          <div className={"location-name"}>
            {subscription && <AnimatedIcon icon={faCrown} animationIn={"tada"} style={{ color: colors.primaryColor, fontSize: "22px", marginRight: "10px" }} onClick={onUpgrade} durationIn={750} />}
            {get(data, "name", "NA")}
          </div>
          <div className={"location-address"}>{getFormattedLocationAddress({ addressOne: data?.addressLineOne, addressTwo: data?.addressLineTwo })}</div>
        </div>

        {/* {simple ? (
            <div className={"status-wrapper"}>
              <div className={"status-dot small-dot"} style={{ backgroundColor: isPlaying ? colors.online : colors.offline }} />
            </div>
          ) : (
            <div className={"store-code-wrapper"} onClick={(e) => copyStoreCodeText(e)}>
              <div className={"store-code-title"}>Store Code:</div>
              <div className={"store-code"}>{storeCode}</div>
            </div>
          )} */}

        {simple && isActive && (
          <div className={"status-wrapper"}>
            <div className={"status-dot small-dot"} style={{ backgroundColor: isPlaying ? colors.online : colors.offline }} />
          </div>
        )}
      </div>

      {!simple && (
        <>
          <div className={"location-navbar-wrapper"}>
            <div className={"location-navbar-item zone-name-wrapper"}>Name</div>
            <div className={"location-navbar-item"} style={{ flex: 2 }}>
              <FontAwesomeIcon icon={faMusic} className={"navbar-icon"} />
              Now Playing
            </div>
            <div className={"location-navbar-item"}>
              <FontAwesomeIcon icon={faPlayCircle} className={"navbar-icon"} />
              Player Info
            </div>
          </div>
          <div className={"status-navbar"} style={{ backgroundColor: isPlaying ? colors.online : colors.offline }} />

          <div className={"zone-wrapper"}>
            <div className={"zone-name-wrapper"}>
              <div className={"status-wrapper"}>
                <div className={"status-dot large-dot"} style={{ backgroundColor: isPlaying ? colors.online : colors.offline }} />
                <div className={"status-text"}>{isPlaying ? "Online" : "Offline"}</div>
              </div>
            </div>
            <div className={"zone-now-playing-wrapper"}>
              <div className={"album-art"} onClick={isPlaying ? null : (e) => onConnect(e, storeCode)}>
                {isPlaying && currentTrack?.album?.cover_med && !imageError ? <img src={currentTrack?.album?.cover_med} className={"artwork"} onError={() => setImageError(true)} /> : mvix ? <FontAwesomeIcon icon={faRecordVinyl} className="default-icon" /> : <img src={images.appLogo} className={"artwork"} />}
              </div>
              <div className={"info-wrapper"}>
                {isPlaying && (
                  <>
                    <div className={"track-artist"}>{trackArtist}</div>
                    <div className={"track-name"}>{trackName}</div>
                  </>
                )}

                <div className={"station-name"} style={{ marginTop: isPlaying ? "10px" : "0px" }}>
                  <FontAwesomeIcon icon={faSignalStream} className={"button-icon"} />
                  {get(station, "name", "NA")}
                </div>
                {schedule && (
                  <div className={"schedule-name"}>
                    <FontAwesomeIcon icon={faClock} className={"button-icon"} />
                    {get(schedule, "name", "NA")}
                  </div>
                )}
              </div>
            </div>

            <div className={"zone-player-info-wrapper"}>
              <div className={"store-code-wrapper"} onClick={(e) => copyStoreCodeText(e)}>
                <div className={"store-code-title"}>Store Code:</div>
                <div className={"store-code"}>{storeCode}</div>
              </div>

              <Button type={"primary"} size={"large"} className={"connect-button"} onClick={(e) => onConnect(e, storeCode)}>
                <FontAwesomeIcon icon={isPlaying ? faExchangeAlt : faPlayCircle} className={"button-icon"} />
                <div className={"button-text-wrapper"}>
                  <div className={"title"}> {isPlaying ? "Change " : "Connect "} Player</div>
                  <div className={"description"}>{isPlaying && pairedDevice?.deviceName}</div>
                </div>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LocationBlock;
