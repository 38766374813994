import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { Button, Checkbox } from "antd";
import { getFormattedLocationAddress } from "../../util";
import { get } from "lodash";
import "./index.scss";

const LocationList = ({ locations = {}, showIconForScheduledLocations, selected, onClick, showSubmit, onSubmitClick, submitButtonDisabled, showDefault, history }) => {
  let locationKeys = Object.keys(locations) || {};
  let hasSelection = Object.keys(selected)?.length > 0;
  let makePlural = Object.keys(selected)?.length === 0 || Object.keys(selected)?.length > 1 ? "s" : "";

  return (
    <div className={"location-list-wrapper"}>
      {locationKeys.length > 0 ? (
        <>
          <div className={"location-list-scroll-wrapper"}>
            {locationKeys.map((key) => (
              <div key={key} id={key} className={"location-list-item-wrapper noselect"} onClick={onClick}>
                <div className={"location-list-item-checkbox"}>
                  <Checkbox checked={selected[key]} />
                </div>
                <div className={"location-list-item-address"}>
                  <div className={"location-list-item-name"}>{get(locations[key], "name", "NA")}</div>
                  <div className={"location-list-item-address"}>{getFormattedLocationAddress({ addressOne: locations[key].addressLineOne, addressTwo: locations[key].addressLineTwo })}</div>
                </div>
                {showIconForScheduledLocations && get(locations[key], "schedule", false) && (
                  <div className={"location-list-item-icon"}>
                    <FontAwesomeIcon icon={faCalendarAlt} className={"button-icon"} />
                  </div>
                )}
              </div>
            ))}
          </div>
          {showSubmit && (
            <Button type={"primary"} onClick={onSubmitClick} className={"location-list-submit"} disabled={submitButtonDisabled || Object.keys(selected) <= 0} loading={submitButtonDisabled}>
              {!submitButtonDisabled && <FontAwesomeIcon icon={faCheckCircle} className={"button-icon"} />}

              {hasSelection && submitButtonDisabled ? "Assigning station" : hasSelection && !submitButtonDisabled ? `Confirm ${Object.keys(selected).length} Location`.concat(makePlural) : "No Locations Selected"}
            </Button>
          )}
        </>
      ) : (
        <div className={"no-locations"}>
          <span>No locations found for this account!</span>
          <span>
            <a onClick={() => history.push("/locations")}>Click Here</a> to create a location.
          </span>
        </div>
      )}
    </div>
  );
};

export default LocationList;
