import { HTTP } from "../configs";
import { notification } from "antd";
import { editScheduleBlock, setScheduleBlocks, setLocations, setSchedules, _addSchedule, _deleteSchedule, _editLocation, _editSchedule } from "./dispatch";
import { responses } from "../constants";
import { objArrayToMap } from "../util";

// Creates a schedule
export function addSchedule(dataToSend) {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("post", "/v3/auth/schedule", dataToSend)
        .then(({ success, message, data, type }) => {
          if (success) {
            let locationMap = objArrayToMap(data?.locations, "_id");
            let scheduleMap = objArrayToMap(data?.schedules, "_id");
            let scheduleBlockMap = objArrayToMap(data?.scheduleBlocks, "_id");

            if (Object.keys(locationMap).length > 0) dispatch(setLocations(locationMap));
            if (Object.keys(scheduleMap).length > 0) dispatch(setSchedules(scheduleMap));
            if (Object.keys(scheduleBlockMap).length > 0) dispatch(setScheduleBlocks(scheduleBlockMap));

            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else {
            if (type === "PRODUCT_LIMIT_REACHED_SCHEDULE") notification.warn({ message: responses.status.warn, description: message, placement: "bottomLeft" });
            else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          }
          resolve(data?.schedules[0] || null);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Gets user's schedules
export function getSchedules() {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("get", "/v3/auth/schedule")
        .then(({ success, message, data }) => {
          if (success) {
            let scheduleMap = objArrayToMap(data?.schedules, "_id");
            let scheduleBlockMap = objArrayToMap(data?.scheduleBlocks, "_id");

            dispatch(setSchedules(scheduleMap));
            dispatch(setScheduleBlocks(scheduleBlockMap));
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
}

// Save's a user's schedule
export function editSchedule({ dataToSend, scheduleId }) {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/schedule/${scheduleId}`, dataToSend)
        .then(({ success, message, data }) => {
          if (success) {
            let locationMap = objArrayToMap(data?.locations, "_id");
            let scheduleMap = objArrayToMap(data?.schedules, "_id");
            let scheduleBlockMap = objArrayToMap(data?.scheduleBlocks, "_id");

            if (Object.keys(locationMap).length > 0) dispatch(setLocations(locationMap));
            if (Object.keys(scheduleMap).length > 0) dispatch(setSchedules(scheduleMap));
            Object.keys(scheduleBlockMap).forEach((block) => dispatch(editScheduleBlock(scheduleBlockMap[block])));

            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(data?.schedules[0] || null);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Deletes a schedule
export function deleteSchedule(scheduleId) {
  return function (dispatch) {
    return new Promise((resolve) => {
      HTTP("delete", `/v3/auth/schedule/${scheduleId}`)
        .then(({ success, message, data }) => {
          if (success) {
            // Modify locations
            let locationMap = objArrayToMap(data?.locations, "_id");
            if (Object.keys(locationMap).length > 0) dispatch(setLocations(locationMap));

            // Delete schedule from redux
            dispatch(_deleteSchedule(scheduleId));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
