/*eslint no-case-declarations: "off"*/

import { SET_APP_LOCATION_ID, SET_INAPP, SET_MVIX, SET_GLOBAL_MODAL_VISIBLE, SET_IS_APPLE, SET_WINDOW_WIDTH, SET_BANNER_VISIBLE } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const settings_reducer = (state = initialState.settings, action) => {
  switch (action.type) {
    case SET_IS_APPLE: // This is an action for APPLE review
      return Object.assign({}, state, { isApple: action.data });
    case SET_INAPP: // Is the user viewing the webapp via our mobile app
      if (get(action, "data", false)) {
        localStorage.setItem("inApp", true); // Store in local storage. Needed because when app user does login/out, we refresh the page, which removes an inApp reference
        window.inApp = true;
      }
      return Object.assign({}, state, { mvix: action.data });
    case SET_MVIX: // Is the user viewing the webapp via our mobile app
      if (get(action, "data", false)) localStorage.setItem("mvix", true); // Store in local storage. Needed because when app user does login/out, we refresh the page, which removes an inApp reference
      return Object.assign({}, state, { mvix: action.data });
    case SET_APP_LOCATION_ID:
      return Object.assign({}, state, { appLocationId: action.data });
    case SET_GLOBAL_MODAL_VISIBLE:
      return Object.assign({}, state, { globalModalVisible: action.data });
    case SET_WINDOW_WIDTH:
      let mobileView = action.data <= 650 ? true : false;
      return Object.assign({}, state, { windowWidth: action.data, mobileView });
    case SET_BANNER_VISIBLE:
      return Object.assign({}, state, { bannerVisible: action.data });
    default:
      return state;
  }
};

export default settings_reducer;
