import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Button } from "antd";
import StationTile from "../station-tile";
import { assignStation } from "../../actions";
import { logEvent } from "../../configs";
import { get, isEqual } from "lodash";
import { supersetDescriptions, categories, actions, labels } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

class StationSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArrows: false,
      showLeftArrow: false,
      showRightArrow: true,
      diff: 0,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Re-render if something in the row changes
    if (!isEqual(get(this.props, "data", []), get(nextProps, "data", []))) return true;

    // Re-render if window size changes
    if (!isEqual(get(this.props, "windowWidth", []), get(nextProps, "windowWidth", []))) return true;

    // Re-render if we get new elements
    if (!isEqual(get(this.state, "rowElem", null), get(nextState, "rowElem", []))) return true;
    return false;
  }

  render() {
    let { id, title, type, showViewAll, rowIndex, isApple } = this.props;
    let data = get(this.props, "data", []);

    return (
      <section className="feature-section" id={id} style={!showViewAll ? { padding: "25px 0px" } : {}}>
        <div className="feature-section-head-wrapper">
          <div className="feature-section-title-wrapper">
            <div className="heading heading-large"> {title} </div>
            {!showViewAll && (
              <Button type="primary" onClick={() => buttonPress(0, type, this.props.history)}>
                <FontAwesomeIcon icon={faChevronLeft} />
                Go back
              </Button>
            )}
          </div>
        </div>
        <div className="description">{supersetDescriptions[title]}</div>
        <ScrollContainer className="stationPreviewWrapper" hideScrollbars={false}>
          {data.map((item) => (
            <StationTile key={item} showStationPreview={this.props.showStationPreview} rowIndex={rowIndex} isApple={isApple} {...item} />
          ))}
        </ScrollContainer>
      </section>
    );
  }
}

// Button Press actions
const buttonPress = (clicked, type, history) => {
  if (clicked === 0) history.push("/stations");
  if (clicked === 1) {
    logEvent({ category: categories.stations, action: actions.button, value: "View More", label: labels.button });
    history.push(`/stations/${type}`);
  }
};

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ assignStation }, dispatch) };
}

function mapStateToProps(state) {
  return {
    activeStationPreview: state.activeStationPreview,
    windowWidth: state.settings.windowWidth,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StationSection));
