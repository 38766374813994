import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getSchedules } from "../../actions";
import { trackScreen } from "../../configs";
import { get } from "lodash";
import PageTitle from "../../components/page-title";
import { Spin } from "antd";
import AntSpinner from "../../components/antSpinner";
import ScheduleModal from "../../modals/schedule-modal";
import MultiLocationDisplayTile from "../../components/multi-location-display-tile";
import NoPageData from "../../components/no-page-data";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { Animated } from "react-animated-css";
import "./index.scss";

class Schedules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      modalLoading: false,
      modalVisible: false,
      focusedId: null,
      defaultTab: null, // Default tab we want to open a schedule to
    };
  }

  componentDidMount() {
    trackScreen();

    // If we have no schedules, fetch schedules
    if (Object.keys(this.props.schedules).length <= 0) {
      this.props.actions.getSchedules().then(() => {
        this.setState({ pageLoading: false });
      });
    } else this.setState({ pageLoading: false });
  }

  // Runs when user presses 'Add Location' button
  async clickedAddSchedule(productLimitReached) {
    this.showModal({ key: productLimitReached ? "upgrade" : "edit" });
  }

  // Opens modal
  showModal = async ({ key, id }) => {
    this.setState({ modalVisible: true, modalLoading: false, defaultTab: key || "edit", focusedId: id || null });
  };

  closeModalAndClearData = () => {
    this.setState({ modalVisible: false, defaultTab: null, setFocusedId: null });
  };

  render() {
    const { schedules, locations, company } = this.props;
    const { pageLoading, modalLoading, modalVisible, defaultTab, focusedId, bannerVisible, showPlayer, mobileView } = this.state;

    let subscribed = get(company, "subscribed", false);
    let hasSchedule = Object.keys(schedules).length > 0;
    let productLimitReached = hasSchedule && !subscribed;
    let bannerHeight = bannerVisible ? "40px" : "0px";
    let maxColumnHeight = `calc(100vh - ${bannerHeight} - ${showPlayer ? "85px" : "0px"} - ${mobileView ? "292px" : "247px"})`;
    let activeSchedules = [];
    let inactiveSchedules = [];

    Object.keys(schedules).map((id) => (schedules[id]?.isActive ? activeSchedules.push(id) : inactiveSchedules.push(id)));

    return (
      <div className="scheduleWrapper">
        <PageTitle title={"Schedules"} subtitle={"Schedule out your stations for the whole week"} buttonText={"Add Schedule"} onButtonPress={() => this.clickedAddSchedule(productLimitReached)} />
        <ScheduleModal visible={modalVisible} loading={modalLoading} onOk={this.closeModalAndClearData} onCancel={this.closeModalAndClearData} data={focusedId ? schedules[focusedId] : null} setFocusedId={(focusedId) => this.setState({ focusedId })} defaultTab={defaultTab} setDefaultTab={(defaultTab) => this.setState({ defaultTab })} subscribed={subscribed} hasSchedule={hasSchedule} />
        <Spin indicator={AntSpinner} spinning={pageLoading}>
          <Animated animationIn="fadeIn" isVisible={true}>
            <div className="schedule-list-wrapper" style={{ height: maxColumnHeight }}>
              {activeSchedules.length > 0 && (
                <>
                  <div className={"sticky-section-header"}>Active Schedules</div>
                  <div className={"multi-location-tile-section-wrapper"}>
                    {activeSchedules.map((id) => (
                      <MultiLocationDisplayTile key={id} elemId={id} type={"schedule"} isActive={schedules[id]?.isActive} onClick={() => this.showModal({ key: "edit", id })} onClickAddLocation={() => this.showModal({ key: "assign", id })} image={schedules[id]?.image} title={schedules[id]?.name} description={schedules[id]?.description} locations={locations} assignedLocations={schedules[id]?.assignedLocations} />
                    ))}
                  </div>
                </>
              )}

              {inactiveSchedules.length > 0 && (
                <>
                  <div className={"sticky-section-header"}>Inactive Schedules</div>
                  <div className={"multi-location-tile-section-wrapper"}>
                    {inactiveSchedules.map((id) => (
                      <MultiLocationDisplayTile key={id} elemId={id} type={"schedule"} isActive={schedules[id]?.isActive} onClick={() => this.showModal({ key: "edit", id })} onClickAddLocation={() => this.showModal({ key: "assign", id })} image={schedules[id]?.image} title={schedules[id]?.name} description={schedules[id]?.description} locations={locations} assignedLocations={schedules[id]?.assignedLocations} />
                    ))}
                  </div>
                </>
              )}
              {!pageLoading && Object.keys(schedules).length <= 0 && <NoPageData type={"Schedule"} icon={faClock} onClick={() => this.clickedAddSchedule(productLimitReached)} />}
            </div>
          </Animated>
        </Spin>
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getSchedules }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    company: state.company,
    schedules: state.schedules,
    scheduleBlocks: state.scheduleBlocks,
    mobileView: state.settings.mobileView,
    bannerVisible: state.settings.bannerVisible,
    showPlayer: state.player.isVisible,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Schedules));
