export default class MobileAppCommunicator {
  static inApp() {
    return window.inApp;
  }

  static send(endpoint, data) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          data: data,
          endpoint: endpoint,
        })
      );
    }
  }
}
