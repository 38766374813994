import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editUser, editCompany, addSubscription, getInvoices } from "../../actions";
import { Spin, Tabs, Form, Button, Modal, Input, notification, List, Divider, Pagination } from "antd";
import { get, isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBuilding, faFileInvoice } from "@fortawesome/pro-solid-svg-icons";
import { responses, companyFormFields, userFormFields, colors } from "../../constants";
import CompanyForm from "../../components/company-form";
import UserProfileForm from "../../components/user-profile-form";
import { AMBII_WEB_VERSION } from "../../configs";
import AntSpinner from "../../components/antSpinner";
import { Animated } from "react-animated-css";
import { getFormattedPrice } from "../../util";
import moment from "moment";
import "./index.scss";

const { TabPane } = Tabs;

const pageSize = 4;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSection: "company", // Current tab in-focus
      fieldData: {}, // data
      current: 1,
      totalPage: 0,
      minIndex: 0,
      maxIndex: pageSize,
      isSaving: false, // Are we saving changes
      sectionLoading: false, // Are we loading a section
    };
  }

  // When user swaps tab menus
  async newTabSelection(tabSection) {
    const { pageSize } = this.state;

    this.setState({ tabSection, sectionLoading: true }, async () => {
      await this.setData(tabSection);

      if (tabSection === "invoice") {
        if (Object.keys(this.props.invoices).length <= 0) await this.props.actions.getInvoices().then((res) => this.setState({ totalPage: res.length / pageSize }));
        else this.props.actions.getInvoices().then((res) => this.setState({ totalPage: res.length / pageSize }));
      }
      this.setState({ sectionLoading: false });
    });
  }

  async componentDidUpdate(prevProps) {
    // Sets 'tabSection' to whatever the user opens the menu to
    if (this.props.defaultTab !== prevProps.defaultTab) {
      await this.setData(this.props.defaultTab);
      if (this.props.defaultTab) this.setState({ tabSection: this.props.defaultTab });
    }
  }

  // Sets 'fieldData' state variable
  async setData(type) {
    return new Promise((resolve) => {
      const { user, company } = this.props;
      const { resetFields } = this.props.form;
      let fieldData = {};
      let allFields = type === "profile" ? userFormFields : companyFormFields;

      // Clears the stored user field values (otherwise may carry over from 'add user' fields)
      resetFields(allFields);

      allFields.forEach((field) => {
        fieldData[field] = type === "profile" ? user[field] : company[field];
      });

      this.setState({ fieldData }, () => {
        resolve(true);
      });
    });
  }

  handleSubmit = async () => {
    const { editUser, editCompany } = this.props.actions;
    const { user, company } = this.props;
    const { tabSection, fieldData } = this.state;

    this.setState({ isSaving: true }, () => {
      let dataToSend = {}; // Final data
      let finalFields = tabSection === "profile" ? userFormFields : companyFormFields;

      // Use 'locationFields' as the only fields to send to the server, as we occasionally mix in some other support fields that the server will reject
      finalFields.forEach((field) => {
        if (fieldData[field] && !isEqual(fieldData[field], tabSection === "profile" ? user[field] : company[field])) dataToSend[field] = fieldData[field];
      });

      if (Object.keys(dataToSend).length > 0) {
        if (tabSection === "profile") editUser({ dataToSend, userId: get(user, "_id", "") }).then(() => this.setState({ isSaving: false }));
        if (tabSection === "company") editCompany(dataToSend).then(() => this.setState({ isSaving: false }));
      } else this.setState({ isSaving: false }, () => notification.warn({ message: responses.status.warn, description: responses.description.noChangesToSave, placement: "bottomLeft" }));
    });
  };

  // Handles pagination page change
  handlePagniationChange = (page) => {
    this.setState({ current: page, minIndex: (page - 1) * pageSize, maxIndex: page * pageSize });
  };

  /**
   * Handles Input Changes
   * Future Reference: We're using both setState & setFieldsValue because antd will compain not to use setState while also using fieldDecorator, so we're using setFieldsValue as a remedy so we can make use of fieldDecorator
   *  */
  handleChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    this.props.form.setFieldsValue({ [key]: value });
    this.setState({ fieldData: { ...this.state.fieldData, [key]: value } });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    this.setState({ fieldData: { ...this.state.fieldData, ...map } });
  };

  renderInvoiceSection() {
    const { invoices } = this.props;
    const { current, minIndex, maxIndex, sectionLoading } = this.state;

    return (
      <div className={"invoice-section-wrapper"}>
        <Spin indicator={AntSpinner} spinning={sectionLoading}>
          <Animated animationIn="fadeIn" isVisible={true}>
            <div className={"invoice-wrapper"}>
              <List
                itemLayout="horizontal"
                dataSource={invoices}
                renderItem={(item, i) => {
                  let paidInvoice = item?.status === "paid";
                  return (
                    <>
                      {i >= minIndex && i < maxIndex ? (
                        <List.Item>
                          <div className={"invoice-item-wrapper"} onClick={() => window.open(item?.hosted_invoice_url || item?.invoice_pdf || null)}>
                            <div className={"invoice-item-icon-wrapper"}>
                              <div className={"invoice-item-icon"} style={{ backgroundColor: paidInvoice ? colors.online : colors.offline }} />
                            </div>
                            <div className={"invoice-item-content"}>{moment(item.createdAt).format("LL")}</div>
                            <div className={"invoice-item-price"}>{getFormattedPrice(item?.total)}</div>
                          </div>
                        </List.Item>
                      ) : null}
                    </>
                  );
                }}
              />
            </div>

            <div className={"pagination-wrapper"}>
              <Pagination defaultCurrent={1} current={current} total={invoices.length} onChange={this.handlePagniationChange} pageSize={pageSize} />
            </div>
          </Animated>
        </Spin>
      </div>
    );
  }

  render() {
    const {
      company,
      visible,
      onOk,
      onCancel,
      form: { getFieldDecorator },
    } = this.props;
    const { tabSection, isSaving, fieldData } = this.state;

    let subscribed = get(company, "subscribed", false);

    return (
      <Modal
        visible={visible}
        title={"Settings"}
        onOk={onOk}
        onCancel={onCancel}
        forceRender={true}
        className={"modal-wrapper"}
        footer={[
          <div className={"modal-footer-main-button-group-left"} style={{ alignItems: "center", opacity: ".5" }}>
            v{AMBII_WEB_VERSION}
          </div>,
          <div className={"modal-footer-main-button-group"}>
            <Button key="back" onClick={onCancel}>
              Cancel
            </Button>

            <Button key="save" type={"primary"} onClick={this.handleSubmit} loading={isSaving}>
              {/* <FontAwesomeIcon icon={faVolume} className={"button-icon"} /> */}
              Save
            </Button>
          </div>,
        ]}
      >
        <div className="settings-wrapper">
          <Tabs type="card" defaultActiveKey={tabSection} activeKey={tabSection} onTabClick={(e) => this.newTabSelection(e)}>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faUser} className={"button-icon"} />
                  Profile
                </span>
              }
              key="profile"
            >
              <UserProfileForm data={fieldData} company={company} getFieldDecorator={getFieldDecorator} handleChange={this.handleChange} />
            </TabPane>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faBuilding} className={"button-icon"} />
                  Company
                </span>
              }
              key="company"
            >
              <CompanyForm data={fieldData} getFieldDecorator={getFieldDecorator} handleChange={this.handleChange} handleSelect={this.handleSelect} />
            </TabPane>
            {subscribed && (
              <TabPane
                tab={
                  <span className={"noselect"}>
                    <FontAwesomeIcon icon={faFileInvoice} className={"button-icon"} />
                    Invoices
                  </span>
                }
                key="invoice"
              >
                {this.renderInvoiceSection()}
              </TabPane>
            )}
          </Tabs>
        </div>
      </Modal>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ editUser, editCompany, addSubscription, getInvoices }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    company: state.company,
    locations: state.locations,
    invoices: state.invoices,
  };
}

const WrappedLocation = Form.create()(Settings);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedLocation);
