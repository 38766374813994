/* eslint-disable no-undef */
import React from "react";
import phoneImage from "../../common/assets/images/ios.png";
import ambiiLogo from "../../common/assets/images/playstore.png";
import { ReactComponent as GooglePlay } from "../../images/google-play-store.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import { Modal, Button } from "antd";
import { AMBII_PLAYER_URL } from "../../configs";
import { colors } from "../../constants";
import "./index.scss";

const InstructionModal = ({ visible, onOk, onCancel, storeCode, isApple, mvix }) => {
  const arrayOfCode = storeCode?.split("");
  const playerUrl = (mvix ? process.env.REACT_APP_MVIX_PLAYER : AMBII_PLAYER_URL) + "/" + storeCode;
  const openLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Modal
      visible={visible}
      title={"Connect Player"}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={true}
      forceRender={true}
      className={"large-modal-wrapper"}
      footer={[
        <div className={"modal-footer-main-button-group"}>
          <Button
            key="connect"
            type={"primary"}
            onClick={() => {
              onCancel();
              openLink(playerUrl);
            }}
          >
            <FontAwesomeIcon icon={faPlayCircle} className={"button-icon"} />
            Play on Web
          </Button>
        </div>,
      ]}
    >
      <div className="instructional-modal-wrapper">
        <div className="inner-wrapper">
          <div className="content">
            <h1 className="headerText">Connect Player</h1>
            <div className="contentInfo">
              <h3>
                Download the <span style={{ color: colors.primaryColor, fontWeight: "bold" }}> Ambii - Music for Business</span> app on your phone/tablet and enter the code
              </h3>
              <div className="storeCodeWrapper">
                <h2 className="headerText">Store Code</h2>
                <div className="storeCodes">
                  {arrayOfCode &&
                    arrayOfCode.map((code) => (
                      <div key={code} className="codeBox">
                        {code}
                      </div>
                    ))}
                </div>
              </div>
              <div className="logos">
                <div className="ambiiLogo">
                  <img src={ambiiLogo} height="100%" width="100%" alt="..." />
                </div>
                <div className="store-button-wrapper" onClick={() => openLink("https:play.google.com/store/apps/details?id=com.ambii_business_music")}>
                  {!isApple && (
                    <div className="store-button">
                      <GooglePlay className={"connect-music-icon brand-icon"} style={{ width: 24, height: 48 }} />
                      <div className="store-button-text">
                        <div className="store-button-subtitle">Download on</div>
                        <div className="store-button-title"> Google Play </div>
                      </div>
                    </div>
                  )}

                  <div className="store-button" onClick={() => openLink("https:apps.apple.com/us/app/ambii-music-for-business/id1501666271")}>
                    <FontAwesomeIcon icon={faApple} className={"connect-music-icon brand-icon"} style={{ fontSize: "32px" }} />
                    <div className="store-button-text">
                      <div className="store-button-subtitle">Download on</div>
                      <div className="store-button-title"> App Store </div>
                    </div>
                  </div>
                </div>
              </div>
              <p
                className="playOnWeb"
                onClick={() => {
                  onCancel();
                  openLink(playerUrl);
                }}
              >
                or play on the web instead
              </p>
            </div>
          </div>
        </div>
        <div className="imageWrapper">
          <img src={phoneImage} alt="..." height="100%" width="100%" />
        </div>
      </div>
    </Modal>
  );
};

export default InstructionModal;
