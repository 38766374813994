/*eslint no-case-declarations: "off"*/

import { SET_SCHEDULE_BLOCKS, EDIT_SCHEDULE_BLOCK } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const scheduleBlock_reducer = (state = initialState.scheduleBlocks, action) => {
  let scheduleBlockId = get(action, "data._id", "").toString();
  let data = get(action, "data", {});

  switch (action.type) {
    case SET_SCHEDULE_BLOCKS:
      return Object.assign({}, state, { ...state, ...data });
    case EDIT_SCHEDULE_BLOCK:
      return Object.assign({}, state, { ...state, [scheduleBlockId]: { ...state[scheduleBlockId], ...data } });
    default:
      return state;
  }
};
export default scheduleBlock_reducer;
