import axios from "axios";
import { responses } from "../constants";
import { API_URL, AMBII_API_KEY } from "./index";
import { notification } from "antd";
import { get } from "lodash";

export async function HTTP(method, uri, data, headers = null, params = null) {
  const url = `${API_URL}${uri}`.trim();
  let query = { method, url, headers: {} };

  if (headers !== null) query.headers = headers;
  if (params !== null) query.params = params;
  if (method === "post" || method === "put" || method === "delete" || method === "patch") query.data = data;

  // Ambii keys
  const ambii_auth_jwt = localStorage.getItem("ambii_auth_jwt");

  if (AMBII_API_KEY) query.headers["ambii_api_key"] = AMBII_API_KEY;
  if (ambii_auth_jwt) query.headers["Authorization"] = "Bearer " + localStorage.getItem("ambii_auth_jwt");

  return new Promise(function (resolve, reject) {
    axios(query)
      .then((response) => {
        const { data: { success, data = null, message, type } = {} } = response;

        // Global server log
        console.log(`API Response: ${uri}`, { query, success, data, message, type });

        // Server response destructure
        if (API_URL.includes(".ambii.io") || API_URL.includes("localhost")) resolve({ success, data, message, type });
        else resolve(response);
      })
      .catch((err) => {
        console.log(`API Error: ${uri}`, { query, err });

        if (get(err, "response.data.type", null) === "TOO_MANY_REQUESTS") {
          notification.error({ message: responses.status.error, description: responses.description.tooManyRequests, placement: "bottomLeft" });
          resolve(false);
        } else if (get(err, "response.data.message", null) === "The token has expired, please login again!") {
          // Used to catch if another device connects while web player is connected
          resolve(false);
        } else if (err.response && err.response.status === 401) {
          localStorage.removeItem("ambii_auth_jwt");
          // window.location.reload(true);
        } else {
          reject(err);
        }
        reject(err);
      });
  });
}
