import React from "react";
import ScheduleRowItem from "../schedule-row-item";
import ScheduleRowEditor from "../schedule-row-editor";
import "./index.scss";

const SimpleScheduler = ({ blockIds, scheduleBlocks, onSave, focusedScheduleBlockId, setFocusedScheduleBlockId }) => (
  <div className={"simple-scheduler-wrapper"}>{focusedScheduleBlockId ? <ScheduleRowEditor id={focusedScheduleBlockId} data={scheduleBlocks[focusedScheduleBlockId]} back={() => setFocusedScheduleBlockId(null)} onSave={onSave} /> : blockIds.map((block) => <ScheduleRowItem key={block} title={scheduleBlocks[block]?.name} startTime={scheduleBlocks[block]?.startTime} endTime={scheduleBlocks[block]?.endTime} days={scheduleBlocks[block]?.days} genres={scheduleBlocks[block]?.genres} tempo={scheduleBlocks[block]?.tempo} onClick={() => setFocusedScheduleBlockId(block)} />)}</div>
);

export default SimpleScheduler;
