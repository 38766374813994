/*eslint no-case-declarations: "off"*/

import { SET_ZONES, EDIT_ZONE, DELETE_ZONE } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const zones_reducer = (state = initialState.zones, action) => {
  let zoneId = get(action, "data._id", "").toString();
  let data = get(action, "data", {});

  switch (action.type) {
    case SET_ZONES:
      return Object.assign({}, state, { ...state, ...data });
    case EDIT_ZONE:
      return Object.assign({}, state, { ...state, [zoneId]: { ...state[zoneId], ...data } });
    case DELETE_ZONE:
      let newZones = { ...state };
      delete newZones[get(action, "data", "").toString()];
      return Object.assign({}, newZones);
    default:
      return state;
  }
};

export default zones_reducer;
