import React from "react";
import { get } from "lodash";
import { Form, Input, Button, Select, TimePicker, Checkbox, List } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FloatLabel from "../floating-label";
import { formErrorMessages } from "../../constants";
import { frequencies, full_language_names, proper_voice_name } from "../../constants";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { getDayShortname } from "../../util";

const { TextArea } = Input;
const { Option } = Select;

const CheckboxGroup = Checkbox.Group;
const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const speedOptions = { 0.75: "Slow", 1: "Normal", 1.25: "Fast" };

const AnnouncementTTSForm = ({ data, getFieldDecorator, handleChange, handleSelect, addTime, checkboxTimeUpdate, uploadedAnnouncement, selectedDays, backgroundMusics, specifiedTimeArray, specifiedTimeMap, voiceOptions, onSpecificTimeChange, removeTime }) => {
  // Renders language option list
  const renderLanguageOptions = () => {
    return Object.keys(voiceOptions)
      .sort()
      .map((language) => <Option key={language}> {language} </Option>);
  };

  // Renders voice option list based on selected language
  const renderVoiceOptions = () => {
    if (!data["language"]) return;
    else {
      let voiceOptionList = Object.keys(voiceOptions).length > 0 ? voiceOptions[data["language"]] : [];
      voiceOptionList.sort((a, b) => (proper_voice_name[a["simpleName"]] > proper_voice_name[b["simpleName"]] ? 1 : -1));
      return voiceOptionList.map((voice) => (
        <Option key={voice["name"]} parent={voice["parent"]}>
          {proper_voice_name[voice["simpleName"]] || voice["simpleName"]}
        </Option>
      ));
    }
  };

  return (
    <Form>
      <FloatLabel required={true} label="Title" placeholder="Message Title" value={data["title"]}>
        <FormItem>
          {getFieldDecorator("title", {
            initialValue: data["title"],
            rules: [
              { required: true, message: formErrorMessages.required },
              {
                min: 1,
                message: "Title too short",
              },
              {
                max: 50,
                message: "Title too long",
              },
            ],
          })(<Input max={50} onChange={handleChange} />)}
        </FormItem>
      </FloatLabel>

      {!uploadedAnnouncement && (
        <FloatLabel required={true} label="Message" placeholder="Attention customers, all tacos are 50% of on Taco Tuesdays!" value={data["message"]}>
          <FormItem>
            {getFieldDecorator("message", {
              initialValue: data["message"],
              rules: [
                { required: true, message: formErrorMessages.required },
                {
                  min: 5,
                  message: "Message too short",
                },
                {
                  max: 500,
                  message: "Message too long",
                },
              ],
            })(<TextArea rows={3} max={500} onChange={handleChange} />)}
          </FormItem>
        </FloatLabel>
      )}

      {uploadedAnnouncement && (
        <FloatLabel label="Description" placeholder="Message description" value={data["description"]}>
          <FormItem>
            {getFieldDecorator("description", {
              initialValue: data["description"],
              rules: [
                {
                  max: 200,
                  message: "Description too long",
                },
              ],
            })(<TextArea rows={4} max={200} onChange={handleChange} />)}
          </FormItem>
        </FloatLabel>
      )}

      <div className={"ant-form-row"}>
        {!uploadedAnnouncement && (
          <FloatLabel required={true} label="Language" placeholder="Language Select" value={full_language_names[data["languageCode"]] || data["language"]}>
            <FormItem>
              {getFieldDecorator("language", {
                initialValue: data["language"],
                rules: [{ required: true, message: formErrorMessages.required }],
              })(<Select onChange={(e, v) => handleSelect({ language: v.key, languageCode: "", voice: "" })}>{renderLanguageOptions()}</Select>)}
            </FormItem>
          </FloatLabel>
        )}

        {!uploadedAnnouncement && (
          <FloatLabel required={true} label="Voice" placeholder="Voice Select" value={data["voice"]}>
            <FormItem>
              {getFieldDecorator("voice", {
                initialValue: data["voice"],
                rules: [{ required: true, message: formErrorMessages.required }],
              })(<Select onChange={(e, v) => handleSelect({ voice: v.key, languageCode: get(v, "props.parent", "") })}>{renderVoiceOptions()}</Select>)}
            </FormItem>
          </FloatLabel>
        )}

        {!uploadedAnnouncement && (
          <FloatLabel required={true} label="Speed" placeholder="Voice playback speed" value={data["speed"]}>
            <FormItem>
              {getFieldDecorator("speed", {
                initialValue: speedOptions[data["speed"]],
                rules: [{ required: true, message: formErrorMessages.required }],
              })(
                <Select onChange={(e, v) => handleSelect({ speed: get(v, "props.parent", "") })}>
                  {Object.keys(speedOptions).map((speed) => (
                    <Option key={speedOptions[speed]} parent={speed}>
                      {speedOptions[speed]}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </FloatLabel>
        )}
      </div>

      <FloatLabel required={true} label="Frequency" placeholder="When should this message play?" value={data["frequency"]}>
        <FormItem>
          {getFieldDecorator("frequency", {
            initialValue: data["frequency"],
            rules: [{ required: true, message: formErrorMessages.required }],
          })(
            <Select onChange={(e, v) => handleSelect({ frequency: v.key })}>
              {frequencies.map((freq, i) => (
                <Option key={get(freq, "time", i)} value={get(freq, "time", "60")}>
                  {get(freq, "title", "Error")}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
      </FloatLabel>

      {data["frequency"] === "specific time" && (
        <div className="specific-time-wrapper">
          <div className="specific-time-wrapper-direction">
            <div className="specific-time-selection">
              <div className="selection-option">
                <h4 className="custom-label">Select Time: </h4>
                <TimePicker
                  format={"hh:mm A"}
                  defaultOpenValue={moment(moment().format("L") + " " + "00:00")}
                  onChange={(e) => {
                    if (e) onSpecificTimeChange({ selectedTime: { selectedTime: e?._d?.toTimeString(), unixTimeStamp: e.unix() } });
                  }}
                  style={{ width: "100%" }}
                  use12Hours
                />
              </div>
              <div className="selection-option">
                <h4 className="custom-label">Select Days: </h4>
                <CheckboxGroup options={daysOfTheWeek} value={selectedDays} onChange={checkboxTimeUpdate} />
              </div>
              <Button onClick={addTime} type={Object.keys(specifiedTimeMap).length > 0 ? "secondary" : "primary"} disabled={selectedDays.length === 0 && selectedDays.length === 0}>
                <FontAwesomeIcon icon={faPlusCircle} className={"button-icon"} />
                Add Time
              </Button>
            </div>

            <div className={"divider"} />

            <div className="specific-time-value-wrapper">
              <h4 className="custom-label">Announcement Times: </h4>
              {Object.keys(specifiedTimeMap).length > 0 ? (
                <List
                  itemLayout={"horizontal"}
                  dataSource={specifiedTimeArray}
                  rowKey={Date.now()}
                  renderItem={(item) => (
                    <List.Item id={get(item, "hash", "")}>
                      <List.Item.Meta title={getDayShortname(item?.days)} description={`${(parseInt(item?.time?.selectedTime.slice(0, 2)) % 12 || 12).toString() + item?.time?.selectedTime.slice(2, 5).toString()} ${parseInt(item.time.selectedTime.slice(0, 2)) / 12 < 1 ? "AM" : "PM"}`} />
                      <FontAwesomeIcon id={get(item, "hash", "")} icon={faTimes} style={{ cursor: "pointer", margin: "0px 10px" }} onClick={(e) => removeTime(get(e, "currentTarget.id", ""))} />
                    </List.Item>
                  )}
                />
              ) : (
                "No Times Scheduled Yet"
              )}
            </div>
          </div>
        </div>
      )}

      {!uploadedAnnouncement && (
        <FloatLabel required={true} label="Background Music" placeholder="Message background audio" value={data["backgroundMusic"]}>
          <FormItem>
            {getFieldDecorator("backgroundMusic", {
              initialValue: data["backgroundMusic"],
              rules: [{ required: true, message: formErrorMessages.required }],
            })(
              <Select onChange={(e, v) => handleSelect({ backgroundMusic: get(v, "props.value", 2) })}>
                {backgroundMusics.map((music, i) => (
                  <Option key={get(music, "musicName", i)} value={get(music, "musicNo", i)}>
                    {get(music, "musicName", i)}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </FloatLabel>
      )}
    </Form>
  );
};

export default Form.create()(AnnouncementTTSForm);
