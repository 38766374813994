import { HTTP } from "../configs";
import { _addCard, _deleteCard, _setCards } from "./dispatch";
import { get } from "lodash";
import { notification } from "antd";
import { objArrayToMap } from "../util";
import { responses } from "../constants";

// Adds a payment card to the user's profile (by id)
export function addPaymentCardById(paymentMethodId) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("post", `/v3/auth/card/${paymentMethodId}`)
        .then(({ success, message, data }) => {
          if (success) dispatch(_addCard(data));
          else notification.error({ message: responses.status.error, description: responses.description.failedToAddData, placement: "bottomLeft" });
          resolve(get(data, "cardId", ""));
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Adds a payment card to the user's profile
export function addPaymentCard(incomingData) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("post", "/v3/auth/card", incomingData)
        .then(({ success, message, data }) => {
          if (success) dispatch(_addCard(data));
          else notification.error({ message: responses.status.error, description: responses.description.failedToAddData, placement: "bottomLeft" });
          resolve(data);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Deletes a user's payment card
export function deletePaymentCard(cardId) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      if (!cardId) {
        notification.error({ message: responses.status.warn, description: responses.description.requiredDataNotFound, placement: "bottomLeft" });
        resolve(false);
      }

      HTTP("delete", `/v3/auth/card/${cardId}`)
        .then(({ success, message, data }) => {
          if (success) dispatch(_deleteCard(data));
          else notification.error({ message: responses.status.error, description: responses.description.failedToDeleteData, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Gets a user's payment cards
export function getPaymentCards() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", "/v3/auth/card")
        .then(({ success, message, data }) => {
          if (success) {
            let cardMap = objArrayToMap(data, "cardId");
            if (Object.keys(cardMap).length > 0) dispatch(_setCards(cardMap));
          }
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
}
