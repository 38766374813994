import React, { useState } from "react";
import "./index.scss";

const FloatLabel = ({ children, label, value, placeholder, required, className, style, labelStyle, placeholderStyle }) => {
  const [focus, setFocus] = useState(false);

  const isFocused = focus || (value && value.length !== 0);
  let labelClass = isFocused ? "label label-float " : "label ";

  let wrapperClass = "float-label noselect ";
  if (className) wrapperClass = wrapperClass.concat(className);

  return (
    <div className={wrapperClass} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={style}>
      {children}
      {focus && !value && (
        <div className={"placeholder noselect"} style={placeholderStyle}>
          {placeholder}
        </div>
      )}
      <label className={labelClass} style={labelStyle}>
        <span className={"required noselect"}>{required && "* "}</span> {label}
      </label>
    </div>
  );
};

export default FloatLabel;
