/*eslint no-case-declarations: "off"*/

import { ADD_BILLING_ADDRESS, EDIT_BILLING_ADDRESS, DELETE_BILLING_ADDRESS, SET_BILLING_ADDRESSES } from "./../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";

const billing_reducer = (state = initialState.billing, action) => {
  let billingId = get(action, "data._id", "").toString();
  let data = get(action, "data", {});

  switch (action.type) {
    case SET_BILLING_ADDRESSES:
      return Object.assign({}, state, { ...state, ...data });
    case ADD_BILLING_ADDRESS:
      return Object.assign({}, state, { ...state, [billingId]: data });
    case EDIT_BILLING_ADDRESS:
      return Object.assign({}, state, { ...state, [billingId]: { ...state[billingId], ...data } });
    case DELETE_BILLING_ADDRESS:
      let newBillingAddresses = { ...state };
      delete newBillingAddresses[get(action, "data", "").toString()];
      return Object.assign({}, newBillingAddresses);
    default:
      return state;
  }
};

export default billing_reducer;
