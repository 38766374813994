import { get, split } from "lodash";
import { colors, tempos } from "../constants";
import { genreColors } from "../constants";
import { generate } from "geopattern";
import { notification } from "antd";
import { faCcVisa, faCcMastercard, faCcDiscover, faCcJcb, faCcAmex, faCcApplePay, faCcDinersClub } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";

// Formats price (takes price in cents. ex: 128 = $1.28, 1600 = $16.00)
export const getFormattedPrice = (price) => {
  if (!price) return "";
  return "$" + price / 100;
};

// Checks to see if each schedule field is valid. Otherwise, returns a field-specific error
export const checkIfValidSchedule = ({ scheduleData, scheduleBlocks }) => {
  if (Object.keys(scheduleData) <= 0) {
    notification.warn({ message: "Required fields missing", placement: "bottomLeft" });
    return false;
  }

  let failMessage = "";
  let failedBlocks = [];
  get(scheduleData, "scheduleBlocks", []).forEach((block) => {
    if (scheduleBlocks[block]?.genres.length <= 0) failedBlocks.push(scheduleBlocks[block]?.name);
  });

  if (!scheduleData["name"] || scheduleData["name"].length < 5) failMessage = "Please add a longer name";
  if (failedBlocks.length > 0) failMessage = "Please complete the following schedule blocks: " + failedBlocks.join(", ");

  if (failMessage) {
    notification.warn({ message: failMessage, placement: "bottomLeft" });
    return false;
  }
  return true;
};

// Checks to see if each ISM field is valid. Otherwise, returns a field-specific error
export const checkValidAnnouncement = ({ data, specifiedTimeArray, uploadedAnnouncement }) => {
  if (Object.keys(data) <= 0) {
    notification.warn({ message: "Required fields missing", placement: "bottomLeft" });
    return false;
  }

  let failMessage = "";

  // Common items
  if (!data["title"]) failMessage = "Please add a title to your message";
  if (!data["frequency"]) failMessage = "Please select a frequency";

  if (!uploadedAnnouncement) {
    // Non-uploaded Announcements
    if (!data["message"] || data["message"].length < 5) failMessage = "Please add a longer message";
    if (!data["language"]) failMessage = "Please select a language";
    if (!data["voice"]) failMessage = "Please select a voice";
    if (data["frequency"] === "specific time" && specifiedTimeArray.length === 0) failMessage = "Please select a time to play your announcement";
    if (!data["speed"]) failMessage = "Please select a speed";
  }

  if (failMessage) {
    notification.warn({ message: failMessage, placement: "bottomLeft" });
    return false;
  }
  return true;
};

// Takes time as seconds, and converts it into "HH:MM AM/PM"
export const convertFromSeconds = (time) => {
  const secondsInDay = 86400;
  if (!time && time !== 0) return "";
  if (time === 0) time = secondsInDay;
  let newTime = time;

  // let day = Math.floor(newTime / secondsInDay); // Sun = 0

  while (newTime > secondsInDay) newTime -= secondsInDay;
  let timeAsString = new Date(newTime * 1000).toISOString().substr(11, 8);
  timeAsString = timeAsString.split(":");

  let hours = timeAsString[0];
  let minutes = timeAsString[1];

  // calculate
  var timeValue;

  if (hours > 0 && hours <= 12) timeValue = "" + hours;
  else if (hours > 12) timeValue = "" + (hours - 12);
  else if (hours == 0) timeValue = "12";

  timeValue += ":" + minutes; // get minutes
  timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

  // console.log({ timeValue, time, newTime, timeAsString, hours, minutes })

  return timeValue;
};

// Takes card brand and returns proper image
export const getCardBrandIcon = (str) => {
  if (!str) return null;
  let lowerCaseString = get(str, "brand", "").toLowerCase();

  if (lowerCaseString === "visa") return faCcVisa;
  if (lowerCaseString === "mastercard") return faCcMastercard;
  if (lowerCaseString === "american express") return faCcAmex;
  if (lowerCaseString === "diners club") return faCcDinersClub;
  if (lowerCaseString === "discover") return faCcDiscover;
  if (lowerCaseString === "jcb") return faCcJcb;
  return faCreditCard;
};

// Takes card information and returns an array of formatted card data
export const getFormattedCardDetails = (data) => {
  const { cardNumber, expMonth, expYear, nameOnCard, brand } = data || {};
  if (!cardNumber && !expMonth && !expYear && !nameOnCard && !brand) return ["", ""];
  let str1 = "";
  let str2 = "Expires ";

  // Build formatted string
  str1 = str1.concat(brand).concat(" •••• ").concat(cardNumber);
  str2 = str2.concat(expMonth).concat("/").concat(expYear);

  return [str1, str2];
};

// Takes location address, and returns a single formatted string
export const getFormattedLocationAddress = ({ addressOne, addressTwo }) => {
  if (!addressOne) return "";
  let str = addressOne;
  if (addressTwo) str = str.concat(", ").concat(addressTwo);
  return str;
};

// Takes location name and address, and returns a single formatted string
export const getFormattedLocationNameWithAddress = ({ name, addressOne, addressTwo }) => {
  if (!name && !addressOne) return null;
  let str = "";

  // Build formatted string
  str = str.concat(name).concat(" - ").concat(addressOne);
  if (addressTwo) str = str.concat(", ").concat(addressTwo);

  return str;
};

// Takes billing address data and returns a single string
export const getLongBillingAddress = ({ addressOne, addressTwo, city, state, country, postalCode }) => {
  let str = "";
  if (addressOne) str = str.concat(" ").concat(addressOne);
  if (addressTwo) str = str.concat(", ").concat(addressTwo);
  if (city) str = str.concat(", ").concat(city);
  if (state) str = str.concat(", ").concat(state);
  if (postalCode) str = str.concat(", ").concat(postalCode);
  if (country) str = str.concat(", ").concat(country);
  return str;
};

// Returns a proper text description for user about the frequency interval
export const getTimeDisplayText = (frequency) => {
  if (!frequency) return "0";
  else if (frequency === "0") return "Announcement Inactive";
  else if (frequency === "15") return "Every 15 Minutes";
  else if (frequency === "30") return "Every 30 Minutes";
  else if (frequency === "60") return "Every Hour";
  else if (frequency === "180") return "Every Few Hours";
  else if (frequency === "specific time") return "Scheduled Time";
  return "";
};

// Converts number to day of the week (same number days as Date().getDay())
export const convertNumberToDay = (number) => {
  if (number === 0) return "Sunday";
  else if (number === 1) return "Monday";
  else if (number === 2) return "Tuesday";
  else if (number === 3) return "Wednesday";
  else if (number === 4) return "Thursday";
  else if (number === 5) return "Friday";
  else if (number === 6) return "Saturday";
  else return "";
};

// Takes an array of days, and tries to return shorthand for it
export const getDayShortname = (days) => {
  if (!days) return "";

  // If our 'days' array is an array of numbers, convert it to 3-len string array
  if (days[0] >= 0 && days[0] <= 9) {
    let old = days;
    days = [];
    old.forEach((day, i) => days.push(convertNumberToDay(day).substring(0, 3)));
  }

  if (days.length === 7 && days.includes("Mon") && days.includes("Tue") && days.includes("Wed") && days.includes("Thu") && days.includes("Fri") && days.includes("Sat") && days.includes("Sun")) return "Everyday";
  if (days.length === 5 && days.includes("Mon") && days.includes("Tue") && days.includes("Wed") && days.includes("Thu") && days.includes("Fri")) return "Weekdays";
  if (days.length === 2 && days.includes("Sat") && days.includes("Sun")) return "Weekends";
  else return getArrayAsString(days);
};

// Converts array to comma seperated string (ex: "a, b, c")
export const getArrayAsString = (arr) => {
  if (!arr) return "";
  let str = "";
  arr.forEach((elem, i) => {
    str = str.concat(elem);
    if (i < arr.length - 1) str = str.concat(", ");
  });
  return str;
};

// Generates a random string of length (arg)
export const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result.toUpperCase();
};

// Generates a random number of length (arg)
export const generateRandomNumbers = (length) => {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
  return result.toUpperCase();
};

/**
 * Converts an object-array to map
 * @param {*} arr - Array to convert
 * @param {*} key - Object key
 */
export const objArrayToMap = (arr, key) => {
  if (!arr || (arr && arr.length == 0)) return {};
  if (!key) return arr;

  let map = {};
  arr.map((item) => {
    return (map[get(item, key, null)] = item);
  });
  return map;
};

// Convert array to map
export const arrayToMap = (arr) => {
  if (!arr) return {};
  let map = {};
  arr.map((item) => {
    return (map[item] = true);
  });
  return map;
};

/**
 *
 * @param {string} property - What property to filter by
 * @param {object} objToCopy - The object to copy & filter
 * @returns Filtered object
 */
export const filterObjectMap = (property, objToCopy) => {
  let filteredRes = { ...objToCopy };

  Object.keys(filteredRes).forEach((val) => {
    if (!get(filteredRes[val], property, false)) delete filteredRes[val];
  });
  return filteredRes;
};

export const getStationImageURL = ({ name, genres }) => {
  if (!name && !genres) return "";

  const getPrimaryGenre = (object) => {
    return Object.keys(object).filter((x) => {
      return object[x] === Math.max.apply(null, Object.values(object));
    });
  };

  var color = colors.primaryColor;
  if (genres) color = genreColors[getPrimaryGenre(genres)[0]];

  var options = { color };
  var pattern = generate(name, options);
  return pattern.toDataUrl();
};

// Converts seconds into "0:13" time format
export const getTime = (time) => {
  if (!isNaN(time)) return Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2);
  else return "0:00";
};

// Gets the BPM info
export const getBPMInfo = (bpm) => {
  if (bpm >= 0 && bpm <= 110) return tempos["Slower"];
  else if (bpm >= 111 && bpm <= 120) return tempos["Slow"];
  else if (bpm >= 121 && bpm <= 127) return tempos["Moderate"];
  else if (bpm >= 128 && bpm <= 140) return tempos["Fast"];
  else if (bpm >= 141 && bpm <= 900) return tempos["Faster"];
  else return tempos["Moderate"];
};

// Creates, sets, and returns a deviceId
export const getDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId"); // Get previously generated deviceId (if any)
  if (!deviceId) {
    deviceId = "web-" + generateRandomString(10); // If no deviceId, create one
    localStorage.setItem("deviceId", deviceId); // Store deviceId
  }
  return deviceId;
};

// API call to get random words
export function getRandomWords(number) {
  return new Promise((resolve) => {
    axios({ method: "get", url: `https://random-word-api.herokuapp.com/word?number=${number || 1}&swear=1` })
      .then((data) => resolve(get(data, "data", ["Finn"])))
      .catch(() => resolve(["Finn"]));
  });
}

export const getQuadraticRoots = (a, b, c) => {
  try {
    if ((!a && a !== 0) || (!b && b !== 0) || (!c && c !== 0)) return 1;
    let discriminant = b * b - 4 * a * c; // calculate discriminant
    if (discriminant > 0) return (-b + Math.sqrt(discriminant)) / (2 * a);
    // condition for real and different roots
    else if (discriminant === 0) return -b / (2 * a);
    // condition for real and equal roots
    else {
      // if roots are not real
      let realPart = (-b / (2 * a)).toFixed(2);
      let imagPart = (Math.sqrt(-discriminant) / (2 * a)).toFixed(2);
      return realPart + imagPart;
    }
  } catch (e) {
    console.log("Error on getQuadraticRoots");
    return 1;
  }
};
