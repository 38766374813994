export const responses = {
  // Statuses
  status: {
    success: "Success",
    error: "Error",
    warn: "Notice",
  },

  // Section Names
  sectionName: {
    location: "Location",
    station: "Station",
    schedule: "Schedule",
    favorite: "Favorite",
    user: "User",
    company: "Company",
    billing: "Billing",
    announcement: "Announcement",
  },

  // User Action Feedback
  action: {
    signUp: "Welcome to Ambii",
    signIn: "Signed In",
    signOut: "Signed Out",
    stationAssign: "Station Assigned",
    addedFavorite: "Favorite Added",
    removedFavorite: "Favorite Removed",
    requiredDataMissing: "Required Data Missing",
    copy: "Copied",
  },

  // General Descriptions
  description: {
    // Sign Up
    signUpSuccess: "Thanks for signing up!",
    signUpFail: "Failed to sign up. Please refresh and try again.",

    // Sign In
    welcome: "Please complete your company's profile to continue",
    welcomeBack: "Welcome back",

    // Sign Out
    signOutSuccess: "You have been signed out",

    // General Messages
    copiedStoreCode: "Store code copied to clipboard",

    // Schedules
    defaultScheduleDescription: "A weekly music schedule",
    defaultAnnouncementDescription: "A user-uploaded announcement",

    // Subscripotion
    addSubscriptionSuccess: "Thanks for subscribing with Ambii!",
    addSubscriptionFailed: "Failed to add subscription. Please refresh and try again.",
    cancelSubscriptionSuccess: "Subscription cancelled. We're sorry to see you go.",
    cancelSubscriptionFailed: "Failed to cancel subscription. Please refresh and try again.",
    billingAddressMissing: "Please select a billing address.",
    cardDataMissing: "Please fill out all card data.",

    // General Errors
    internalServerError: "A server error occurred. Please refresh and try again.",
    requiredDataNotFound: "Required data not found.",
    noChangesToSave: "There are no changes to save.",
    loginFail: "Something went wrong. Please login again.",
    failedToAddData: "Could not add requested data. Please refresh and try again.",
    failedToEditData: "Could not modify requested data. Please refresh and try again.",
    failedToRetrieveData: "Could not retrieve data. Please refresh and try again.",
    failedToDeleteData: "Could not remove requested data. Please refresh and try again.",
    locationSearchDataMissing: "Some data fields were not found. Please complete all required fields.",
    requiredDataMissing: "One or more required fields are missing.",

    // Rate Limiter
    tooManyRequests: "You have made too many API requests. Please wait a minute before making more.",
  },

  // Partial Responses
  partial: {
    addedFavorite: " added to favorites.",
    removedFavorite: " removed from favorites",
  },
};
