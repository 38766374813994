import { setCurrentTrack, setCurrentTrackNumber, setIsPlaying, setStationDetails, setTrackList } from ".";

export const createTrackList = (stationData) => (dispatch) => {
  //filters for explicit tracks & available previews
  const filteredList = stationData.top_tracks.filter((track) => track.preview && !track.explicit_lyrics);
  dispatch(setStationDetails(stationData));
  dispatch(setTrackList(filteredList));
  dispatch(setCurrentTrackNumber(0));
  dispatch(setCurrentTrack(filteredList[0]));
};

export const nextTrack = () => (dispatch, getState) => {
  return new Promise((resolve) => {
    try {
      const { currentTrackNumber, tracks } = getState().player;
      const isLastTrack = tracks.length - 1 === currentTrackNumber;
      const nextTrack = isLastTrack ? tracks[0] : tracks[currentTrackNumber + 1];

      dispatch(setCurrentTrack(nextTrack));
      dispatch(setCurrentTrackNumber(isLastTrack ? 0 : currentTrackNumber + 1));
      resolve(true);
    } catch (error) {
      resolve(false);
    }
  });
};

export const clearPlayerData = () => (dispatch) => {
  dispatch(setStationDetails(null));
  dispatch(setTrackList([]));
  dispatch(setCurrentTrackNumber(0));
  dispatch(setCurrentTrack(null));
};
