import { HTTP } from "../configs";
import { setLocations, _addLocation, _editLocation, _deleteLocation, _editSchedule } from "./dispatch";
import { notification } from "antd";
import { objArrayToMap } from "../util";
import { getAnnouncements } from "./announcements";
import { responses } from "../constants";

// Ges all of a user's locations
export function getLocations() {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("get", `/v3/auth/location`)
        .then(({ success, message, data }) => {
          if (success) {
            let locationMap = objArrayToMap(data, "_id");
            dispatch(setLocations(locationMap));
          } else notification.error({ message: responses.status.error, description: responses.description.failedToRetrieveData, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  };
}

// Creates a new location
export function addLocation(dataToSend) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("post", "/v3/auth/location/", dataToSend)
        .then(({ success, data, message, type }) => {
          if (success) {
            dispatch(_addLocation(data));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else {
            if (type === "PRODUCT_LIMIT_REACHED_LOCATION") notification.warn({ message: responses.status.warn, description: message, placement: "bottomLeft" });
            else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          }
          resolve(data);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Deletes a location
export function deleteLocation(locationId) {
  return function (dispatch) {
    return new Promise(async (resolve) => {
      HTTP("delete", `/v3/auth/location/${locationId}`)
        .then(({ success, data, message }) => {
          if (success) {
            let scheduleMap = objArrayToMap(data?.schedules, "_id");

            dispatch(_deleteLocation(locationId));
            dispatch(getAnnouncements());
            Object.keys(scheduleMap).forEach((scheduleId) => dispatch(_editSchedule(scheduleMap[scheduleId])));

            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

// Edits a location's data
export function editLocation({ dataToSend, locationId }) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/location/${locationId}`, dataToSend)
        .then(({ success, message }) => {
          if (success) {
            dispatch(_editLocation({ ...dataToSend, _id: locationId }));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
