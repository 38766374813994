import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { get, isEqual } from "lodash";
import { trackScreen, API_URL } from "../../configs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCrown, faStoreAlt, faBadgeDollar, faCog, faPlusCircle, faArrowRight, faArrowLeft, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { notification, Spin, Modal, Button, Form, Tabs, Input } from "antd";
import PageTitle from "../../components/page-title";
import LocationBlock from "../../components/location-block";
import AntSpinner from "../../components/antSpinner";
import { states, responses, locationFormFields, productTypes, defaultLocationSettings, colors, locationSettingTypes } from "../../constants";
import { addLocation, editLocation, deleteLocation, getLocations } from "../../actions";
import { getFormattedLocationAddress, getRandomWords, generateRandomNumbers } from "../../util";
import { Animated } from "react-animated-css";
import FilterList from "../../components/filter-list";
import InstructionModal from "../../modals/instruction-modal";
import LocationForm from "../../components/location-form";
import FloatLabel from "../../components/floating-label";
import NoPageData from "../../components/no-page-data";
import UpgradePreview from "../../components/upgrade-preview";
import LocationSettings from "../../components/location-settings";
import "./index.scss";

const { TabPane } = Tabs;
const { confirm } = Modal;

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: false, // Is the page loading
      modalVisible: false, // Is our modal visible
      tabSection: "edit", // Current tab in-focus
      locationData: {}, // Location data that we're currently viewing
      annualSubscription: false, // Does the selected location want to subscribe annually
      termsAgreed: false, // Has the user agreed to Ambii's terms of service
      confirmLoading: false, // Is the user deleting a location
      addingNewLocation: false, // Is the user adding a new location
      editExisting: false, // Is the user editing a location
      stateRes: [], // Filtered States to show
      isSaving: false, // Are we saving changes
      isAutofilling: false, // Are we waiting for autofill
      searchText: "", // Search text for location filtering
      filteredLocations: [], // List of filtered locations
      filteredLocationsMap: [], // Map of filtered locations
      instructionalModalVisible: false, // Is the instructional modal visible
      focusedStoreCode: "", // Store Code of the focused player to connect
      steps: 2, // How many steps for new location
      currentStep: 1, // What step is the user on
      changesToSave: false, // Are there changes that the user can save
    };
  }

  componentDidMount() {
    trackScreen();
    this.props.actions.getLocations();

    let stateRes = [];
    let stateKeys = Object.keys(states);
    stateKeys.forEach((state) => stateRes.push(state));
    this.setState({ stateRes });
    this.onLocationSearchChange(); // update our location search data on page load
  }

  componentDidUpdate(prevProps) {
    // If our locations prop changes, update our location search data
    if (!isEqual(this.props?.locations, prevProps?.locations)) {
      this.onLocationSearchChange();
    }
  }

  // On Autocomplete serach (filters data list)
  onAutoCompleteSearch = (type, searchText) => {
    let initValue = type === "state" ? states : {};
    let results = type === "state" ? "stateRes" : "";
    let keys = Object.keys(initValue);
    let filteredData = keys.filter((s) => s.toLowerCase().includes(searchText.toLowerCase()));
    this.setState({ filteredData: searchText.length <= 0 ? keys : filteredData, [results]: filteredData, locationData: { ...this.state.locationData, [type]: searchText } });
  };

  // Updates state w/ search text changes, and 'filteredLocations' to display
  onLocationSearchChange = (e) => {
    const { locations } = this.props;

    let searchText = e?.target?.value || "";

    let filteredLocations = [];
    let activeFilteredLocations = [];
    let inactiveFilteredLocations = [];

    let filteredLocationsMap = {};
    let activeFilteredLocationsMap = {};
    let inactiveFilteredLocationsMap = {};

    // Inner-function to add values to our array & map
    const addValues = (location, id, isActive) => {
      if (isActive) {
        activeFilteredLocations.push(location);
        activeFilteredLocationsMap[id] = location;
      } else {
        inactiveFilteredLocations.push(location);
        inactiveFilteredLocationsMap[id] = location;
      }
    };

    if (searchText.length > 0) {
      Object.keys(locations).forEach((id) => {
        let location = locations[id];
        if (location?.name.toLowerCase().includes(searchText)) addValues(location, id, location?.isActive);
        else if (location?.addressLineOne.toLowerCase().includes(searchText)) addValues(location, id, location?.isActive);
        else if (location?.addressLineTwo.toLowerCase().includes(searchText)) addValues(location, id, location?.isActive);
        else if (location?.storeCode.toLowerCase().includes(searchText)) addValues(location, id, location?.isActive);
      });
    } else Object.keys(locations).forEach((id) => addValues(locations[id], id, locations[id]?.isActive));

    filteredLocations = activeFilteredLocations.concat(inactiveFilteredLocations);
    filteredLocationsMap = { ...activeFilteredLocationsMap, ...inactiveFilteredLocationsMap };

    this.setState({ searchText, filteredLocations, filteredLocationsMap });
  };

  // Sets 'locationData' state variable based on incoming 'incomingId'
  async setLocationData(incomingId = null) {
    return new Promise((resolve) => {
      const { setFieldsValue } = this.props.form;

      let locationData = {};
      let editExisting = false;

      // Clears the stored location field values (otherwise may carry over from 'add location' fields)
      this.resetFormFields();

      if (incomingId) {
        locationData = { ...this.state.locationData };
        let location = this.props.locations[incomingId];
        editExisting = true; // If we have a location ID, then we must be editing a location

        locationFormFields.forEach((field) => {
          locationData[field] = location[field];
          setFieldsValue({ [field]: location[field] });
        });

        // Add some extra data fields for convienence
        locationData["_id"] = location["_id"];
        locationData["formattedAddress"] = getFormattedLocationAddress({ addressOne: locationData["addressLineOne"], addressTwo: locationData["addressLineTwo"] });
      } else {
        if (!locationData["_id"]) {
          this.resetFormFields();
          locationData["_id"] = null;
          locationData["explicitAllowed"] = defaultLocationSettings.explicitAllowed;
          locationData["allowPlayerToChangeStation"] = defaultLocationSettings.allowPlayerToChangeStation;
        }
      }

      this.setState({ locationData, editExisting, currentStep: 1 }, () => {
        resolve(true);
      });
    });
  }

  resetFormFields() {
    const { setFieldsValue } = this.props.form;
    locationFormFields.forEach((field) => setFieldsValue({ [field]: "" }));
  }

  // Prompts user to delete a location
  handleConfirm = ({ id, data, props, confirmLoading, closeModal }, type) => {
    let subscribed = get(props, "company.subscribed", false);
    let titleText = type === locationSettingTypes.upgrade ? locationSettingTypes.activate : type;
    let okText =
      type === locationSettingTypes.upgrade ? (
        <>
          <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
          Upgrade Now
        </>
      ) : (
        "OK"
      );

    confirm({
      maskClosable: true,
      title: `${titleText} Location`,
      content: (
        <>
          Are you sure you wish to {titleText} <br />
          <span style={{ fontWeight: "bold" }}>
            {data["name"]} - {data["formattedAddress"]}
          </span>
          ?
          {type === locationSettingTypes.activate && subscribed && (
            <div className={"payment-alert-wrapper"} style={{ marginTop: "15px", marginLeft: "-38px" }}>
              <FontAwesomeIcon icon={faBadgeDollar} className={"button-icon"} />
              <div className={"payment-text-wrapper"}>
                This location will be added to your existing billing cycle <br />
                <div className={"payment-price"}>$16.00 USD per zone/month</div>
                <div className={"payment-subtext"}>This addition will be charged right away at a prorated price</div>
              </div>
            </div>
          )}
          {type === locationSettingTypes.upgrade && !subscribed && (
            <div style={{ display: "flex", flexDirection: "column", marginTop: "15px" }}>
              You need to upgrade your account in order to activate this location <br />
              <br />
            </div>
          )}
        </>
      ),
      okText,
      onOk() {
        confirmLoading(type);

        if (type === locationSettingTypes.deactivate) {
          props.actions.editLocation({ dataToSend: { isActive: false }, locationId: id }).then(() => {
            confirmLoading(false);
          });
        } else if (type === locationSettingTypes.activate) {
          props.actions.editLocation({ dataToSend: { isActive: true }, locationId: id }).then(() => {
            confirmLoading(false);
          });
        } else if (type === locationSettingTypes.delete) {
          props.actions.deleteLocation(id).then(() => {
            confirmLoading(false);
            closeModal();
          });
        } else if (type === locationSettingTypes.upgrade) {
          confirmLoading(false);
          closeModal();
          props.history.push("/choose-plan");
        } else {
          confirmLoading(false);
          closeModal();
        }
      },
      onCancel() {
        confirmLoading(false);
      },
    });
  };

  // What to do when form is submitted
  handleSubmit = async (e) => {
    const { locations } = this.props;
    const { locationData, editExisting } = this.state;

    e.preventDefault();

    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        this.setState({ tabSection: "edit" });
        notification.warn({ message: responses.status.warn, description: responses.description.requiredDataMissing, placement: "bottomLeft" });
        return;
      }
      if (!states[fieldsValue?.state]) {
        notification.error({ message: "Invalid State", description: "Please select valid state from the dropdown menu", placement: "bottomLeft" });
        return;
      }

      this.setState({ isSaving: true }, async () => {
        let dataToSend = {}; // Final data
        let tempData = { ...locationData, ...fieldsValue }; // Create a copy of our state / form data

        locationFormFields.forEach((field) => {
          if (editExisting) {
            if (tempData[field].toString() && !isEqual(tempData[field].toString(), locations[locationData["_id"]][field].toString())) dataToSend[field] = tempData[field];
          } else dataToSend[field] = tempData[field];
        });

        if (editExisting) this.props.actions.editLocation({ dataToSend, locationId: tempData["_id"] }).then(() => this.setState({ isSaving: false }));
        else {
          this.props.actions.addLocation(dataToSend).then((res) => {
            this.setState({ isSaving: false }, async () => {
              if (res) {
                await this.setLocationData(get(res, "_id", ""));
                this.closeModal();
              } else this.closeModal();
            });
          });
        }
      });
    });
  };

  /**
   * Handles Input Changes
   * Future Reference: We're using both setState & setFieldsValue because antd will compain not to use setState while also using fieldDecorator, so we're using setFieldsValue as a remedy so we can make use of fieldDecorator
   *  */
  handleChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    this.props.form.setFieldsValue({ [key]: value });
    this.setState({ locationData: { ...this.state.locationData, [key]: value } }, () => {
      this.calculateChanges();
    });
  };

  /**
   * Handles Dropdown Changes.
   * @param: Object - Takes in an object we'll map through and assign to both state & setFieldsValue
   * Future Reference: We're using both state & setFieldsValue because we need state for actual data updates, but form will want to display values from 'setFieldsValue', so we need to try and keep them in-sync.
   *  */
  handleSelect = (map) => {
    if (Object.keys(map) <= 0) return;
    else Object.keys(map).forEach((key) => this.props.form.setFieldsValue({ [key]: map[key] }));
    this.setState({ locationData: { ...this.state.locationData, ...map } }, () => {
      this.calculateChanges();
    });
  };

  // Runs when a location setting is updated
  updateLocationSettings(settings) {
    this.setState({ locationData: { ...this.state.locationData, ...settings } }, () => {
      this.calculateChanges();
    });
  }

  // Check if there are any differences between state data and prop data
  calculateChanges() {
    const { locations } = this.props;
    const { editExisting, locationData } = this.state;

    if (editExisting) {
      let propCopy = {}; // Create a copy of our prop form data
      let stateCopy = { ...locationData }; // Create a copy of our state form data

      locationFormFields.forEach((field) => {
        propCopy[field] = locations[locationData["_id"]][field];
      });

      delete stateCopy["_id"];
      delete stateCopy["formattedAddress"];

      this.setState({ changesToSave: !isEqual(propCopy, stateCopy) });
    } else {
      this.setState({ changesToSave: true });
    }
  }

  // Opens modal
  showModal = async (key) => {
    this.setState({ modalVisible: true, tabSection: key });
  };

  // Closes modal and resets modal states
  closeModal = async () => {
    this.setState({ modalVisible: false, addingNewLocation: false });
  };

  // Runs when user presses 'Add Location' button
  async clickedAddLocation() {
    this.setState({ addingNewLocation: true }, async () => {
      await this.setLocationData();
      this.showModal("edit");
    });
  }

  // When user swaps tab menus
  async newTabSelection(tabSection) {
    this.setState({ tabSection });
  }

  // When user clicks a location menu button
  async menuClick(type, location) {
    await this.setLocationData(location);
    this.showModal(type);
  }

  // Scrolls to element id that is passed
  scrollToLocationBlock(id) {
    try {
      let elem = document.getElementById(id);
      // elem.scrollIntoView({ behavior: "smooth", block: "start" });

      var headerOffset = 45;
      var elementPosition = elem.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      elem.focus();
      elem.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } catch (err) {
      console.log("scrollToLocationBlock err: ", err);
    }
  }

  // Opens the instructional modal w/ correct store code
  openInstructionalModal = (e, storeCode) => {
    if (!storeCode) storeCode = "ABC123";
    e.stopPropagation();
    this.setState({ instructionalModalVisible: true, focusedStoreCode: storeCode });
  };

  async devFill() {
    this.setState({ isAutofilling: true }, async () => {
      let words = await getRandomWords(3);

      this.setState(
        {
          locationData: {
            name: words[0],
            addressLineOne: generateRandomNumbers(3) + " " + words[1] + " st",
            addressLineTwo: generateRandomNumbers(1) % 2 === 0 ? "Suite " + generateRandomNumbers(3) : "",
            city: words[2],
            state: Object.keys(states)[Math.floor(Math.random() * 50) + 1],
            postalCode: generateRandomNumbers(5),
            phoneNumber: generateRandomNumbers(10),
            ...defaultLocationSettings,
          },
          isAutofilling: false,
        },
        () => {
          locationFormFields.forEach((field) => {
            this.props.form.setFieldsValue({ [field]: this.state.locationData[field] });
          });
        }
      );
    });
  }

  render() {
    const { mvix, locations, isApple, mobileView, company, bannerVisible, form, showPlayer, windowWidth } = this.props;
    const { changesToSave, filteredLocationsMap, stateRes, focusedStoreCode, instructionalModalVisible, searchText, filteredLocations, isSaving, modalVisible, pageLoading, tabSection, locationData, addingNewLocation, editExisting, steps, currentStep, confirmLoading, isAutofilling } = this.state;

    let confirmationConfig = {
      id: locationData["_id"],
      data: locationData,
      props: this.props,
      confirmLoading: (confirmLoading) => this.setState({ confirmLoading }),
      closeModal: this.closeModal,
    };

    let subscribed = get(company, "subscribed", false);
    let hasLocation = Object.keys(locations).length > 0;
    let nextStepAvailable = currentStep < steps;
    let bannerHeight = bannerVisible ? "40px" : "0px";
    let maxColumnHeight = `calc(100vh - ${bannerHeight} - ${showPlayer ? "85px" : "0px"} - ${mobileView ? "292px" : "247px"})`;
    let locationsToDisplay = windowWidth < 1024 ? filteredLocationsMap : locations;
    let activeLocations = [];
    let inactiveLocations = [];

    Object.keys(locationsToDisplay).map((location) => (locationsToDisplay[location]?.isActive ? activeLocations.push(location) : inactiveLocations.push(location)));

    return (
      <div className={"location-wrapper"}>
        <Modal
          visible={modalVisible}
          title={`${editExisting ? "Manage Location" : "Add New Location"}`}
          onCancel={this.closeModal}
          footer={[
            <div className={"modal-footer-main-button-group"}>
              {(API_URL.includes("localhost") || API_URL.includes("-dev")) && !editExisting && (
                <Button key="dev" onClick={() => this.devFill()} loading={isAutofilling}>
                  <FontAwesomeIcon icon={faThumbsUp} className={"button-icon"} />
                  Autofill
                </Button>
              )}

              {editExisting && tabSection !== "upgrade" ? (
                <Button key="save" type={"primary"} onClick={this.handleSubmit} loading={isSaving} disabled={!changesToSave}>
                  <FontAwesomeIcon icon={editExisting ? faEdit : faPlusCircle} className={"button-icon"} />
                  Save Changes
                </Button>
              ) : tabSection === "upgrade" ? (
                <Button key="upgrade" type={"primary"} onClick={() => this.props.history.push("/choose-plan")} disabled={subscribed}>
                  <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                  Upgrade Now
                </Button>
              ) : !nextStepAvailable || tabSection === "settings" ? (
                <>
                  <Button key="back" onClick={() => this.setState({ tabSection: "edit" })}>
                    <FontAwesomeIcon icon={faArrowLeft} className={"button-icon"} />
                    Back
                  </Button>
                  <Button key="save" type={"primary"} loading={isSaving} onClick={this.handleSubmit}>
                    <FontAwesomeIcon icon={faPlusCircle} className={"button-icon"} />
                    Add Location
                  </Button>
                </>
              ) : (
                <Button key="next" type={"primary"} onClick={() => this.setState({ tabSection: "settings" })} loading={isSaving}>
                  <FontAwesomeIcon icon={faArrowRight} className={"button-icon"} />
                  Next
                </Button>
              )}
            </div>,
          ]}
        >
          <Tabs type="card" defaultActiveKey={tabSection} activeKey={tabSection} onTabClick={(e) => this.newTabSelection(e)}>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faEdit} className={"button-icon"} />
                  Edit
                </span>
              }
              key={"edit"}
            >
              <LocationForm data={locationData} getFieldDecorator={form?.getFieldDecorator} handleChange={this.handleChange} handleSelect={this.handleSelect} onSearchChange={this.onAutoCompleteSearch} stateRes={stateRes} />
              {subscribed && addingNewLocation && (
                <div className={"payment-alert-wrapper"}>
                  <FontAwesomeIcon icon={faBadgeDollar} className={"button-icon"} />
                  <div className={"payment-text-wrapper"}>
                    This location will be added to your existing billing cycle <br />
                    <div className={"payment-price"}>$16.00 USD per zone/month</div>
                    <div className={"payment-subtext"}>This addition will be charged right away at a prorated price</div>
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span className={"noselect"}>
                  <FontAwesomeIcon icon={faCog} className={"button-icon"} />
                  Settings
                </span>
              }
              key={"settings"}
            >
              <LocationSettings
                locations={locations}
                locationId={locationData["_id"]}
                deleteLocation={() => this.handleConfirm(confirmationConfig, locationSettingTypes.delete)}
                activateLocation={() => this.handleConfirm(confirmationConfig, locationSettingTypes.activate)}
                upgradeAccount={() => this.handleConfirm(confirmationConfig, locationSettingTypes.upgrade)}
                deactivateLocation={() => this.handleConfirm(confirmationConfig, locationSettingTypes.deactivate)}
                confirmLoading={confirmLoading}
                updateLocationSettings={(settings) => this.updateLocationSettings(settings)}
                activeLocationCount={activeLocations.length || 0}
                subscribed={subscribed}
              />
            </TabPane>
            {!subscribed && (
              <TabPane
                tab={
                  <span className={"noselect"}>
                    <FontAwesomeIcon icon={faCrown} className={"button-icon"} />
                    Upgrade
                  </span>
                }
                key={"upgrade"}
              >
                <UpgradePreview type={productTypes.location} mvix={mvix} />
              </TabPane>
            )}
          </Tabs>
        </Modal>

        <InstructionModal visible={instructionalModalVisible} storeCode={focusedStoreCode} onCancel={() => this.setState({ instructionalModalVisible: false })} isApple={isApple} mvix={mvix} />
        <PageTitle title={"Locations"} subtitle={"An overview of your business locations"} buttonText={"Add Location"} onButtonPress={() => this.clickedAddLocation()} />
        <Spin indicator={AntSpinner} spinning={pageLoading} style={{ backgroundColor: colors.offWhite, maxHeight: "100%" }}>
          <Animated animationIn="fadeIn" isVisible={true}>
            <div className={"column-wrapper spaced-scrollbar"} style={{ height: maxColumnHeight }}>
              <div className="search-input">
                <FloatLabel label="Search" placeholder="Search for locations by name or address" value={searchText}>
                  <Input.Search value={searchText} className="searchBar" onChange={this.onLocationSearchChange} />
                </FloatLabel>
              </div>
              <div className={"column-item left-location-column"}>{hasLocation && <FilterList data={filteredLocations} searchText={searchText} onSearchChange={this.onLocationSearchChange} onClick={(id) => this.scrollToLocationBlock(id)} />}</div>
              <div className={"column-item"} style={{ flex: "1" }}>
                {activeLocations.length > 0 && (
                  <>
                    <div className={"sticky-section-header"}>Active Locations</div>
                    <div className={"multi-location-tile-section-wrapper"}>
                      {activeLocations.map((location) => (
                        <LocationBlock key={locations[location]?._id} data={locations[location]} onEdit={() => this.menuClick("edit", location)} onUpgrade={() => this.menuClick("edit", location)} onConnect={this.openInstructionalModal} mvix={mvix} />
                      ))}
                    </div>
                  </>
                )}

                {inactiveLocations.length > 0 && (
                  <>
                    <div className={"sticky-section-header"}>Inactive Locations</div>
                    <div className={"multi-location-tile-section-wrapper"}>
                      {inactiveLocations.map((location) => (
                        <LocationBlock key={locations[location]?._id} data={locations[location]} onEdit={() => this.menuClick("edit", location)} onUpgrade={() => this.menuClick("edit", location)} onConnect={this.openInstructionalModal} mvix={mvix} />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Animated>
        </Spin>

        {!pageLoading && Object.keys(locations).length <= 0 && <NoPageData type={"Location"} icon={faStoreAlt} onClick={() => this.clickedAddLocation({ hasLocation, subscribed })} />}
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addLocation, deleteLocation, editLocation, getLocations }, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    company: state.company,
    isApple: state.settings.isApple,
    mvix: state.settings.mvix,
    windowWidth: state.settings.windowWidth,
    mobileView: state.settings.mobileView,
    bannerVisible: state.settings.bannerVisible,
    showPlayer: state.player.isVisible,
  };
}

const WrappedLocation = Form.create({})(Locations);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedLocation));
