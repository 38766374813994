import React, { useState } from "react";
import { getStationImageURL } from "../../util";
import GenreColorBar from "../genre-color-bar";
import { get } from "lodash";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones, faPause, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { createTrackList, getStationDetails, setIsPlaying, setPlayerVisiblity } from "../../actions";
import "./index.scss";

const StationTile = ({ bpm, genres, _id, hex, rowIndex, showStationPreview, isApple, name }) => {
  let bpmMean = get(bpm, "mean", 1);
  const [previewLoading, setPreviewLoading] = useState(false);
  const dispatch = useDispatch();
  const { playingStation, isPlaying, stations } = useSelector((state) => ({ playingStation: state.player.stationDetails, isPlaying: state.player.isPlaying, stations: state.stations }));
  const previewTracks = async () => {
    setPreviewLoading(true);
    let stationHasTracks = Boolean(stations[_id]?.top_tracks);
    let stationData = stations[_id];
    // fetch tracks if not present
    if (!stationHasTracks) stationData = await dispatch(getStationDetails(_id));

    dispatch(createTrackList(stationData));
    dispatch(setPlayerVisiblity(true));
    setPreviewLoading(false);
  };

  const previewClickHandler = (e) => {
    e.stopPropagation();

    if (playingStation?._id !== _id) previewTracks();
    else if (playingStation?._id === _id && isPlaying) dispatch(setIsPlaying(false));
    else if (playingStation?._id === _id && !isPlaying) dispatch(setIsPlaying(true));
  };

  return (
    <div className={"station-tile-wrapper noselect no-flicker".concat(rowIndex === 0 ? " first-row-station-tile-wrapper" : "")} onClick={() => showStationPreview(_id)} style={{ backgroundColor: hex, backgroundImage: `${getStationImageURL({ name, genres })}` }}>
      <div className={"top-wrapper"}>
        <Button className={"preview-button"} onClick={previewClickHandler} loading={previewLoading}>
          <FontAwesomeIcon icon={playingStation?._id === _id ? (isPlaying ? faPause : faPlay) : faHeadphones} />
        </Button>
      </div>
      <div className={"name-plate-wrapper".concat(rowIndex === 0 ? " first-row-name-plate-wrapper" : "")}> {name} </div>
      <div className={"info-wrapper"}>
        <GenreColorBar genres={genres} showStats={isApple ? false : true} bpm={bpmMean || 100} limit={3} />
      </div>
    </div>
  );
};

export default StationTile;
