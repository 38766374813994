/*eslint no-case-declarations: "off"*/

import { get } from "lodash";
import { SET_ANNOUNCEMENTS, DELETE_ANNOUNCEMENT, SET_ANNOUNCEMENT_HASH } from "../constants";
import initialState from "../redux-store/initialState";

const announcements_reducer = (state = initialState.announcements, action) => {
  switch (action.type) {
    case SET_ANNOUNCEMENTS:
      return { ...state, announcements: action.data };
    case DELETE_ANNOUNCEMENT:
      let newAnnouncements = { ...state.announcements };
      delete newAnnouncements[get(action, "data", "")];
      return Object.assign({}, state, { announcements: newAnnouncements });
    case SET_ANNOUNCEMENT_HASH:
      return { ...state, announcementHash: action.data };
    default:
      return state;
  }
};

export default announcements_reducer;
