import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { colors } from "../../constants";
import "./index.scss";

const NoPageData = ({ type, icon, onClick }) => {
  return (
    <div className={"no-page-data-wrapper"}>
      <FontAwesomeIcon icon={icon} className={"no-page-data-icon"} />
      <div className={"no-page-data-title"}>
        You have no <span style={{ color: colors.primaryColor }}>{type}s</span>
      </div>
      <div className={"no-page-data-subtitle"}>Press 'Add {type}' to get started</div>
      <Button className={"no-page-data-button"} type="primary" onClick={onClick} icon={"plus"} size={"large"}>
        {`Add ${type}`}
      </Button>
    </div>
  );
};

export default NoPageData;
