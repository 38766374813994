import React, { useState, useEffect } from "react";
import { Switch, Button, Collapse } from "antd";
import { get } from "lodash";
import { locationSettingTypes } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPowerOff } from "@fortawesome/pro-solid-svg-icons";
import "./index.scss";

const { Panel } = Collapse;

const LocationSettings = ({ locations, locationId, deleteLocation, activateLocation, deactivateLocation, upgradeAccount, confirmLoading, updateLocationSettings, activeLocationCount, subscribed }) => {
  // Are we creating a new location's settings
  let newLocation = !locationId;
  let isActive = locations[locationId]?.isActive;

  // Hooks
  const [activeCollapseKey, setActiveCollapseKey] = useState(null);
  const [explicitAllowed, setExplicitAllowed] = useState(get(locations[locationId], "explicitAllowed", false));
  const [allowPlayerToChangeStation, setAllowPlayerToChangeStation] = useState(get(locations[locationId], "allowPlayerToChangeStation", false));

  // Update the correct hook
  const performAction = (type) => {
    if (type === "explicit") setExplicitAllowed(!explicitAllowed);
    if (type === "stations") setAllowPlayerToChangeStation(!allowPlayerToChangeStation);
  };

  // Update our local state anytime 'locationId' updates
  useEffect(() => {
    setActiveCollapseKey(null);
    setExplicitAllowed(get(locations[locationId], "explicitAllowed", false));
    setAllowPlayerToChangeStation(get(locations[locationId], "allowPlayerToChangeStation", false));
  }, [locationId]);

  // Listen for changes in our individual settings
  useEffect(() => {
    updateLocationSettings({ explicitAllowed, allowPlayerToChangeStation });
  }, [explicitAllowed, allowPlayerToChangeStation]);

  return (
    <div className={"location-settings-wrapper"}>
      {/* General Settings */}
      <div className={"setting-section"}>
        <div className={"setting-header"}>General Settings</div>
        <div className={"setting-item"}>
          <div className={"setting-description"}>Allow explicit music</div>
          <div className={"setting-controller"}>
            <Switch checked={explicitAllowed} onChange={() => performAction("explicit")} />
          </div>
        </div>
        <div className={"setting-item"}>
          <div className={"setting-description"}>Allow player to change stations</div>
          <div className={"setting-controller"}>
            <Switch checked={allowPlayerToChangeStation} onChange={() => performAction("stations")} />
          </div>
        </div>
      </div>

      {
        /* Advanced Settings - Only for locations which exist */
        !newLocation && (
          <div className={"setting-section"}>
            <Collapse className={"noselect"} activeKey={activeCollapseKey} onChange={(key) => setActiveCollapseKey(key)}>
              <Panel header="Advanced Settings" key="1">
                {
                  /* Activate Location (if subscribed) */
                  !isActive && subscribed && (
                    <div className={"setting-item"}>
                      <div className={"setting-description"}>
                        Activate Location
                        <span className={"fine-text"}>Enables music streaming for this location</span>
                      </div>
                      <div className={"setting-controller"}>
                        <Button onClick={activateLocation} loading={confirmLoading === locationSettingTypes.activate}>
                          <FontAwesomeIcon icon={faPowerOff} className={"button-icon"} />
                          Activate Location
                        </Button>
                      </div>
                    </div>
                  )
                }

                {
                  /* Activate Location (if NOT subscribed) */
                  !isActive && !subscribed && (
                    <div className={"setting-item"}>
                      <div className={"setting-description"}>
                        Activate Location
                        <span className={"fine-text"}>Enables music streaming for this location</span>
                      </div>
                      <div className={"setting-controller"}>
                        <Button onClick={activeLocationCount < 1 ? activateLocation : upgradeAccount} loading={confirmLoading === locationSettingTypes.activate || confirmLoading === locationSettingTypes.upgrade}>
                          <FontAwesomeIcon icon={faPowerOff} className={"button-icon"} />
                          Activate Location
                        </Button>
                      </div>
                    </div>
                  )
                }

                {
                  /* Deactivate Location */
                  isActive && (
                    <div className={"setting-item"}>
                      <div className={"setting-description"}>
                        Deactivate Location
                        <span className={"fine-text"}>Removes location from billing cycle, but can no longer stream music for this location</span>
                      </div>
                      <div className={"setting-controller"}>
                        <Button onClick={deactivateLocation} loading={confirmLoading === locationSettingTypes.deactivate}>
                          <FontAwesomeIcon icon={faPowerOff} className={"button-icon"} />
                          Deactivate Location
                        </Button>
                      </div>
                    </div>
                  )
                }

                {
                  /* Delete Location */
                  !isActive && (
                    <div className={"setting-item"}>
                      <div className={"setting-description"}>
                        Delete Location
                        <span className={"fine-text"}>
                          <strong>warning</strong>: this action cannot be undone
                        </span>
                      </div>
                      <div className={"setting-controller"}>
                        <Button onClick={deleteLocation} loading={confirmLoading === locationSettingTypes.delete}>
                          <FontAwesomeIcon icon={faTrash} className={"button-icon"} />
                          Delete Location
                        </Button>
                      </div>
                    </div>
                  )
                }
              </Panel>
            </Collapse>
          </div>
        )
      }
    </div>
  );
};

export default LocationSettings;
