// Auth
export const SET_IS_APPLE = "SET_IS_APPLE";
export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";
export const SET_INAPP = "SET_INAPP";
export const SET_MVIX = "SET_MVIX";
export const SET_GLOBAL_MODAL_VISIBLE = "SET_GLOBAL_MODAL_VISIBLE";
export const SET_APP_LOCATION_ID = "SET_APP_LOCATION_ID";
export const SET_BANNER_VISIBLE = "SET_BANNER_VISIBLE";

// User
export const SET_USER = "SET_USER";
export const SET_FAVORITES = "SET_FAVORITES";

// Player
export const TOGGLE_VISIBLE = "TOGGLE_VISIBLE";
export const SET_TRACKS_LIST = "SET_TRACKS_LIST";
export const SET_CURRENT_TRACK = "SET_CURRENT_TRACK";
export const SET_CURRENT_TRACK_NUMBER = "SET_CURRENT_TRACK_NUMBER";
export const SET_PLAYER_VISIBLITY = "SET_PLAYER_VISIBLITY";
export const SET_IS_PLAYING = "SET_IS_PLAYING";
export const SET_STATION_DETAILS = "SET_STATION_DETAILS";

// Company
export const SET_COMPANY = "SET_COMPANY";

// Location
export const ADD_LOCATION = "ADD_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_PAIRED_DEVICE = "SET_PAIRED_DEVICE";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_STATION = "SET_STATION";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";

// Zones
export const SET_ZONES = "SET_ZONES";
export const EDIT_ZONE = "EDIT_ZONE";
export const DELETE_ZONE = "DELETE_ZONE";

// Stations
export const SET_STATIONS = "SET_STATIONS";

// Schedules
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const EDIT_SCHEDULE = "EDIT_SCHEDULE";
export const SET_SCHEDULES = "SET_SCHEDULES";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";

// Schedule Blocks
export const SET_SCHEDULE_BLOCKS = "SET_SCHEDULE_BLOCKS";
export const EDIT_SCHEDULE_BLOCK = "EDIT_SCHEDULE_BLOCK";

// Announcements
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_HASH = "SET_ANNOUNCEMENT_HASH";

// Billing
export const SET_BILLING_ADDRESSES = "SET_BILLING_ADDRESSES";
export const ADD_BILLING_ADDRESS = "ADD_BILLING_ADDRESS";
export const EDIT_BILLING_ADDRESS = "EDIT_BILLING_ADDRESS";
export const DELETE_BILLING_ADDRESS = "DELETE_BILLING_ADDRESS";

// Payments
export const SET_CARDS = "SET_CARDS";
export const ADD_CARD = "ADD_CARD";
export const DELETE_CARD = "DELETE_CARD";

// Subscription
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const SET_INVOICES = "SET_INVOICES";
