export const supersets = {
  global: ["Popular Today"],
  defaultIndividual: ["Working From Home", "New Hobby, New Self", "A Time For Reflection", "Go Hard or (Stay) Home", "Treasured Throwbacks", "Positive Vibes Only (Singing in the Shower)"],
  bar: ["Liquid Courage", "Beats and Beers", "Taproom Treasures", "Flirtatious Friday Night", "Settled-Down Speakeasy", "Dancin' Across the World"],
  cafe: ["Coffee Curiosity", "Bookstore Barista", "Classical Cappuccino", "Boba Beats", "Countryside Cafe", "Late Night Study "],
  gym: ["Pumpin Metal", "Persistence Pop", "Pre-Game Pump Up", "Fire Dancing Fiesta", "Heavy Lifting Hype"],
  hotel: ["Glitz At the Ritz", "Loungeside Lovers", "Hearthside Hip", "Travelers Rest", "Elegant Entertainment", "Tropical Getaway"],
  other: ["Flattening the Curve", "Upbeat Energy", "Laid-back, Low-key.", "Learning to Grow", "Decades", "Ahead of the Curve"],
  restaurant: ["Hamburger Hits", "Barbecue Bash", "Asian Eats", "Taco Truck Temposetter", "Boba Beats", "Royalty Dining"],
  salon: ["Girls Night Out", "Upscaled Grace", "Jukebox of the Ages", "Wax and Relax", "Hairdresser Hop", "Nails, Hair, Hips, Heels"],
  school: ["Pep Rally Pop", "Study Beats", "Intelligence through Inspiration", "Classical Concentration", "Developing Brains", "Academy Alternative"],
  spa: ["Ethereal Elegance", "Tropical Getaway", "Self-Care Classical", "Asian Getaway", "Relaxin' R&B", "Hot Spring Hits"],
  shop: ["Boutique Beats", "Diamonds Are Forever", "Jukebox of the Ages", "Retail R&B", "Girls Night Out", "Food Court Finds"],
};
