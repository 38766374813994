import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { convertFromSeconds } from "../../util";
import { genreColors } from "../../constants";
import TempoSelection from "../tempo-selection";
import SelectBar from "../select-bar";
import md5 from "md5";
import "./index.scss";

// let defaultSliderValue = 100;

const ScheduleRowEditor = ({ id, data, back, onSave }) => {
  // const [sliders, setSliders] = useState({});
  const [selectedTempo, setSelectedTempo] = useState(data?.tempo || {});
  const [genreSearchText, setGenreSearchText] = useState("");
  const [selectedGenres, setSelectedGenres] = useState(data?.genres || {});

  // Sets slider value
  // const setSliderReferences = (name, value) => {
  //   setSliders({ ...sliders, [name]: value });
  // };

  // Selects a tempo
  const onSelectTempo = (minMax, index, tempo) => {
    console.log({ minMax, index, tempo });
    setSelectedTempo({ min: minMax[0], max: minMax[1], selected: index, name: tempo });
  };

  // Deselects tempo
  const onDeselectTempo = () => {
    setSelectedTempo({});
  };

  const createHash = () => {
    let hash = "";
    hash = hash.concat(data?.name).concat(data?.days.toString()).concat(data?.startTime).concat(data?.endTime);
    Object.keys(selectedGenres).forEach((genre) => (hash = hash.concat(genre).concat(JSON.stringify(selectedGenres[genre])))); // Add genre & value
    hash = hash.concat(JSON.stringify(selectedTempo["min"])); // Add min
    hash = hash.concat(JSON.stringify(selectedTempo["max"])); // Add max
    return md5(hash);
  };

  // Creates final genre data and sends to parent
  const handleSave = () => {
    onSave({ _id: id, genres: selectedGenres, tempo: selectedTempo, newHash: createHash() });
  };

  // Selects a mood
  const onSelectGenre = (genres) => {
    let selectedGenres = {};
    genres.forEach((item) => (selectedGenres[item] = 100));
    setSelectedGenres(selectedGenres);
  };

  // Handles mood search text update
  const genreSearchTextUpdate = (value) => {
    setGenreSearchText(value);
  };

  return (
    <div className={"schedule-row-editor-wrapper"}>
      <div className={"title-wrapper"}>
        <div className={"title"}>{data?.name}</div>
        <div className={"range"}>
          {convertFromSeconds(data?.startTime)} - {convertFromSeconds(data?.endTime)}
        </div>
      </div>
      <div className={"content-wrapper"}>
        <div className={"section-wrapper"}>
          <div className={"section-title"}>
            <Tooltip className={"tooltip"} title="Genres you'd like to hear">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
            Genres
          </div>
          <div className={"section-content"}>
            <SelectBar label={"Select Genre(s)"} placeholder={"Select a genre"} tags={Object.keys(genreColors)} performSelect={onSelectGenre} selectedTags={selectedGenres} onSearchChange={genreSearchTextUpdate} searchText={genreSearchText} />
          </div>
        </div>
        {/* <div className={"section-wrapper"}>
          {
            <>
              <div className={"section-title"}>
                <Tooltip className={"tooltip"} title="Amount of each genre you'd like mixed into your station">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
                Genre Mix
              </div>
              <div className={"section-content"}>
                {Object.keys(selectedGenres).length > 0 ? (
                  <>
                    {Object.keys(selectedGenres).map((genre) => (
                      <div className={"range-item"} key={genre}>
                        <div className={"item-title"}>{genre}</div>
                        <div className={"item-slider"}>
                          <Slider defaultValue={data?.genres[genre] || defaultSliderValue} id={genre} onAfterChange={(e) => setSliderReferences(genre, e)} />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={"range-item"} style={{ opacity: 0.5 }}>
                    <div className={"item-title"} style={{ fontStyle: "italic" }}>
                      Pick a genre to adjust slider
                    </div>
                    <div className={"item-slider"}>
                      <Slider defaultValue={defaultSliderValue} disabled={true} tooltipVisible={false} />
                    </div>
                  </div>
                )}
              </div>
            </>
          }
        </div> */}
        <div className={"section-wrapper"}>
          <div className={"section-title"}>
            <Tooltip className={"tooltip"} title="Energy level of your mix (applies to all genres)">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
            Energy
          </div>
          <div className={"section-content"}>
            <TempoSelection currentSelectedTempo={selectedTempo?.selected} onSelectTempo={onSelectTempo} onDeselectTempo={onDeselectTempo} />
          </div>
        </div>
      </div>
      <div className={"button-wrapper"}>
        <Button style={{ marginRight: "10px" }} onClick={back}>
          Back
        </Button>
        <Button type="primary" onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ScheduleRowEditor;
