import React from "react";
import Geosuggest from "react-geosuggest";
import { Form } from "antd";
import FloatLabel from "../floating-label";
import "./index.scss";

const FormItem = Form.Item;
const google = window.google;

const GeoSearchForm = ({ searchValue, getFieldDecorator, geoSuggestSearchChange, onSuggestSelect, label, placeholder, errorMessage }) => (
  <Form className="auto-complete-wrapper">
    <FloatLabel required={true} label={label} placeholder={placeholder} value={searchValue}>
      <FormItem className="form-item">
        {getFieldDecorator("userData", {
          rules: [{ required: true, message: errorMessage }],
        })(<Geosuggest placeholder={""} onChange={geoSuggestSearchChange} onSuggestSelect={onSuggestSelect} location={new google.maps.LatLng(0, 0)} radius={20} country={"us"} />)}
      </FormItem>
    </FloatLabel>
  </Form>
);

export default Form.create()(GeoSearchForm);
