export const full_language_names = {
  "ar-XA": "Arabic",
  "bn-IN": "Bengali (India)",
  "cmn-CN": "Mandarin Chinese",
  "cmn-TW": "Mandarin Chinese",
  "cs-CZ": "Czech (Czech Republic)",
  "da-DK": "Danish (Denmark)",
  "de-DE": "German (Germany)",
  "el-GR": "Greek (Greece)",
  "en-AU": "English (Australia)",
  "en-GB": "English (UK)",
  "en-IN": "English (India)",
  "en-US": "English (US)",
  "es-ES": "Spanish (Spain)",
  "fi-FI": "Finnish (Finland)",
  "fil-PH": "Filipino (Philippines)",
  "fr-CA": "French (Canada)",
  "fr-FR": "French (France)",
  "gu-IN": "Gujarati (India)",
  "hi-IN": "Hindi (India)",
  "hu-HU": "Hungarian (Hungary)",
  "id-ID": "Indonesian (Indonesia)",
  "it-IT": "Italian (Italy)",
  "ja-JP": "Japanese (Japan)",
  "kn-IN": "Kannada (India)",
  "ko-KR": "Korean (South Korea)",
  "ml-IN": "Malayalam (India)",
  "nb-NO": "Norwegian (Norway)",
  "nl-NL": "Dutch (Netherlands)",
  "pl-PL": "Polish (Poland)",
  "pt-BR": "Portuguese (Brazil)",
  "pt-PT": "Portuguese (Portugal)",
  "ru-RU": "Russian (Russia)",
  "sk-SK": "Slovak (Slovakia)",
  "sv-SE": "Swedish (Sweden)",
  "ta-IN": "Tamil (India)",
  "te-IN": "Telugu (India)",
  "th-TH": "Thai (Thailand)",
  "tr-TR": "Turkish (Turkey)",
  "uk-UA": "Ukrainian (Ukraine)",
  "vi-VN": "Vietnamese (Vietnam)",
  "yue-HK": "Chinese (Hong Kong)",
};

// export const language_name_code = {
//   "Arabic": "ar-XA",
//   "Bengali (India)": "bn-IN",
//   "Mandarin Chinese": "cmn-CN",
//   "Mandarin Chinese": "cmn-TW",
//   "Czech (Czech Republic)": "cs-CZ",
//   "Danish (Denmark)": "da-DK",
//   "German (Germany)": "de-DE",
//   "Greek (Greece)": "el-GR",
//   "English (Australia)": "en-AU",
//   "English (UK)": "en-GB",
//   "English (India)": "en-IN",
//   "English (US)": "en-US",
//   "Spanish (Spain)": "es-ES",
//   "Finnish (Finland)": "fi-FI",
//   "Filipino (Philippines)": "fil-PH",
//   "French (Canada)": "fr-CA",
//   "French (France)": "fr-FR",
//   "Gujarati (India)": "gu-IN",
//   "Hindi (India)": "hi-IN",
//   "Hungarian (Hungary)": "hu-HU",
//   "Indonesian (Indonesia)": "id-ID",
//   "Italian (Italy)": "it-IT",
//   "Japanese (Japan)": "ja-JP",
//   "Kannada (India)": "kn-IN",
//   "Korean (South Korea)": "ko-KR",
//   "Malayalam (India)": "ml-IN",
//   "Norwegian (Norway)": "nb-NO",
//   "Dutch (Netherlands)": "nl-NL",
//   "Polish (Poland)": "pl-PL",
//   "Portuguese (Brazil)": "pt-BR",
//   "Portuguese (Portugal)": "pt-PT",
//   "Russian (Russia)": "ru-RU",
//   "Slovak (Slovakia)": "sk-SK",
//   "Swedish (Sweden)": "sv-SE",
//   "Tamil (India)": "ta-IN",
//   "Telugu (India)": "te-IN",
//   "Thai (Thailand)": "th-TH",
//   "Turkish (Turkey)": "tr-TR",
//   "Ukrainian (Ukraine)": "uk-UA",
//   "Vietnamese (Vietnam)": "vi-VN",
//   "Chinese (Hong Kong)": "yue-HK",
// };

export const proper_voice_name = {
  "Female A": "Cheerful Female",
  "Female B": "Strong Female",
  "Female C": "Neutral Female",
  "Female D": "Excited Female",
  "Female E": "Strong Female",
  "Female F": "Nice Female",
  "Female G": "Blissful Female",
  "Female H": "Happy Female",
  "Female I": "Animated Female",
  "Female J": "Nice Female",
  "Female K": "Vibrant Female",
  "Female L": "Energetic Female",
  "Male A": "Cheerful Male",
  "Male B": "Strong Male",
  "Male C": "Neutral Male",
  "Male D": "Excited Male",
  "Male E": "Strong Male",
  "Male F": "Nice Male",
  "Male G": "Blissful Male",
  "Male H": "Happy Male",
  "Male I": "Animated Male",
  "Male J": "Nice Male",
  "Male K": "Vibrant Male",
  "Male L": "Energetic Male",
};
