import { faPause, faPlay, faTimes, faSignalStream } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";
import { isEqual } from "lodash";
import { RFC_2822 } from "moment";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { connect } from "react-redux";
import { clearPlayerData, nextTrack, setCurrentTrack, setCurrentTrackNumber, setIsPlaying, setPlayerVisiblity } from "../../actions";
import { getQuadraticRoots } from "../../util";

import "./index.scss";

let fadeVolume = 1;
class Player extends React.Component {
  state = {
    songCompletion: 0,
    showTrackList: false,
    playerVolume: 0.6,
    fadeDuration: 0.5, // Duration we want our fade to last
    fadeStretch: 3, // Stretch value of the fade effect
    fadeArray: [], // Array of fade values calculate on fadeOut
    fadeArrayIndexes: [], // Array of indexes used during fadeOut (used with fadeArray)
    fadeOutLoading: false,
  };

  componentDidMount() {
    // this.rap.audioEl.current.addEventListener("timeupdate", async (e) => {
    //   let trackTime = e.target.currentTime;
    //   this.setState({ songCompletion: ((trackTime / e.target.duration) * 100 * 2.05).toFixed(2) });

    //   if (parseInt(trackTime) == 15) {
    //     this.skipCurrentTrack();

    //     trackTime = 0;
    //   }
    // });

    this.calculateFadeArray();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.isPlaying !== prevProps.isPlaying && this.props.isPlaying) {
      this.rap.audioEl.current.play();
    }

    if (this.props.isPlaying !== prevProps.isPlaying && !this.props.isPlaying) this.rap.audioEl.current.pause();
  }

  componentWillUnmount() {
    this.props.clearPlayerData();
  }

  skipCurrentTrack = async () => {
    if (!this.state.fadeOutLoading) {
      this.setState({ fadeOutLoading: true }, async () => {
        await this.fadeOutVolume();

        this.setState({ fadeOutLoading: false, songCompletion: 0 });
        this.props.skipTrack();
      });
    }
    // this.props.skipTrack();
  };

  closePlayer = () => {
    this.props.setPlayerVisiblity(false);
  };

  selectTrack = ({ track, index }) => {
    this.props.setCurrentTrack(track);
    this.props.setCurrentTrackNumber(index);
  };

  onPauseHandler = () => {
    this.setState({ playerVolume: 0 });
    this.props.setIsPlaying(false);
  };

  onPlayHandler = () => {
    this.setState({ playerVolume: 0 }, () => {
      this.fadeInVolume();
      this.props.setIsPlaying(true);
    });
  };

  onListenHandler = (listenTime) => {
    let { duration } = this.rap.audioEl.current;

    this.setState({ songCompletion: ((listenTime / duration) * 100 * 2.9).toFixed(2) });
    // skip after 10 sec
    if (parseInt(listenTime) == 10) this.skipCurrentTrack();
  };

  calculateFadeArray = () => {
    return new Promise((resolve) => {
      const { playerVolume, fadeDuration, fadeStretch } = this.state;

      try {
        let stopAtVolume = 0.05; // Volume to stop calculating our fade
        let baseValue = 1; // Base value of our x (root)
        let rateOfChange = 0.1; // The rate of change value to our x (root)
        let root = getQuadraticRoots(baseValue, 0, -fadeStretch); // Root is our x value
        let fadeArray = [];

        // Calculate all values in our fade, and put them into an array (if less than current volume)
        while (Math.pow(root, 2) / fadeStretch > stopAtVolume) {
          fadeArray.push(Math.pow(root, 2) / fadeStretch); // Push value into our array
          root = getQuadraticRoots(parseFloat((baseValue += rateOfChange).toFixed(1)), 0, -fadeStretch); // Recalculate x (root)
        }

        let fadeCounter = 0; // A counter for how many times we've looped
        let interval = (fadeArray.length / fadeDuration) * (fadeDuration * 0.01); // The interval at which we pick elements from our calculated array
        let index = 0; // Index we should access within our calculated array
        let fadeArrayIndexes = [];

        // Calculate the indexes we'll be using to access fadeArray
        while (index <= fadeArray.length) {
          index += Math.floor(fadeCounter * interval);
          fadeArrayIndexes.push(index);
          fadeCounter++;
        }

        // If index overflows fadeArray length, pop the last element
        if (index >= fadeArray.length) fadeArrayIndexes.pop();

        // Save to state
        this.setState({ fadeArray, fadeArrayIndexes });
        resolve(true);
      } catch (error) {
        resolve(false);
        console.log("Calculating FadeArray Error: ", error);
      }
    });
  };

  fadeOutVolume = () => {
    return new Promise((resolve) => {
      try {
        const { fadeArray, fadeArrayIndexes, playerVolume } = this.state;

        let volume = playerVolume; // Grab player volume
        let previousVolume = playerVolume;

        let fadeCounter = 0;
        let index = 0;

        // Execute fade effect
        let fadeOut = setInterval(async () => {
          index = fadeArrayIndexes[fadeCounter];
          volume = fadeArray[index];
          fadeCounter++;

          if (fadeCounter >= fadeArrayIndexes.length) {
            clearInterval(fadeOut);
            fadeVolume = 0;
            // this.setState({ playerVolume: 0.6 }, () => {
            resolve(true);
            // });
            // return volumeByTrackType * fadeVolume;
          } else {
            fadeVolume = volume; // Lower volume
            this.setState({ playerVolume: 0.6 * fadeVolume });
          }
        }, 50);
      } catch (err) {
        console.log("caught fadeOutVolume err: ", err);
        resolve(false);
      }
    });
  };

  fadeInVolume = () => {
    return new Promise((resolve) => {
      const { playerVolume, fadeArray } = this.state;
      try {
        let previousPlayerVolume = playerVolume;

        let volume = 0;
        let fadeCounter = 0;
        let index = 0;
        //get fadeArray indexes from state
        let fadeArrayIndexes = [...this.state.fadeArrayIndexes];
        fadeArrayIndexes = fadeArrayIndexes.reverse();

        // Execute fade in effect
        let fadeIn = setInterval(async () => {
          index = fadeArrayIndexes[fadeCounter];
          volume = fadeArray[index];
          fadeCounter++;

          if (fadeCounter >= fadeArrayIndexes.length) {
            clearInterval(fadeIn);
            fadeVolume = 1;

            // this.setState({ playerVolume: 0.6 }, () => {
            resolve(true);
            // });
          } else {
            fadeVolume = volume; // Raise volume
            this.setState({ playerVolume: fadeVolume * 0.6 });
          }
        }, 50);
      } catch (err) {
        resolve(false);
        console.log("caught fadeInVolume err: ", err);
      }
    });
  };

  render() {
    const { currentTrack, isPlaying } = this.props;
    const { songCompletion, playerVolume } = this.state;

    return (
      <>
        <div className="player-wrapper">
          <div className="music-bar-scrub-wrapper">
            <div className="music-bar-scrubber" style={{ width: songCompletion + "%" }} />
          </div>
          <div className="player">
            <div className="player--left-section">
              <div className="play-pause-btn" onClick={() => (isPlaying ? this.rap.audioEl.current.pause() : this.rap.audioEl.current.play())}>
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </div>
              <div className="track-info">
                <div className="track-name">{currentTrack?.title}</div>
                <div className="artist-name">{currentTrack?.artist?.name}</div>
              </div>
            </div>
            <div className="player--right-section">
              {/* <Popover
                trigger="click"
                title="Top Tracks"
                content={this.props.trackList.map((track, index) => (
                  <>
                    <div className="track-list-item" onClick={() => this.selectTrack({ track, index })}>
                      <h4>
                        {track.artist.name} - {track.title}
                      </h4>
                    </div>
                  </>
                ))}
              > */}

              <div className="station-name-btn" onClick={() => this.setState({ showTrackList: !this.state.showTrackList })}>
                <FontAwesomeIcon icon={faSignalStream} className="station-icon" /> <span className="station-name"> {this.props.stationDetails?.name} </span>
              </div>

              {/* </Popover> */}
              <div className="close-player-btn" onClick={() => this.closePlayer()}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        </div>
        {/* React Audio Player */}
        <ReactAudioPlayer
          src={currentTrack.preview}
          autoPlay
          ref={(element) => {
            this.rap = element;
          }}
          volume={playerVolume}
          onPlay={() => this.onPlayHandler()}
          onPause={() => this.onPauseHandler()}
          onError={() => this.skipCurrentTrack()}
          listenInterval={100}
          onListen={(listenTime) => this.onListenHandler(listenTime)}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  skipTrack: () => dispatch(nextTrack()),
  setPlayerVisiblity: (data) => dispatch(setPlayerVisiblity(data)),
  setIsPlaying: (data) => dispatch(setIsPlaying(data)),
  setCurrentTrack: (data) => dispatch(setCurrentTrack(data)),
  setCurrentTrackNumber: (data) => dispatch(setCurrentTrackNumber(data)),
  clearPlayerData: () => dispatch(clearPlayerData()),
});

const mapStateToProps = (state) => ({
  currentTrack: state.player.currentTrack,
  trackList: state.player.tracks,
  isPlaying: state.player.isPlaying,
  stationDetails: state.player.stationDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Player));
