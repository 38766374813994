import { notification } from "antd";
import { HTTP } from "../configs";
import { responses } from "../constants";
import { setUser, setFavorites } from "./dispatch";

// Edits a user
export function editUser({ dataToSend, userId }) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/user/${userId}`, dataToSend)
        .then(({ success, data, message }) => {
          if (success) {
            dispatch(setUser(dataToSend));
            notification.success({ message: responses.status.success, description: message, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}

export function toggleFavorite({ stationId, favorites, stationData }) {
  return async (dispatch) => {
    return new Promise((resolve) => {
      HTTP("patch", `/v3/auth/user/favorite/${stationId}`)
        .then(({ success, data, message }) => {
          if (success) {
            let newFavorites = [...favorites];
            if (data.type === "add") newFavorites.push(stationData);
            else newFavorites = newFavorites.filter((favorite) => favorite._id !== stationId);
            dispatch(setFavorites(newFavorites));
            notification.success({ message: data.type === "add" ? responses.action.addedFavorite : responses.action.removedFavorite, description: stationData?.name, placement: "bottomLeft" });
          } else notification.error({ message: responses.sectionName.favorite, description: message, placement: "bottomLeft" });
          resolve(true);
        })
        .catch(() => {
          notification.error({ message: responses.sectionName.favorite, description: responses.description.internalServerError, placement: "bottomLeft" });
          resolve(false);
        });
    });
  };
}
