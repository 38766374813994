import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { getAnnouncements, setAnnouncements } from "../../actions";
import { Spin } from "antd";
import { get, isEqual } from "lodash";
import PageTitle from "../../components/page-title";
import ReactAudioPlayer from "react-audio-player";
import AntSpinner from "../../components/antSpinner";
import AnnouncementModal from "../../modals/announcement-modal";
import MultiLocationDisplayTile from "../../components/multi-location-display-tile";
import { getTimeDisplayText } from "../../util";
import NoPageData from "../../components/no-page-data";
import { faSignalStream } from "@fortawesome/pro-solid-svg-icons";
import { Animated } from "react-animated-css";
import "./index.scss";

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLoading: false,
      modalVisible: false,
      isPlaying: false,
      previewTrackUrl: null,
      pageLoading: true,
      selectedFile: null,
      announcementAlreadyFired: false,
      focusedId: null,
      defaultTab: null, // Default tab we want to open an announcement to
      data: {},
    };
  }

  onChangeHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    // If we have no announcements, fetch announcements
    if (Object.keys(this.props.announcements.announcements).length <= 0) {
      this.props.actions.getAnnouncements().then(() => {
        this.setState({ pageLoading: false, announcementAlreadyFired: true });
      });
    } else this.setState({ pageLoading: false, announcementAlreadyFired: true });
  }

  componentDidUpdate(prevProps) {
    // If webpage is loaded here (/announcements), do the following after props are fully loaded
    // If the announcement already fired dont fire again
    if (!this.state.announcementAlreadyFired) {
      if (!isEqual(prevProps.locations, this.props.locations)) {
        this.props.actions.getAnnouncements().then((res) => {
          if (res) this.setState({ pageLoading: false });
        });
        this.setState({ announcementAlreadyFired: true });
      }
    }
  }

  playTrack() {
    this.setState({ isPlaying: true }, () => {
      this.rap.audioEl.play();
    });
  }

  stopTrack = () => {
    this.setState({ isPlaying: false, previewTrackUrl: null }, () => {
      this.rap.audioEl.pause();
    });
  };

  setPreviewUrl(e) {
    this.setState({ previewTrackUrl: e });
  }

  // Runs when user presses 'Add Location' button
  async clickedAddAnnouncement(productLimitReached) {
    this.showModal({ key: productLimitReached ? "upgrade" : "edit" });
  }

  // Opens modal
  showModal = async ({ key, id }) => {
    this.setState({ modalVisible: true, modalLoading: false, defaultTab: key || "edit", focusedId: id || null });
  };

  closeModalAndClearData = () => {
    this.setState({ modalVisible: false, defaultTab: null });
  };

  // Delays the announcement preview so we can trigger fadeout from player
  playAnnouncementPreview() {
    const { setPreviewUrl } = this.props;
    const { data } = this.state;

    let delay = setTimeout(() => {
      setPreviewUrl(get(data, "url", ""));
      this.setState({ isPlaying: true });
      clearInterval(delay);
    }, 1000);
  }

  setAnnouncementId(focusedId) {
    this.setState({ focusedId });
  }

  render() {
    const {
      announcements: { announcements },
      locations,
      company,
      bannerVisible,
      showPlayer,
      mobileView,
    } = this.props;
    const { focusedId, previewTrackUrl, modalVisible, modalLoading, pageLoading, defaultTab } = this.state;

    let subscribed = get(company, "subscribed", false);
    let hasAnnouncement = Object.keys(announcements).length > 0;
    let productLimitReached = hasAnnouncement && !subscribed;
    let bannerHeight = bannerVisible ? "40px" : "0px";
    let maxColumnHeight = `calc(100vh - ${bannerHeight} - ${showPlayer ? "85px" : "0px"} - ${mobileView ? "292px" : "247px"})`;
    let activeAnnouncements = [];
    let inactiveAnnouncements = [];

    Object.keys(announcements).map((id) => (announcements[id]?.isActive ? activeAnnouncements.push(id) : inactiveAnnouncements.push(id)));

    return (
      <div className="messageWrapper spaced-scrollbar">
        <PageTitle title={"Announcements"} subtitle={"Custom announcements to help drive customer action"} buttonText={"Add Announcement"} onButtonPress={() => this.clickedAddAnnouncement(productLimitReached)} />
        <AnnouncementModal visible={modalVisible} loading={modalLoading} onOk={this.closeModalAndClearData} onCancel={this.closeModalAndClearData} data={focusedId ? announcements[focusedId] : null} setAnnouncementId={(e) => this.editAnnouncement(e)} defaultTab={defaultTab} setDefaultTab={(defaultTab) => this.setState({ defaultTab })} subscribed={subscribed} hasAnnouncement={hasAnnouncement} />
        <Spin indicator={AntSpinner} spinning={pageLoading}>
          <Animated animationIn="fadeIn" isVisible={true}>
            <div className="announcement-list-wrapper" style={{ height: maxColumnHeight }}>
              <div style={{ display: "none" }}>
                <ReactAudioPlayer
                  ref={(element) => {
                    this.rap = element;
                  }}
                  src={previewTrackUrl}
                  autoPlay={false}
                  controls={false}
                  muted={false}
                  volume={0.75}
                  onCanPlay={() => this.playTrack()}
                  onEnded={() => this.setState({ isPlaying: false, previewTrackUrl: null })}
                  onError={() => this.setState({ isPlaying: false, previewTrackUrl: null })}
                />
              </div>
              {activeAnnouncements.length > 0 && (
                <>
                  <div className={"sticky-section-header"}>Active Announcements</div>
                  <div className={"multi-location-tile-section-wrapper"}>
                    {activeAnnouncements.map((id) => (
                      <MultiLocationDisplayTile key={id} elemId={id} type={"announcement"} isActive={announcements[id]?.isActive} onClick={() => this.showModal({ key: "edit", id })} onClickAddLocation={() => this.showModal({ key: "assign", id })} image={announcements[id]?.image} title={announcements[id]?.title} subtitle={getTimeDisplayText(announcements[id]?.frequency)} description={announcements[id]?.message || announcements[id]?.description} locations={locations} assignedLocations={announcements[id]?.assignedLocations} />
                    ))}
                  </div>
                </>
              )}

              {inactiveAnnouncements.length > 0 && (
                <>
                  <div className={"sticky-section-header"}>Inactive Announcements</div>
                  <div className={"multi-location-tile-section-wrapper"}>
                    {inactiveAnnouncements.map((id) => (
                      <MultiLocationDisplayTile key={id} elemId={id} type={"announcement"} isActive={announcements[id]?.isActive} onClick={() => this.showModal({ key: "edit", id })} onClickAddLocation={() => this.showModal({ key: "assign", id })} image={announcements[id]?.image} title={announcements[id]?.title} subtitle={getTimeDisplayText(announcements[id]?.frequency)} description={announcements[id]?.message || announcements[id]?.description} locations={locations} assignedLocations={announcements[id]?.assignedLocations} />
                    ))}
                  </div>
                </>
              )}

              {!pageLoading && Object.keys(announcements).length <= 0 && <NoPageData type={"Announcement"} icon={faSignalStream} onClick={() => this.clickedAddAnnouncement(productLimitReached)} />}
            </div>
          </Animated>
        </Spin>
      </div>
    );
  }
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ getAnnouncements, setAnnouncements }, dispatch) };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    locations: state.locations,
    company: state.company,
    announcements: state.announcements,
    mobileView: state.settings.mobileView,
    bannerVisible: state.settings.bannerVisible,
    showPlayer: state.player.isVisible,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Announcements));
