import React, { Component } from "react";
import { Provider } from "react-redux";
import { connect } from "react-redux"; //to pass functions
import { bindActionCreators } from "redux";
import App from "../App";
import "antd/dist/antd.css";
import { Elements } from "@stripe/react-stripe-js";

class RootContainer extends Component {
  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { store, stripePromise } = this.props;
    return (
      <Provider store={store}>
        <div>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </div>
      </Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.loader,
  };
}

/* Map Actions to Props */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
