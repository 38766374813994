export const categories = {
  welcome: "Welcome",
  stations: "Stations",
  locations: "Locations",
  announcements: "Announcements",
  schedule: "Schedule",
  general: "General",
};

export const actions = {
  // Welcome
  login: "Logged into web application",
  signup: "Signed up for web application",
  failedLogin: "Failed to login",

  // Stations
  stationPreview: "Previewed a station",
  startStation: "Started station",
  updatePreference: "Updated station preference",
  advancedBrowse: "Swapped to advance station browse",
  stationBrowse: "Swapped to station browse",
  search: "Search station",

  // Locations
  connect: "Pressed connect button",

  // Scheduling
  addSchedule: "Created Schedule",
  saveSchedule: "Saved Schedule",
  closeSchedule: "Closed Schedule",
  backToDashboard: "Back To Dashboard",
  changeScheduleName: "Changed Schedule Name",
  viewedMobile: "Viewed on Mobile Device",

  // General
  button: "Clicked a button",
  toggledFavorite: "Toggled a station as favorite",
  signout: "User signed out of web application",

  // Error
};

export const labels = {
  button: "button",
  error: "error",
};
