import React from "react";
import { Form, Input, AutoComplete } from "antd";
import FormItem from "antd/lib/form/FormItem";
import FloatLabel from "../floating-label";
import { formErrorMessages } from "../../constants";

const LocationForm = ({ data, stateRes, getFieldDecorator, handleChange, handleSelect, onSearchChange }) => (
  <Form>
    <FloatLabel required={true} label="Location Name" placeholder="Location's Name (or nickname)" value={data["name"]}>
      <FormItem>
        {getFieldDecorator("name", {
          rules: [{ required: true, message: formErrorMessages.required }],
          initialValue: data["name"],
        })(<Input onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    <FloatLabel required={true} label="Street Address" placeholder="Location's Street Address" value={data["addressLineOne"]}>
      <FormItem>
        {getFieldDecorator("addressLineOne", {
          rules: [{ required: true, message: formErrorMessages.required }],
          initialValue: data["addressLineOne"],
        })(<Input onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    <FloatLabel label="Suite, unit, building, etc." placeholder="Location's Secondary Address Info" value={data["addressLineTwo"]}>
      <FormItem>
        {getFieldDecorator("addressLineTwo", {
          initialValue: data["addressLineTwo"],
        })(<Input onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>

    <div className={"ant-form-row"}>
      <FloatLabel required={true} label="City" placeholder="Location's City" value={data["city"]}>
        <FormItem>
          {getFieldDecorator("city", {
            rules: [{ required: true, message: formErrorMessages.required }],
            initialValue: data["city"],
          })(<Input onChange={handleChange} />)}
        </FormItem>
      </FloatLabel>

      <FloatLabel required={true} label="State" placeholder="Location's State" value={data["state"]}>
        <FormItem className="form-item">
          {getFieldDecorator("state", {
            rules: [{ required: true, message: formErrorMessages.required }],
            initialValue: data["state"],
          })(<AutoComplete dataSource={stateRes} style={{ width: "100%" }} onSelect={(state) => handleSelect({ ...data, state })} onSearch={(e) => onSearchChange("state", e)} />)}
        </FormItem>
      </FloatLabel>

      <FloatLabel required={true} label="Zip" placeholder="Location's Zip" value={data["postalCode"]}>
        <FormItem>
          {getFieldDecorator("postalCode", {
            rules: [{ required: true, message: formErrorMessages.required }],
            initialValue: data["postalCode"],
          })(<Input type="number" onChange={handleChange} />)}
        </FormItem>
      </FloatLabel>
    </div>

    <FloatLabel required={true} label="Location Phone Number" placeholder="Location's Phone Number" value={data["phoneNumber"]}>
      <FormItem>
        {getFieldDecorator("phoneNumber", {
          rules: [{ required: true, message: formErrorMessages.required }],
          initialValue: data["phoneNumber"],
        })(<Input onChange={handleChange} />)}
      </FormItem>
    </FloatLabel>
  </Form>
);

export default Form.create()(LocationForm);
