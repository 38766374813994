import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Animated } from "react-animated-css";
import "./index.scss";

const AnimatedIcon = ({ icon, onClick, style, animationIn, animationOut, durationIn, durationOut }) => {
  const [animateIcon, setAnimateIcon] = useState(false);

  return (
    <div className={"animated-icon-wrapper"}>
      {animateIcon ? (
        <Animated animationIn={animationIn} animationOut={animationOut} isVisible={animateIcon} animationInDuration={durationIn} animationOutDuration={durationOut}>
          <FontAwesomeIcon icon={icon} onClick={onClick} style={style} onMouseLeave={() => setAnimateIcon(false)} />
        </Animated>
      ) : (
        <FontAwesomeIcon icon={icon} onClick={onClick} style={style} onMouseOver={() => setAnimateIcon(true)} />
      )}
    </div>
  );
};

export default AnimatedIcon;
