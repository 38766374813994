import React from "react";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { upgradePerks } from "../../constants";
import PlanOptionBlock from "../plan-option-block";
import "./index.scss";

const UpgradePreview = ({ type, mvix }) => (
  <div className={"upgrade-wrapper"}>
    <div className={"info-wrapper"}>
      <div className={"warning"}>
        <Animated animationIn="rubberBand" isVisible={true} animationInDelay={1000}>
          <FontAwesomeIcon icon={faTimesCircle} className={"button-icon"} style={{ fontSize: "14px" }} />
        </Animated>
        You have reached your 1 {type} maximum
      </div>
      <div className={"description"}>Please subscribe to unlock unlimited {type}s</div>
      <div className={"sub-description"}>You can continue to create {type}s, but they will be locked until your account is upgraded</div>
    </div>

    {/* Product Description */}
    <PlanOptionBlock
      currentOption={true}
      title={
        <span>
          {mvix ? "" : "Ambii"} <span className={"colored-title"}>Premium</span> {mvix ? " Radio" : ""}
        </span>
      }
      description={"For businesses that want full coverage and control of their locations."}
      rowItems={upgradePerks}
      simple={true}
    />
  </div>
);

export default UpgradePreview;
