export const colors = {
  primaryColor: "#D04283",
  primaryColorHovered: "#Cf6B98",
  primaryColorOpaque: "#D04283AE",
  deepPurple: "#262142",
  shadowPurple: "#2C21425A",
  lightPurple: "#E6E3EE",
  darkGray: "#2A2A2A",
  gray: "#CCC",
  grayAlt: "#D9D9D9",
  white: "#FFFFFF",
  black: "#000000",
  offWhite: "#F1EEF4",
  hoveredOffWhite: "#D9D4E570",
  selectedOffWhite: "#D9D4E5",
  online: "limegreen",
  offline: "#E84B47",
  gold: "gold",
  inputColor: "#FAF9FC",
};
