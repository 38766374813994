import initialState from "../redux-store/initialState";

import { get } from "lodash";
import { SET_TRACKS_LIST, TOGGLE_VISIBLE, SET_CURRENT_TRACK, SET_CURRENT_TRACK_NUMBER, SET_PLAYER_VISIBLITY, SET_IS_PLAYING, SET_STATION_DETAILS } from "../constants";

const player_reducer = (state = initialState.player, action) => {
  switch (action.type) {
    case TOGGLE_VISIBLE:
      return Object.assign({}, state, { isVisible: !state.isVisible });
    case SET_TRACKS_LIST:
      return { ...state, tracks: action.data };
    case SET_CURRENT_TRACK:
      return { ...state, currentTrack: action.data };
    case SET_CURRENT_TRACK_NUMBER:
      return { ...state, currentTrackNumber: action.data };
    case SET_PLAYER_VISIBLITY:
      return { ...state, isVisible: action.data };
    case SET_IS_PLAYING:
      return { ...state, isPlaying: action.data };
    case SET_STATION_DETAILS:
      return { ...state, stationDetails: action.data };
    default:
      return state;
  }
};

export default player_reducer;
