import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Spin } from "antd";
import AntSpinner from "../../components/antSpinner";
import { withAuth0 } from "@auth0/auth0-react";
import "./index.scss";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.auth0.isAuthenticated && this.props.user) {
      this.props.history.push("/locations");
    }
    return (
      <div style={{ width: "100%", height: "100%", position: "fixed", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spin indicator={AntSpinner} spinning={true} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

function mapStateToPropsRouter(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToPropsRouter, mapDispatchToProps)(withRouter(withAuth0(Main)));
