import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { convertFromSeconds, getDayShortname } from "../../util";
import { colors, genreColors, tempos } from "../../constants";
import GenreColorBar from "../genre-color-bar";
import "./index.scss";

const ScheduleRowItem = ({ title, startTime, endTime, genres = {}, tempo, days, onClick }) => (
  <div className={"schedule-row-item-wrapper"} style={{ borderBottom: Object.keys(genres)?.length > 0 ? "inherit" : `4px solid ${colors.grayAlt}` }} onClick={onClick}>
    <div className={"schedule-row-item-content"}>
      <div className={"section-title-wrapper"}>
        <div className={"title"}>{title}</div>
        <div className={"range"}>
          {convertFromSeconds(startTime)} - {convertFromSeconds(endTime)}
        </div>
        <div className={"days"}> {getDayShortname(days)}</div>
      </div>
      <div className={"genre-wrapper"}>
        {Object.keys(genres).map((genre) => (
          <div className={"genre-item"} key={genre}>
            <div className={"genre-color-dot"} style={{ backgroundColor: genreColors[genre] }} />
            <div className={"genre-title"}>{genre}</div>
          </div>
        ))}
      </div>
      {tempo?.name && (
        <div className={"tempo-wrapper"}>
          <div className={"tempo-box"}>
            <FontAwesomeIcon icon={tempos[tempo?.name]?.icon} />
            {/* {tempos[tempo].title} */}
          </div>
        </div>
      )}
      <div className={"icon-wrapper"}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
    <div className={"schedule-row-item-colors"}>
      <GenreColorBar genres={genres} roundBottom={true} simple={true} />
    </div>
  </div>
);

export default ScheduleRowItem;
