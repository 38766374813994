import ReactGA from "react-ga";

// Initializes analytics
export const startGoogleAnalytics = () => {
  ReactGA.initialize("UA-167385126-1", {
    siteSpeedSampleRate: 100,
    testMode: document.location.hostname.includes("business.ambii.io") ? false : true,
  });
};

/**
 * @param {category} - Category of action
 * @param {action} - Action user performed
 * @param {value} - (optional) any potential value
 * @param {label} - Item which was interacted with
 */
export const logEvent = ({ category, action, value, label }) => {
  // console.log("hit event: ", { category, action, value, label });
  ReactGA.event({
    category,
    action,
    value: value ? parseInt(value) : null,
    label,
  });
};

// Tracks screen based on url
export const trackScreen = () => {
  // console.log("hit screen: ", window.location.pathname + window.location.search)
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// Tracks modals when page does not reload
export const trackModal = (modal) => {
  // console.log("hit modal: ", modal)
  ReactGA.modalview(modal);
};
